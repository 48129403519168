import { useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { CallRecord, CallState, CallType } from 'utils/calls';
import { CallRecordsParams, getCallRecords } from "api/callrecords";
import { Person } from "@mui/icons-material";
import CallerHistory from "./CallerHistory";
import { useAppSelector } from "reducers/hooks";
import { selectedCallSelector } from "reducers/selector/callSelector";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "reducers/types";
import { getSelectedCall } from "reducers/thunks/callThunks";
import debounce from "awesome-debounce-promise";

const debouncedGetCallRecords = debounce(getCallRecords, 500)

const AccountCallerHistory = () => {
  const selectedCall = useAppSelector(selectedCallSelector)
  const account = useAppSelector(state => state.accountPanel.activeAccount)
  const [lastSelectedCall, setLastSelectedCall] = useState<CallState | null>(null)
  const [lastCallRecord, setLastCallRecord] = useState<CallRecord | null>(null) // Always incoming call
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    if (selectedCall?.conferenceName) {
      setLastSelectedCall(selectedCall)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCall?.conferenceName])

  useEffect(() => {
    if (!selectedCall && !!lastSelectedCall && lastSelectedCall.accountId === account.id) return

    setLastCallRecord(null)
    setIsLoading(true)

    const options: CallRecordsParams = {
      limit: 1,
      page: 1,
      accountIds: [account.id],
    }

    let lastCallerNumber = lastSelectedCall?.callerNumber
    if (lastCallerNumber && lastSelectedCall?.accountId === account.id) {
      options.callerNumber = lastCallerNumber
    } else {
      options.direction = ['Incoming']
    }

    debouncedGetCallRecords(options).then((res) => {
      const selectedCall = thunkDispatch(getSelectedCall())
      if (selectedCall && selectedCall.callerNumber !== lastCallerNumber) return

      const lastRecord = res.data[0] as CallRecord
      setLastCallRecord(lastRecord)

      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedCall?.callerNumber, account.id])

  const isOutbound = lastSelectedCall?.type === CallType.OUTBOUND
  return (
    <Box>
      <CallerHistory
        noCallerNumber={!!lastSelectedCall && !lastSelectedCall.callerNumber}
        lastCallRecord={lastCallRecord}
        isLoading={isLoading}
        isOutbound={isOutbound}
        account={{ id: account.id, name: account.name }}
      />

      <Divider sx={{ my: 1 }} />
      <Stack direction="row" alignItems="center" spacing={1} mb={1}>
        <Person sx={{ color: '#1033A5' }} />
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Account Details</Typography>
      </Stack>
    </Box>
  );
};

export default AccountCallerHistory;
