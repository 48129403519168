import { FC } from "react";
import { FormQuestion } from "../utils";
import StyledTextField from "./StyledTextField";

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  error?: string,
}

const TextfieldInput: FC<IProps> = (props) => {
  const { question, handleChange, value, error } = props;

  return (
    <StyledTextField
      variant="outlined"
      fullWidth
      size="small"
      InputProps={{ sx: { bgcolor: '#E4ECFC', borderRadius: '25px' } }}
      onChange={e => handleChange(question.id, e.target.value)}
      value={value}
      error={!!error}
      helperText={error}
    />
  )
}

export default TextfieldInput;
