import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deregisterSlot, registerSlot } from 'reducers/slices/callSlotReducer';
import CallCard from '../callCard/CallCard';
import NoCallCard from '../callCard/NoCallCard';
import { RootState } from "reducers/store";
import { setOpenAccountId } from 'reducers/slices/accountPanelReducer';
import { AppThunkDispatch } from 'reducers/types';
import { selectSlotThunk, unclaimCallSlotThunk } from 'reducers/thunks/callThunks';
import { logger } from 'utils/utils';
import { getActiveAccount } from 'reducers/thunks/accountThunk';
import { getLoggedInUser } from 'reducers/thunks/userThunk';

interface IProps {
  name: string,
  userId: string,
}

export const CallSlot: FC<IProps> = ({ name, userId }) => {
  const slotName = name + userId
  const conferenceName = useSelector((state: RootState) => state.callSlot.assignedCalls[slotName]);
  const callState = useSelector((state: RootState) => state.call[conferenceName || '']);
  const isSelected = useSelector((state: RootState) => state.callSlot.selectedSlot === slotName)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    const callSlot = {
      name: slotName,
      userId,
    }
    dispatch(registerSlot(callSlot))

    return () => { dispatch(deregisterSlot(callSlot)) }
  }, [slotName, userId, dispatch])

  useEffect(() => {
    if (!callState || callState.isRemoved) {
      logger('Unclaiming call slot as call state is removed', { name, conferenceName })
      thunkDispatch(unclaimCallSlotThunk(conferenceName || ''))
    }

    // CCAA-1251 This is to handle an edge case in which call is assigned into slot
    // right as someone else answered it
    if (callState) {
      const user = thunkDispatch(getLoggedInUser())
      if (callState.conferenceJoinedBy && callState.conferenceJoinedBy !== user?.userId) {
        logger('Unclaiming call slot as the call is for another user', { name, conferenceName, joinedBy: callState.conferenceJoinedBy, userId: user?.userId })
        thunkDispatch(unclaimCallSlotThunk(conferenceName || ''))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callState])
  
  const handleSelect = () => {
    const account = thunkDispatch(getActiveAccount())
    if (account?.isFromGlobalSearch) {
      dispatch(setOpenAccountId('call'))
    }
    thunkDispatch(selectSlotThunk(slotName))
  }

  if (!callState || callState.isRemoved) {
    return <NoCallCard />
  }

  return (
    <CallCard
      callState={callState}
      isSelected={isSelected}
      onSelect={handleSelect}
    />
  );
}

export default CallSlot;