import agent from './agent'

export interface ILogin {
  login: string;
  password: string;
}

export function login(payload: ILogin) {
  return agent.post('/user/signIn', payload)
}

export interface IRegister {
  email: string;
  password: string;
}

export function register(payload: IRegister) {
  return agent.post('/user/signUp', payload)
}

export interface IResetPassword {
  login: string;
}

export function resetPassword(payload: IResetPassword) {
  return agent.post('/user/reset-password', payload)
}

export interface ISetupPassword {
  password: string;
  confirmationCode: string;
}

export function setupPassword(payload: ISetupPassword) {
  return agent.post('/user/setup-password', payload)
}