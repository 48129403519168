import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import { duoAuth, duoAuthSms } from "api/authDuo";
import AnimatedButton from "components/buttons/AnimatedButton";
import jwtDecode from "jwt-decode";
import BackgroundLayout from "pages/BackgroundLayout";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setLoginError } from "reducers/slices/UIReducer";
import { LoggedInUser, logout, updateUser } from "reducers/slices/userReducer";


const DuoAuth = () => {
  const [authType, setAuthType] = useState<string>('push')
  const [passcode, setPasscode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const hasCanceledPush = useRef<boolean>(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams();
  const token = params.token as string

  const [user] = useState<LoggedInUser | null>(() => {
    const token = params.token
    if (!token) return null

    const user = jwtDecode(token)
    return user as LoggedInUser
  })

  const auth2faMethods = useMemo(() => user?.auth2faMethods || [], [user?.auth2faMethods])
  
  useEffect(() => {
    if (user) {
      duoAuth('push', token).then((res) => {
        if (hasCanceledPush.current) return
  
        if (res.data.result === 'allow') {
          dispatch(updateUser(res.data.accessToken));
          navigate('/')
        } else {
          dispatch(logout())
          dispatch(setLoginError(res.data.response.status_msg))
          navigate('/')
        }
      })
    }
  }, [dispatch, navigate, user, token])

  const performSmsAuth = () => {
    setAuthType('sms')
    duoAuth('sms', token)
    hasCanceledPush.current = true
  }

  const submitPasscode = (event: any) => {
    event.preventDefault()

    setLoading(true)
    duoAuthSms(passcode, token).then((res) => {
      if (res.data.result === 'allow') {
        dispatch(updateUser(res.data.accessToken));
        navigate('/')
      }
      if (res.data.response.result === 'deny') {
        setError(res.data.response.status_msg)
        setLoading(false)
      }
    })
  }

  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <Box px={6} py={4}>
          {authType === 'push' &&
            <Box width={400}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '24px', }}>2-Factor Authentication</Typography>
              <Typography align="center" sx={{ mt: 1, lineHeight: '20px' }}>
                Please check your phone and authorize via the push notification.
              </Typography>
              {auth2faMethods.includes('sms') &&
                <Button
                  onClick={performSmsAuth}
                  variant="text"
                  sx={{ mt: 2, width: '100%' }}
                >
                  Use sms instead
                </Button>
              }
            </Box>
          }

          {authType === 'sms' &&
          <form onSubmit={submitPasscode}>
            <Box width={400}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '24px', }}>2-Factor Authentication</Typography>
              <Typography align="center" sx={{ mt: 1, lineHeight: '20px' }}>
                Please enter the passcode that you have received via SMS.
              </Typography>
              <TextField
                sx={{
                  mt: 2,
                  '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
                }}
                placeholder="Passcode"
                fullWidth
                size="small"
                value={passcode}
                onChange={e => setPasscode(e.target.value)}
                InputProps={{
                  sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
                }}
                helperText={error}
                error={!!error}
              />
              <AnimatedButton
                sx={{ mt: 4 }}
                label="SUBMIT"
                type="submit"
                onClick={submitPasscode}
                loading={loading}
                disabled={loading}
              />
            </Box>
            </form>
          }
        </Box>
      </Paper>
    </BackgroundLayout>
  );
};

export default DuoAuth;
