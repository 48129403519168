import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import CallNote from "pages/main/callCard/CallNote";
import React from "react";

const CallNoteMemo = React.memo(CallNote, (prevProps, nextProps) => {
  return (
    prevProps.callState === nextProps.callState
  )
})

const CallNotes = () => {
  const openCallNotes = useSelector((state: RootState) => state.callSlot.openCallNotes)

  return (
    <>
      {openCallNotes.map(callNote => (
        <CallNoteMemo
          key={callNote.conferenceName}
          callState={callNote}
        />
      ))}
    </>
  )
}

export default CallNotes