import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import TaskFrame from "./TaskFrame";
import { useMemo } from "react";

const Tasks = () => {
  const tasks = useSelector((state: RootState) => state.accountPanel.tasks)
  const selectedTask = useSelector((state: RootState) => state.accountPanel.selectedTask)

  const [abbyTasks, nonAbbyTasks] = useMemo(() => {
    const abbyFormsUrl = process.env.REACT_APP_ABBY_FORMS_URL
    if (abbyFormsUrl) {
      return [
        tasks.filter(e => e.link.includes(abbyFormsUrl)),
        tasks.filter(e => !e.link.includes(abbyFormsUrl))
      ]
    }

    return [[], tasks]
  }, [tasks])

  return (
    <>
      {selectedTask &&
        <Typography sx={{ fontWeight: 600, fontSize: '24px', color: '#000' }}>
          {selectedTask}
        </Typography>
      }
      {abbyTasks.map(task => (
        <TaskFrame key={task.name} isSelected={task.name === selectedTask} task={task} isAbby />
      ))}
      {nonAbbyTasks.map(task => (
        <TaskFrame key={task.name} isSelected={task.name === selectedTask} task={task} />
      ))}
    </>
  );
};

export default Tasks;
