import { FC, useState } from 'react';
import { Autocomplete, AutocompleteInputChangeReason, Box, Button, Chip, Collapse, IconButton, InputAdornment, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { Close, DateRange } from '@mui/icons-material';
import moment, { Moment } from 'moment-timezone';
import DateRangePicker, { getDateRangeLabel } from 'components/DateRangePicker';
import HistoryCard from './HistoryCard';
import { CallRecord } from 'utils/calls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FilterItem, MAX_HISTORY_ITEMS } from './useCallHistory';
import { CallHistoryFilters } from 'reducers/slices/callHistoryReducer';
import NewHistoryBanner from './NewHistoryBanner';

const SEARCH_TYPES: FilterItem[] = [
  { label: 'Everywhere', value: 'everywhere' },
  { label: 'Caller Number', value: 'callerNumber' },
  { label: 'Number Caller Called', value: 'numberCallerCalled' },
  { label: 'Notes', value: 'note' },
  { label: 'Transfer to Number', value: 'trfToNum' },
  { label: 'Call SID', value: 'callSid' },
]

const FilterChip = (props: { label: string, value: string, onDelete: () => void }) => {
  return (
    <Chip
      sx={{ mr: 1, mt: 1 }}
      size="small"
      label={(
        <Typography sx={{ fontSize: '12px' }}>
          {`${props.label}: `}
          <Typography component="span" sx={{ fontWeight: 600, fontSize: '12px' }}>{props.value}</Typography>
        </Typography>
      )}
      onDelete={props.onDelete}
    />
  )
}

interface IProps {
  callRecords: CallRecord[],
  startDate: Moment | null,
  setStartDate: (date: Moment | null) => void,
  endDate: Moment | null,
  setEndDate: (date: Moment | null) => void,

  hasMore: boolean,
  loadMore: () => void,
  refreshHistory: () => void,

  isGlobal?: boolean,
  loadNewerData?: () => void,
  isLoadingNewData?: boolean,
  setCallHistoryOpen?: (isOpen: boolean) => void,

  otherFilters: CallHistoryFilters,
  setOtherFilters: (filter: CallHistoryFilters) => void,
}

export const CallHistoryHistory: FC<IProps> = ({
  callRecords,
  startDate, setStartDate,
  endDate, setEndDate,

  hasMore, loadMore,
  refreshHistory,

  isGlobal,
  loadNewerData, isLoadingNewData,
  setCallHistoryOpen,

  otherFilters, setOtherFilters,
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [autocompleteValue, setAutocompleteValue] = useState<FilterItem | null>(null)

  const handleRemoveFilter = (name: keyof CallHistoryFilters) => {
    const newFilters = { ...otherFilters }
    delete newFilters[name]
    setOtherFilters(newFilters)
  }

  const handleChange = (e: any, newValue: string | FilterItem | null) => {
    if (newValue && typeof newValue !== 'string') {
      setOtherFilters({
        ...otherFilters,
        [newValue.value]: search
      })
      setAutocompleteValue(null)
    } else if (typeof newValue === 'string') {
      setOtherFilters({
        ...otherFilters,
        everywhere: newValue,
      })
      setAutocompleteValue(null)
      setSearch('')
    }
  }

  const handleInputChange = (e: any, newValue: any, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      setSearch(newValue)
    } else {
      setSearch('')
    }
  }

  const clearDate = (e: any) => {
    e.stopPropagation()
    setStartDate(moment().subtract(1, 'year').startOf('day'))
    setEndDate(null)
  }

  const hasOtherFilters = !!Object.keys(otherFilters).length
  return (
    <Box p={2} width="calc(100% - 32px)" height="calc(100% - 32px)">
      <Box display="flex" alignItems="center">
        {isGlobal && <Typography style={{ fontWeight: 600, fontSize: '22px' }}>Call History</Typography>}
        <Autocomplete
          sx={{ ml: isGlobal ? 4 : 0, width: 350 }}
          style={{ backgroundColor: '#E4ECFC', borderRadius: '5px' }}
          size="small"
          onChange={handleChange}
          value={autocompleteValue}
          inputValue={search}
          onInputChange={handleInputChange}
          options={search.length ? SEARCH_TYPES : []}
          filterOptions={(x) => x}
          autoSelect
          freeSolo
          blurOnSelect
          isOptionEqualToValue={() => false}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search history"
            />
          )}
          renderOption={(props, option) => (
            <MenuItem {...props} sx={{ height: 36, fontSize: '12px', width: '100%' }}>
              <span style={{ display: 'inline-block', width: 126 }}>
                {option.label}
              </span>
              <span style={{ display: 'inline-block', maxWidth: 'calc(100% - 126px)', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                {search}
              </span>
            </MenuItem>
          )}
        />
        <TextField
          sx={{ ml: 2, width: 275 }}
          size="small"
          placeholder="Filter by date"
          style={{ backgroundColor: '#E4ECFC' }}
          onClick={() => setDatePickerOpen(true)}
          value={getDateRangeLabel(startDate, endDate)}
          InputProps={{
            readOnly: true,
            sx: { fontSize: '16px', pl: 1, pr: 1 },
            startAdornment: (
              <InputAdornment position="start">
                <DateRange sx={{ color: '#A5B5E3' }} />
              </InputAdornment>
            ),
            endAdornment: (startDate || endDate) && (
              <InputAdornment position="end">
                <IconButton sx={{ p: 0 }} onClick={clearDate}>
                  <Close sx={{ color: '#A5B5E3' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          sx={{ ml: 2, mb: '4px' }}
          onClick={refreshHistory}
        >
          Refresh
        </Button>
      </Box>

      <DateRangePicker
        open={datePickerOpen}
        onClose={() => setDatePickerOpen(false)}
        startDate={startDate} setStartDate={setStartDate}
        endDate={endDate} setEndDate={setEndDate}
      />

      <Collapse in={hasOtherFilters}>
        <Box width="100%">
          {Object.entries(otherFilters).map(([key, value]) => (
            <FilterChip
              key={key + value}
              label={SEARCH_TYPES.find(e => e.value === key)?.label || ''}
              value={value}
              onDelete={() => handleRemoveFilter(key as keyof CallHistoryFilters)}
            />
          ))}
        </Box>
      </Collapse>

      <Box
        id="historyCardWrapper"
        sx={{
          mt: 2,  pr: 2,
          width: 'calc(100% - 16px)',
          height: `calc(100% - 40px - 16px - ${hasOtherFilters ? 24 : 0 }px)`,
          overflowY: 'auto',
        }}
      >
        {isGlobal &&
          <NewHistoryBanner
            loadNewerData={loadNewerData}
            isLoadingNewData={isLoadingNewData}
          />
        }

        <InfiniteScroll
          dataLength={hasMore ? callRecords.length + 1 : callRecords.length}
          hasMore={hasMore}
          next={loadMore}
          loader={<Skeleton height={170} sx={{ transform: 'none' }} />}
          scrollableTarget="historyCardWrapper"
          scrollThreshold="800px"
          endMessage={(
            <Typography>{callRecords.length >= MAX_HISTORY_ITEMS
              ? `For performance reason, you can only load up to ${MAX_HISTORY_ITEMS} calls. Please adjust your filters to load more.`
              : callRecords.length ? 'No more calls found with current filters.' : 'No calls found with current filters.'}
            </Typography>
          )}
        >
          {callRecords.map(callRecord => (
            <HistoryCard key={callRecord.id} isGlobal={isGlobal} callRecord={callRecord} setCallHistoryOpen={setCallHistoryOpen} />
          ))}
        </InfiniteScroll>
      </Box>
    </Box>
  );
}

export default CallHistoryHistory;