import React, { FC } from "react";
import { Button, keyframes } from "@mui/material";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
`

const buttonAnimation = {
  background: 'linear-gradient(135deg, #4B21D6 0%, #9536A6 33%, #4B21D6 66%, #9536A6 100%)',
  backgroundSize: '300%',
  animation: `${gradientAnimation} 1.5s linear infinite`,
}

interface IProps {
  label: string,
  loading?: boolean,
  sx?: any,
  [props: string]: any,
}

const AnimatedButton: FC<IProps> = ({ loading = false, label, sx = {}, ...props }) => {
  return (
    <Button
      sx={{
        width: '100%',
        background: 'linear-gradient(135deg, #4B21D6 0%, #9536A6 100%)',
        color: 'white !important',
        borderRadius: '10px',
        opacity: (loading || props.disabled) ? 0.6 : 1,
        ...(loading ? buttonAnimation : {}),
        '&:hover': {
          ...buttonAnimation
        },
        ...sx,
      }}
      {...props}
    >
      {label}
    </Button>
  );
};

export default AnimatedButton;
