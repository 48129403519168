import { Navigate, Route, Routes } from "react-router-dom";
import Login from "pages/auth/Login";
import ResetPassword from "pages/auth/ResetPassword";
import EmailSent from "pages/auth/EmailSent";
import SetPassword from "pages/auth/SetPassword";
import Enroll from "pages/duo/Enroll";
import DuoAuth from "pages/duo/Auth";

const AuthRoutes = () => {

    return (
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/set-password" element={<SetPassword />} />

        <Route path="/duo/enroll/:token" element={<Enroll />} />
        <Route path="/duo/auth/:token" element={<DuoAuth />} />

        <Route index element={<Login />} />
        <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
      </Routes>
    );
};

export default AuthRoutes