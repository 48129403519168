import { useEffect } from "react";
import { useAppDispatch } from "reducers/hooks";
import { setIsCallEnabled, setTwilioLoading } from "reducers/slices/UIReducer";
import useSocket from "./useSocket";

/**
 * Ping socket to ask if this connection can receive call
 */
const useCanReceiveCall = () => {
  const dispatch = useAppDispatch();
  const socket = useSocket();

  useEffect(() => {
    if (!socket) {
      console.warn("Socket is not available yet");
      return;
    }

    // Socket is ready, ping to check if this connection can receive call
    socket.emit("CAN_RECEIVE_CALL", { payload: {} }, (response: any) => {
      if (response?.canReceiveCall) {
        dispatch(setIsCallEnabled(true));
      } else {
        dispatch(setTwilioLoading(false));
      }
    });
  }, [socket, dispatch]);
};

export default useCanReceiveCall