import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import useCallFunctions from "./useCallFunctions";
import { ActiveCall } from "utils/calls";
import { TwilioContext } from "context/twilio-context";
import { setListeningToOperatorId } from "reducers/slices/callSlotReducer";
import { logger } from "utils/utils";

/**
 * Follow operator's call and join/leave as they do.
 */
const useListeningToOperator = () => {
  const device = useContext(TwilioContext)?.device
  const operatorCalls = useSelector((state: RootState) => {
    const listeningToOperatorId = state.callSlot.listeningToOperatorId
    if (!listeningToOperatorId) return []

    const userCalls = state.callSlot.otherUserCalls[listeningToOperatorId]
    return userCalls.map(conferenceName => state.call[conferenceName])
  })
  const [isListeningToCall, setIsListeningToCall] = useState<string>('')
  const { listenToCall } = useCallFunctions()
  const dispatch = useDispatch()

  useEffect(() => {
    const handleUpdate = async () => {
      const activeCall = operatorCalls.find(call => call && ActiveCall.includes(call.state))
      if (!activeCall && isListeningToCall) {
        await device?.disconnectAll()
        logger('Stopped listening to call ' + isListeningToCall)
        setIsListeningToCall('')

        if (!operatorCalls.length) {
          dispatch(setListeningToOperatorId(null))
        }
      }

      if (activeCall) {
        if (activeCall.conferenceName !== isListeningToCall) {
          await device?.disconnectAll()
          await listenToCall(activeCall.conferenceName)
          setIsListeningToCall(activeCall.conferenceName)
          logger('Started listening to call ' + activeCall.conferenceName)
        }
      }
    }

    handleUpdate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, operatorCalls])

  return null
}

export default useListeningToOperator