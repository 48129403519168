import { DtmfProvider } from "./DtmfProvider"
import { TwilioProvider } from "./TwilioProvider"
import { SocketProvider } from "./SocketProvider"
import { RingtoneProvider } from "./RingtoneProvider"

export const ContextProvider = (props: any) => {
  return (
    <DtmfProvider>
      <TwilioProvider>
        <SocketProvider>
          <RingtoneProvider>
            {props.children}
          </RingtoneProvider>
        </SocketProvider>
      </TwilioProvider>
    </DtmfProvider>
  )
}