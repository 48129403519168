export interface FormDetails {
  subject?: string,
  questions: FormQuestion[],
  instructions?: string,
  name?: string,
  id: string,
  contacts: FormContact[],
  askAIForms: boolean,
  accountName: string,
  usaStates: string[],
  dictionaries: {
    id: string,
    clientDictionaryRecords?: ClientDictionaryRecords[],
  }[],
  childForms?: Record<string, FormDetails>,
}

export interface FormQuestion {
  id: string,
  fieldType: FieldType,
  isRequired: boolean,
  questionNumber: number,
  question: string,
  instructionPosition: InstructionPosition,
  instructions?: string,
  HTMLInstructions?: string,
  redirectToForm?: string,
  picklist?: Picklist[],
  dictionaryId?: string,
  confirmSpelling?: boolean,

  // conditions to show this question, all must be met
  conditions?: QuestionCondition[]

  // Form to load, used with FieldType.Loading
  formId?: string,

  // Generated by us
  isSystem?: boolean,
  clientDictionaryRecords?: ClientDictionaryRecords[],
  // Hide in ui, but accepted as visible question
  hideOnUi?: boolean,
}

export interface QuestionCondition {
  // ID of question to show this
  conditionalId: string,
  // ID of picklist answer to show this - if picklist
  conditionalValue?: string
}

export enum FieldType {
  Picklist = 'Picklist',
  Textbox = 'Textbox',
  Textarea = 'Textarea',
  Markbox = 'Markbox',
  States = 'USA States',
  Contacts = 'Account Contacts',
  ContactsMultiple = 'Account Contacts Multiple',
  Custom = 'Custom',

  // Special case
  Loading = 'Loading',
  Instruction = 'Instruction',
}

export const TEXT_FIELDS = [FieldType.Textbox, FieldType.Textarea]
export const SPECIAL_FIELDS = [FieldType.Loading, FieldType.Instruction]

export enum InstructionPosition {
  Above = 'Above',
  Below = 'Below',
  Left = 'Left',
  Right = 'Right'
}

interface Picklist {
  id: string,
  value: string,
  instructions?: string,
  redirectToForm?: string,
}

interface ClientDictionaryRecords {
  id: string,
  name: string,
  value: string,
  contactId?: string,
}

export interface FormContact {
  name: string,
  id: string,
  email?: string,
  doNotUseInAccountContactsQuestion: boolean,
  // Comma separated string
  additionalEmails?: string,
  catchAll?: boolean,
}

export const getFormIdsFromFormDetails = (formDetails: FormDetails) => {
  const formIds = new Set<string>()

  formDetails.questions.forEach(question => {
    if (question.redirectToForm) {
      formIds.add(question.redirectToForm)
    }

    if (question.picklist) {
      question.picklist.forEach(picklist => {
        if (picklist.redirectToForm) {
          formIds.add(picklist.redirectToForm)
        }
      })
    }
  })

  return formIds
}

export const CALL_SID_QUESTION: FormQuestion = {
  id: 'callSid',
  fieldType: FieldType.Textbox,
  isRequired: true,
  questionNumber: 0,
  question: 'Call SID',
  instructionPosition: InstructionPosition.Below,
  instructions: '<i>Call SID should start with CA and should be 34 characters long.</i>',
  isSystem: true,
}

export const CONTACTS_QUESTION: FormQuestion = {
  id: 'contacts-multiple',
  fieldType: FieldType.ContactsMultiple,
  isRequired: true,
  questionNumber: 999,
  question: 'Contacts',
  instructionPosition: InstructionPosition.Below,
  isSystem: true,
}

export const REGARDING_DELIMITER = '%%%'

export type FormContactsRequest = {
  formId: string,
  questions: {
    questionId: string,
    answer: string | null,
    answerId: string | null,
  }[]
}

export type FormSubmission = {
  askAIForms: boolean,
  callSid: string,
  // Array of selected contact ids
  contacts: string[],
  subject: string,
  // Array of question ids
  questions: string[],
  questionAnswerPairs: { question: string; answer?: string, answerId?: string, fieldType: FieldType }[];
  disclaimer?: string | null,
  receptionistId: string,
}

export const AI_CHECKED = 'AI_CHECKED'

export const ANSWER_ID_FIELDS = [FieldType.Picklist, FieldType.Contacts, FieldType.Custom]

export const isValidCallSid = (callSid?: string) => {
  if (!callSid) return false
  return callSid.startsWith('CA') && callSid.length === 34
}
