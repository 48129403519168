import { Box, Collapse, FormLabel, IconButton, InputAdornment, Stack, styled, Switch, TextField } from "@mui/material"
import { FC, useMemo } from "react";
import { AI_CHECKED, FormQuestion, REGARDING_DELIMITER } from "../utils";
import TextareaInput from "./TextareaInput";
import CheckGreen from "assets/CheckGreen";
import CheckBlack from "assets/CheckBlack";

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  error?: string,
}

const MarkTextField = styled(TextField)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 0,
  }
})


const MarkfieldInput: FC<IProps> = (props) => {
  const { question, handleChange, value, error } = props;

  const isRegarding = useMemo(() => {
    return question.question.toLowerCase().includes('regarding')
  }, [question.question])

  const [marked, addDisclaimer, disclaimer] = useMemo(() => {
    if (!value) return ['', '', '']
    if (!isRegarding) return [value, '', '']

    return value.split(REGARDING_DELIMITER)
  }, [isRegarding, value])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [markedError, addDisclaimerError, disclaimerError] = useMemo(() => {
    if (!error) return ['', '', '']
    if (!isRegarding) return [error, '', '']

    return error.split(REGARDING_DELIMITER)
  }, [isRegarding, error])

  const onChange = () => {
    if (isRegarding) {
      if (marked === AI_CHECKED) {
        handleChange(question.id, ['', addDisclaimer, disclaimer].join(REGARDING_DELIMITER))
      } else {
        handleChange(question.id, [AI_CHECKED, addDisclaimer, disclaimer].join(REGARDING_DELIMITER))
      }

      return
    } else {
      handleChange(question.id, marked ? '' : AI_CHECKED)
    }
  }

  const onAddDisclaimerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    handleChange(question.id, [isChecked ? AI_CHECKED : marked, isChecked ? 'add' : '', disclaimer].join(REGARDING_DELIMITER))
  }

  const handleDisclaimerChange = (questionId: string, value: string) => {
    handleChange(question.id, [marked, addDisclaimer, value].join(REGARDING_DELIMITER))
  }

  return (
    <Box>
      <MarkTextField
        variant="outlined"
        fullWidth
        disabled
        InputLabelProps={{ sx: { color: '#000 !important', fontWeight: 400 }}}
        label={question.question + (question.confirmSpelling ? ' (confirm the spelling)' : '')}
        required={question.isRequired}
        error={!!markedError}
        helperText={markedError}
        InputProps={{
          sx: { bgcolor: 'rgb(242, 242, 242)', pr: 1 },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton sx={{ p: 0 }} onClick={onChange}>
                {marked ?
                  <CheckGreen />
                  :
                  <CheckBlack />
                }
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {isRegarding &&
        <>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Switch onChange={onAddDisclaimerChange} checked={addDisclaimer === 'add'} />
            <FormLabel sx={{ fontWeight: 500 }}>Add Disclaimer</FormLabel>
          </Stack>

          <Collapse in={addDisclaimer === 'add'}>
            <FormLabel required error={!!disclaimerError} sx={{ fontWeight: 500 }}>Disclaimer</FormLabel>
            <TextareaInput
              question={question}
              handleChange={handleDisclaimerChange}
              value={disclaimer}
              error={disclaimerError}
            />
          </Collapse>
        </>
      }
    </Box>
  )
}

export default MarkfieldInput;
