import { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import { deleteAvatar } from "api/users";
import { Close } from "@mui/icons-material";

type IProps = {
  setIsLoading: (isLoading: string) => void,
  fetchAccount: () => void,
  open: boolean,
  onClose: () => void,
}

const DeleteAvatarDialog: FC<IProps> = ({ setIsLoading, fetchAccount, open, onClose }) => {
  const dispatch = useDispatch()

  const handleDeleteAvatar = () => {
    onClose()
    setIsLoading('delete')
    deleteAvatar().then(res => {
      fetchAccount()
      dispatch(setSnackbar({ message: 'Avatar has been deleted', severity: SnackbarSeverity.SUCCESS }))
    }).finally(() => {
      setIsLoading('')
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {'Delete Avatar'}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#E80B0B',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pr: 6 }}>
        <Typography>Are you sure you want to delete your avatar?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleDeleteAvatar}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAvatarDialog;
