import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsListeningToRecording } from "reducers/slices/callHistoryReducer";
import { RootState } from 'reducers/store';

export interface AudioPlayer {
  isPlaying: boolean,
  isEnded: boolean,
  currentTime: number,
  totalDuration: number,
  playbackRate: number,
  play: () => void,
  pause: () => void,
  seek: (time: number) => void,
  rewind: (time: number) => void,
  forward: (time: number) => void,
  changeSpeed: (speed: number) => void,
}

const useAudioPlayer = (audioSource: any, duration?: number | null): AudioPlayer => {
  const [audioItem, setAudioItem] = useState<HTMLAudioElement | null>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [isEnded, setIsEnded] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [totalDuration, setTotalDuration] = useState<number>(duration || 0)
  const [playbackRate, setPlaybackRate] = useState<number>(1)
  const isListeningToRecording = useSelector((state: RootState) => state.callHistory.isListeningToRecording)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      audioItem?.pause()
      audioItem?.remove()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioItem])

  useEffect(() => {
    if (isListeningToRecording !== audioSource && isPlaying) {
      pause()
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListeningToRecording])

  useEffect(() => {
    if (!isPlaying || !audioItem) {
      return
    }

    const id = setInterval(() => {
      setCurrentTime(audioItem?.currentTime)
    }, 100)

    return () => clearInterval(id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  const play = () => {
    let audioEl = audioItem
    if (!audioEl) {
      audioEl = new Audio(audioSource)
      setAudioItem(audioEl)

      audioEl.addEventListener('loadeddata', () => {
        setTotalDuration(audioEl?.duration || 0)
        if (audioEl) {
          audioEl.currentTime = currentTime
          audioEl.playbackRate = playbackRate
        }
      })
      audioEl.addEventListener('ended', () => {
        setIsPlaying(false)
        setIsEnded(true)
        setCurrentTime(audioEl?.duration || 0)
      })
    }

    if (audioEl.ended) {
      audioEl.currentTime = 0
    }

    audioEl.play()
    setCurrentTime(audioEl.currentTime)
    setIsPlaying(true)
    setIsEnded(false)
    dispatch(setIsListeningToRecording(audioSource))
  }

  const pause = () => {
    if (audioItem) {
      audioItem.pause()
    }
    setIsPlaying(false)
  }

  const seek = (time: number) => {
    setCurrentTime(time)

    if (audioItem) audioItem.currentTime = time
    if (!isPlaying) play()
  }

  const rewind = (time: number) => {
    let newTime = currentTime - time
    if (newTime < 0) newTime = 0

    seek(newTime)
  }

  const forward = (time: number) => {
    let newTime = currentTime + time
    if (newTime > totalDuration) newTime = totalDuration

    seek(newTime)
  }

  const changeSpeed = (speed: number) => {
    setPlaybackRate(speed)

    if (audioItem) audioItem.playbackRate = speed
  }

  return {
    isPlaying,
    isEnded,
    currentTime,
    totalDuration,
    playbackRate,
    play,
    pause,
    seek,
    rewind,
    forward,
    changeSpeed,
  }
}

export default useAudioPlayer