import { Box, Button, Collapse, Divider, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { CallRecord, FormData, prepareFormData } from 'utils/calls';
import { CallMade, CallReceived, Chat, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { formatDate } from "utils/utils";
import { useMemo, useRef, useState } from "react";
import { getFormData } from "api/callrecords";

const Info = (props: { label: string, value?: string | null }) => (
  <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{`${props.label || 'Unknown'}: `}
    <Typography component="span" style={{ fontWeight: 400, fontSize: '12px' }}>{props.value || 'N/A'}</Typography>
  </Typography>
)

const CallerHistoryCard = ({ callRecord }: { callRecord: CallRecord }) => {
  const cardRef = useRef<HTMLDivElement | null>(null)
  const [showForms, setShowForms] = useState<boolean>(false)
  const [formDatas, setFormDatas] = useState<FormData[]>([])
  const [isLoadingForms, setIsLoadingForms] = useState<number>(0)
  const [formsPerLoad] = useState<number>(3)

  const completedFormIds = useMemo(() => {
    if (!callRecord.completedFormIds) return []
    let completedFormIds: string[] = []
    try {
      completedFormIds = JSON.parse(callRecord.completedFormIds)
    } catch (err) {
      console.log(err)
    }

    return completedFormIds
  }, [callRecord?.completedFormIds])

  const unloadedFormIds = useMemo(() => {
    return completedFormIds.filter(formId => !formDatas.some(e => e.id === formId))
  }, [completedFormIds, formDatas])

  const onShowForms = async () => {
    if (!formDatas.length) {
      loadMoreForms()
    }
    setShowForms(true)
  }

  const collapseCard = () => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView()
    }
    setShowForms(false)
  }

  const loadMoreForms = async () => {
    const formIdsToLoad = unloadedFormIds.slice(0, formsPerLoad)

    setIsLoadingForms(formIdsToLoad.length)

    const promises = formIdsToLoad.map(getFormData)
    Promise.all(promises).then((res) => {
      const newFormDatas = formDatas.concat(res.map(e => prepareFormData(e.data)))
      setFormDatas(newFormDatas)
    }).finally(() => setIsLoadingForms(0))
  }

  return (
    <Box
      sx={{
        borderRadius: '5px',
        pt: '12px',
        px: !!completedFormIds.length ? 0 : '12px',
        mb: '4px',
        backgroundColor: '#F5F8FD',
      }}
      ref={cardRef}
    >
      <Box
        sx={{
          px: !!completedFormIds.length ? '12px' : 0,
          pb: !!completedFormIds.length ? 0 : '12px',
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start" width="100%">
          {callRecord?.direction === 'Incoming' ?
            <CallReceived sx={{ color: '#448C3D', fontSize: '20px' }} />
            :
            <CallMade sx={{ color: '#2F6FCF', fontSize: '20px' }} />
          }
          <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
            {!callRecord.endTime
              ? 'Ongoing'
              : (callRecord.answeredBy || (callRecord.direction === 'Incoming' && 'Unanswered'))}
            {callRecord.answeredByStaffExtension && ` (ext ${callRecord.answeredByStaffExtension})`}
          </Typography>
          {!!completedFormIds.length &&
            <Box ml="auto!important">
              <Tooltip title="Form(s) filled">
                <Chat sx={{ color: '#1033A5', fontSize: '20px' }} />
              </Tooltip>
            </Box>
          }
        </Stack>
        <Typography sx={{ mt: '4px', fontWeight: 600, fontSize: '12px' }}>{'Start Time: '}
          <Typography component="span" style={{ fontWeight: 400, fontSize: '12px' }}>
            {formatDate(callRecord.startTime, 'MM/DD/YYYY hh:mm:ss A')}
          </Typography>
        </Typography>
      </Box>

      {!!completedFormIds.length &&
        <Collapse in={showForms} collapsedSize="24px">
          <Box>
            {!showForms &&
              <Button
                endIcon={<KeyboardArrowDown sx={{ color: '#1033A5' }} />}
                onClick={onShowForms}
                disableElevation
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#F5F8FD',
                  color: '#1033A5',
                  fontSize: '12px',
                  height: 24,
                  borderRadius: 0,
                  justifyContent: 'flex-end'
                }}
              >
                View Form(s) Details
              </Button>
            }

            <Box px="12px" pb={1}>
              {formDatas.map(formData => (
                <Box key={formData.id}>
                  <Divider sx={{ my: '8px' }} />
                  <Info label="Date" value={formData.date} />

                  <Tooltip title={formData.sentTo.join(', ')}>
                    <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{'Sent to: '}
                      <Typography component="span" style={{ fontWeight: 400, fontSize: '12px' }}>
                        {formData.sentTo[0]}
                        {formData.sentTo.length > 1 ? ` & ${formData.sentTo.length - 1} more` : ''}
                      </Typography>
                    </Typography>
                  </Tooltip>

                  <Info label="Date" value={formData.date} />
                  <Info label="Subject" value={formData.subject} />
                  {Object.entries(formData.details).map(([label, value], index2) => (
                    <Info key={index2} label={label} value={value} />
                  ))}
                </Box>
              ))}
              {!!isLoadingForms &&
                [...new Array(isLoadingForms)].map((e, i) => (
                  <Skeleton key={i} sx={{ mt: 2 }} variant="rectangular" width="100%" height={32} />
                ))
              }
            </Box>

            {(!!unloadedFormIds.length && !isLoadingForms) &&
              <Button
                endIcon={<KeyboardArrowDown sx={{ color: '#1033A5' }} />}
                onClick={loadMoreForms}
                disableElevation
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#F5F8FD',
                  color: '#1033A5',
                  fontSize: '12px',
                  height: 24,
                  borderRadius: 0,
                  justifyContent: 'flex-end'
                }}
              >
                See More Forms
              </Button>
            }

            {showForms &&
              <Button
                endIcon={<KeyboardArrowUp sx={{ color: '#1033A5' }} />}
                onClick={collapseCard}
                disableElevation
                size="small"
                sx={{
                  width: '100%',
                  backgroundColor: '#F5F8FD',
                  color: '#1033A5',
                  fontSize: '12px',
                  height: 24,
                  borderRadius: 0,
                  justifyContent: 'flex-end'
                }}
              >
                Hide Form(s) Details
              </Button>
            }
          </Box>
        </Collapse>
      }
    </Box>

  );
};

export default CallerHistoryCard;
