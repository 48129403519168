import React from "react";
import {
  Box, MenuItem, TextField, Typography
} from "@mui/material";
import { useAppSelector } from "reducers/hooks";
import { useDispatch } from "react-redux";
import { setCallerDelay } from "reducers/slices/UIReducer";

const DELAYS = [
  { label: '0ms', value: '0' },
  { label: '500ms', value: '500' },
  { label: '1000ms', value: '1000' },
  { label: '1500ms', value: '1500' },
  { label: '2000ms', value: '2000' },
]

const TwilioDelaySetting = () => {
  const callerDelay = useAppSelector(state => state.ui.callerDelay);
  const dispatch = useDispatch()


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setCallerDelay(event.target.value))
  }

  return (
    <Box>
      <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Caller Join Delay</Typography>
      <TextField
        sx={{ mt: 1 }}
        value={callerDelay}
        onChange={handleChange}
        SelectProps={{
          sx: {
            backgroundColor: '#E4ECFC',
            borderColor: '#CBD8F1',
          },
          MenuProps: { sx: { width: 350 } },
        }}
        size="small"
        select
        fullWidth
      >
        {DELAYS.map(delay => (
          <MenuItem
            key={delay.value}
            value={delay.value}
          >
            <Typography>
              {delay.label}
            </Typography>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default TwilioDelaySetting;
