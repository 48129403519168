import React, { useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { updateUser } from "reducers/slices/userReducer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormLabel, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import logo from '../../assets/logo.svg';
import parseYupErrors from "utils/parseYupErrors";
import { login } from "api/auth";
import BackgroundLayout from "pages/BackgroundLayout";
import { useNavigate } from "react-router-dom";
import AnimatedButton from "components/buttons/AnimatedButton";
import { useAppSelector } from "reducers/hooks";
import { setLoginError } from "reducers/slices/UIReducer";

const loginSchema = yup.object().shape({
  email: yup.string().email('Email Address format is invalid').required().label('Email Address'),
  password: yup.string().required().label('Password')
})

interface LoginForm {
  email: string,
  password: string
}

const defaultForm: LoginForm = {
  email: '',
  password: '',
}

const Login = () => {
  const [form, setForm] = useState<LoginForm>({ ...defaultForm })
  const [errors, setErrors] = useState<LoginForm>({ ...defaultForm })
  const [loading, setLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const errorMessage = useAppSelector(state => state.ui.loginError)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleChange = (name: keyof LoginForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: event.target.value,
    })
    setErrors({ ...defaultForm })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setErrors({ ...defaultForm })
    dispatch(setLoginError(''))
    setLoading(true)
    loginSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        login({ login: form.email, password: form.password })
          .then((res) => {
            const {
              accessToken,
              authNextStep,
            } = res.data

            if (authNextStep === 'none') {
              dispatch(updateUser(accessToken));
            }

            if (authNextStep === 'enroll') {
              navigate('/duo/enroll/' + accessToken)
            }

            if (authNextStep === 'auth') {
              navigate('/duo/auth/' + accessToken)
            }
          })
          .catch((err) => {
            dispatch(setLoginError(err.response.data))
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setLoading(false)
      })
  }

  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              px: 6,
              py: 8,
              width: 350,
            }}
          >
            <Box mb={8}>
              <img width="175" height="85" src={logo} alt="Logo" />
            </Box>
            <TextField
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
              }}
              placeholder="Email Address"
              fullWidth
              size="small"
              value={form.email}
              onChange={handleChange('email')}
              error={!!errors.email}
              helperText={errors.email || ''}
              InputProps={{
                sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
              }}
            />
            <TextField
              sx={{
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
              }}
              placeholder="Password"
              fullWidth
              size="small"
              value={form.password}
              onChange={handleChange('password')}
              type={showPassword ? 'text' : 'password'}
              error={!!errors.password}
              helperText={errors.password || ''}
              InputProps={{
                sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      sx={{ color: '#A5B5E3' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Box width="100%" display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  color: '#1033A5',
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  }
                }}
                onClick={() => navigate('/reset-password')}
              >
                Forgot Password?
              </Typography>
            </Box>
            <FormLabel sx={{ my: 1 }} error>{errorMessage}&nbsp;</FormLabel>
            <AnimatedButton
              loading={loading}
              label={loading ? 'Logging in...' : 'Login'}
              type="submit"
              disabled={loading}
            />
          </Box>
        </form>
      </Paper>
    </BackgroundLayout>
  );
};

export default Login;
