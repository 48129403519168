import { Close, KeyboardArrowDown } from "@mui/icons-material";
import { createTheme } from "@mui/material";
import { ReactComponent as CheckboxIcon } from "assets/CheckBox.svg";

declare module '@mui/material/styles' {
  interface Theme {
    [key: string]: any
  }
  interface ThemeOptions {
    [key: string]: any
  }
  interface TypographyOptions {
    [key: string]: any
  }
  interface Palette {
    [key: string]: any
  }
  interface PaletteOptions {
    [key: string]: any
  }
  interface PaletteColor {
    [key: string]: any
  }
  interface SimplePaletteColorOptions {
    [key: string]: any
  }
}

const theme = createTheme({
  typography: {
    fontFamily: '"Open Sans"',
    fontSize: 14,
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '24px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        contained: {
          color: 'black',
          textTransform: 'none',
          fontWeight: 600,
          backgroundColor: '#FFFFFF',
          height: 32,
          '&:hover': { 
            backgroundColor: '#EEEEEE'
          },
          '&.Mui-disabled > .MuiButton-startIcon': {
            opacity: 0.5,
          }
        },
        containedSecondary: {
          color: '#FFF',
          backgroundColor: '#1033A5',
          '&:hover': {
            backgroundColor: '#0F2294'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDown,
      },
      styleOverrides: {
        select: {
          fontSize: '14px',
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: '#A5B5E3',
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
        input: {
          '&::placeholder': {
            color: '#A5B5E3',
            opacity: 1,
            fontWeight: 500,
          },
        },
        notchedOutline: {
          borderColor: '#E3EBFC',
          borderRadius: '5px',
        },
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          width: 32,
          height: 32,
          fontSize: 12,
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation0: {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: 'top'
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
        },
        label: {
          fontSize: '13px',
          fontWeight: 600,
        },
      }
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckboxIcon />,
      },
      styleOverrides: {
        root: {
          color: '#CBD8F1',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 20, height: 20,
        },
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBF1FF',
          borderRadius: '10px',
        },
        bar: {
          backgroundColor: '#1033A5',
          borderRadius: '10px',
        },
      }
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <Close />,
      },
      styleOverrides: {
        root: {
          backgroundColor: '#1033A5',
          borderRadius: '5px',
          color: '#FFFFFF',
        },
        deleteIcon: {
          color: '#FFFFFF',
          opacity: 0.7,
          '&:hover': {
            color: '#FFFFFF',
            opacity: 1,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#1033A5'
        },
        thumb: {
          width: '14px',
          height: '14px',
        }
      },
    },
  }
})

export default theme;