import { useEffect } from 'react';
import { Box } from '@mui/material';
import CallHistoryFilters from './Filters';
import CallHistoryHistory from '../../callHistory/History';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/store';
import useCallHistory from '../../callHistory/useCallHistory';

export const AccountCallHistory = () => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const {
    startDate, setStartDate,
    endDate, setEndDate,
    selectedBlocks, setSelectedBlocks,
    selectedTeams, setSelectedTeams,
    selectedReceptionists, setSelectedReceptionists,
    selectedActivityTypes, setSelectedActivityTypes,
    setSelectedAccounts,
    selectedDirections, setSelectedDirections,
    otherFilters, setOtherFilters,
    callRecords,
    hasMore,
    refreshHistory, loadNextPage
  } = useCallHistory({
    defaultFilters: { accounts: [{ label: 'a', value: account?.id || '' }] }
  })

  useEffect(() => {
    setSelectedAccounts([{ label: 'a', value: account?.id || '' }])
    refreshHistory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  return (
    <Box display="flex" height="calc(100% - 36px)" width="100%">
      <Box
        sx={{
          width: '320px',
          height: '100%'
        }}
      >
        <CallHistoryFilters
          selectedBlocks={selectedBlocks} setSelectedBlocks={setSelectedBlocks}
          selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}
          selectedReceptionists={selectedReceptionists} setSelectedReceptionists={setSelectedReceptionists}
          selectedActivityTypes={selectedActivityTypes} setSelectedActivityTypes={setSelectedActivityTypes}
          selectedDirections={selectedDirections} setSelectedDirections={setSelectedDirections}
        />
      </Box>

      <Box width="calc(100% - 320px)" height="100%">
        <CallHistoryHistory
          callRecords={callRecords}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}

          hasMore={hasMore}
          loadMore={loadNextPage}
          refreshHistory={refreshHistory}

          otherFilters={otherFilters}
          setOtherFilters={setOtherFilters}
        />
      </Box>
    </Box>
  );
}

export default AccountCallHistory;