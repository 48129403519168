import { Box, Divider, Typography } from "@mui/material"
import { FlagNote } from "utils/calls"
import { formatDate } from "utils/utils"

const FlagNotes = ({ flagNotes }: { flagNotes: FlagNote[] }) => {
  return (
    <>
      {flagNotes.map((flagNote, i) => (
        <Box key={i}>
          {i !== 0 && <Divider sx={{ my: '2px' }} />}
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>{formatDate(flagNote.createdOn, 'MM/DD/YYYY h:mm A')} {flagNote.answeredBy}</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>{flagNote.note}</Typography>
        </Box>
      ))}
    </>
  )
}

export default FlagNotes