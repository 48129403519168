import { FC, useEffect, useMemo, useState } from 'react'
import { Button, keyframes, Paper } from '@mui/material';
import { Check, Close, Pause, StopCircle } from '@mui/icons-material';
import { CallState, CallStateState, CallType, CanAnswer, CanHold, CanStopTransfer, TimestampAction, getCallColors } from 'utils/calls';
import useCallFunctions from 'hooks/useCallFunctions';
import { useDispatch } from 'react-redux';
import { openCallNote } from 'reducers/slices/callSlotReducer';
import { useAppSelector } from 'reducers/hooks';
import CallerInfo from './CallerInfo';
import CallNoteDisplay from './CallNoteDisplay';

interface IProps {
  callState: CallState,
  isSelected: boolean
  onSelect: () => any
}

const blinking = keyframes`
  0% {
    background-color: #FF9999;
  }
  50% {
    background-color: #FFE699;
  }
  100% {
    background-color: #FF9999;
  }
`
const buttonStyles = {
  p: '6px',
  width: 76,
  height: 24,
  fontSize: '12px',
  '&.Mui-disabled': { backgroundColor: '#b8b8b8' },
}

const iconStyles = {
  width: '18px',
  height: '18px',
  mr: '-6px',
}

const CallCard: FC<IProps> = ({
  callState,
  isSelected,
  onSelect,
}: IProps) => {
  const {
    state,
    timestamps,
    conferenceName,
    isProcessing,
    type,
    requeued,
  } = callState;

  const {
    joinCall,
    holdCall,
    leaveCall,
    stopTransfer,
  } = useCallFunctions()

  // If the call is onHold for more than 1 min, then start blinking
  const [shouldBlink, setShouldBlink] = useState<boolean>(false)
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const dispatch = useDispatch()

  useEffect(() => {
    if (state !== CallStateState.ONHOLD) return setShouldBlink(false)

    const timestamp = timestamps.find(e => e.action === TimestampAction.HOLD)
    if (!timestamp) return setShouldBlink(false)

    const now = Date.now()
    if (now - timestamp.timestamp > (60 * 1000)) {
      return setShouldBlink(true)
    }

    const timeout = setTimeout(() => {
      setShouldBlink(true)
    }, 60 * 1000 - (now - timestamp.timestamp))

    return () => clearTimeout(timeout)
  }, [state, timestamps])

  const [cardColor, textColor] = useMemo(() => {
    return getCallColors(state)
  }, [state])

  const handleClickEvents = (func?: any) => (event: any) => {
    event.stopPropagation()
    event.preventDefault()

    if (event.type === 'click') {
      onSelect()
      func && func()
    }
  }

  const openNote = () => {
    dispatch(openCallNote({
      callerNumber: callState.callerNumber,
      callRecordId: callState.callRecordId,
      conferenceName: callState.conferenceName,
      hipaaCompliant: callState.hipaaCompliant,
      name: callState.name,
      notes: callState.notes,
    }))
  }

  const notesDisabled = !callState.callRecordId || callState.state === CallStateState.UNANSWERED
  const actionsDisabled = !callEnabled || isProcessing
  return (
    <Paper
      elevation={0}
      sx={{
        width: 210,
        height: 98,
        p: 1,
        bgcolor: cardColor,
        borderRadius: '5px',
        cursor: 'pointer',
        border: isSelected ? '2px solid #FFFFFF' : `2px solid ${cardColor}`,
        outline: `2px solid ${cardColor}`,
        animation: shouldBlink ? `${blinking} 1s infinite` : ''
      }}
      onClick={handleClickEvents()}
      onContextMenu={handleClickEvents()}
    >
      <CallerInfo callState={callState} textColor={textColor} />
      <CallNoteDisplay callState={callState} onClick={handleClickEvents(() => !notesDisabled && openNote())}>
        {(CanAnswer.includes(state) &&
          !(state === CallStateState.UNANSWERED && type === CallType.OUTBOUND && !requeued) &&
          type !== CallType.UNBOUND) &&
          <Button
            sx={buttonStyles}
            startIcon={<Check sx={{ color: '#448C3D', ...iconStyles }} />}
            onClick={handleClickEvents(() => joinCall(conferenceName))}
            onContextMenu={handleClickEvents(() => joinCall(conferenceName))}
            disabled={actionsDisabled}
          >
            {state === CallStateState.UNANSWERED ? 'Answer' : 'Speak'}
          </Button>
        }
        {(state === CallStateState.UNANSWERED && type === CallType.OUTBOUND && !requeued) &&
          <Button
            sx={buttonStyles}
            startIcon={<Close sx={{ color: '#9E0000', ...iconStyles }} />}
            onClick={handleClickEvents(() => leaveCall(conferenceName))}
            onContextMenu={handleClickEvents(() => leaveCall(conferenceName))}
            disabled={actionsDisabled}
          >
            Hangup
          </Button>
        }
        {(CanHold.includes(state) && type !== CallType.UNBOUND) &&
          <Button
            sx={buttonStyles}
            startIcon={<Pause sx={{ color: '#EDB63F', ...iconStyles }} />}
            onClick={handleClickEvents(() => holdCall(conferenceName))}
            onContextMenu={handleClickEvents(() => holdCall(conferenceName))}
            disabled={actionsDisabled}
          >
            Hold
          </Button>
        }
        {(CanStopTransfer.includes(state) && type !== CallType.UNBOUND) &&
          <Button
            sx={{ ...buttonStyles, width: 83 }}
            startIcon={<StopCircle sx={{ color: '#9E0000', ...iconStyles }} />}
            onClick={handleClickEvents(() => stopTransfer(conferenceName))}
            onContextMenu={handleClickEvents(() => stopTransfer(conferenceName))}
            disabled={actionsDisabled}
          >
            Stop Trns
          </Button>
        }
      </CallNoteDisplay>
    </Paper>
  );
}

export default CallCard