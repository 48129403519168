import { useRef } from "react";

/**
 * A simple timer to cancel functions if another function call was just made
 */
const useThrottledAction = (delayMs = 500) => {
  const lastEvoked = useRef<number>(Date.now())
  
  const isThrottled = () => {
    const now = Date.now()
    if (now - lastEvoked.current > delayMs) {
      lastEvoked.current = now
      return false
    }

    return true
  }

  return isThrottled
}

export default useThrottledAction