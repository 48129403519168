import agent from './agent'

export function getAccount(accountId: string) {
  return agent.get('/companies/' + accountId)
}

export function getFaqs(accountId: string) {
  return agent.get(`/companies/${accountId}/faqs`, {
    params: {
      limit: 1000,
    }
  })
}

export function getAccountContacts(accountId: string) {
  return agent.get('/contacts', {
    params: {
      accountId,
      limit: 1000,
    }
  })
}

export function getContactDetail(contactId: string) {
  return agent.get('/contacts/' + contactId)
}

export function searchCompanies(name: string, limit: number = 20) {
  return agent.get('/companies', {
    params: {
      name,
      limit: 20,
    },
  })
}