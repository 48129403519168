import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userReducer";
import UISlicer from "../slices/UIReducer";
import callSlotReducer from "../slices/callSlotReducer";
import callReducer from "../slices/callReducer";
import accountPanelReducer from "../slices/accountPanelReducer";
import activityViewReducer from "../slices/activityViewReducer";
import callHistoryReducer from "reducers/slices/callHistoryReducer";
import cacheReducer from "../slices/cacheReducer";
import eventReducer from "../slices/eventReducer";
import { logger } from "utils/utils";

// https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki/61943631#61943631

const combinedReducer = combineReducers({
  user: userReducer,
  ui: UISlicer,
  callSlot: callSlotReducer,
  call: callReducer,
  accountPanel: accountPanelReducer,
  activityView: activityViewReducer,
  callHistory: callHistoryReducer,
  cache: cacheReducer,
  event: eventReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logout') {
    state = undefined
    logger('Logging out of the app')
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
