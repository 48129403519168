import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Popper, Skeleton, TextField, Typography } from "@mui/material";
import moment from "moment-timezone";
import { Close, Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { ActiveCall, CallStateState } from "utils/calls";
import { nameToInitial } from "utils/utils";
import debounce from 'awesome-debounce-promise';
import { searchUsers } from "api/users";
import { addOrUpdateUsers, setSearchUser, setSelectedUser } from "reducers/slices/activityViewReducer";
import { CallStats } from "./ActivityView";

const HeaderCard = (props: any) => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        height: 40,
        mr: 2,
        borderRadius: '5px',
        '&:last-child': { mr: 0 }
      }}
    >
      {props.children}
    </Paper>
  )
}

const CountCard = ({ title, count, color }: any) => {
  return (
    <HeaderCard>
      <Typography sx={{ fontWeight: 600, mr: 2 }}>{title}</Typography>
      <Typography style={{ fontWeight: 700, fontSize: '18px', color }}>{count}</Typography>
    </HeaderCard>
  )
}

export const ACTIVITY_HEADER_HEIGHT = 80


const debouncedSearchUsers = debounce(searchUsers, 500)

const ActivityHeader = (props: { callStats: CallStats }) => {
  const allUsers = useSelector((state: RootState) => Object.values(state.activityView.allUsers))
  const [search, setSearch] = useState<string>('')
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSearchUser(search))
    if (search) {
      dispatch(setSelectedUser(''))
      setIsSearching(true)
      debouncedSearchUsers(search).then((res) => {
        dispatch(addOrUpdateUsers(res.data))
        setIsSearching(false)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const allUserCalls = useSelector((state: RootState) => {
    let conferenceNames: string[] = []
    allUsers.forEach(user => {
      conferenceNames = conferenceNames.concat(state.callSlot.otherUserCalls[user.id])
    })

    let callStates = conferenceNames.map(conferenceName => state.call[conferenceName])
    return callStates
  })

  const clearSearch = () => {
    setSearch('')
    setAnchorEl(null)
    dispatch(setSelectedUser(''))
  }

  const selectUser = (userId: string) => {
    setAnchorEl(null)
    dispatch(setSelectedUser(userId))
    const operatorCard = document.getElementById(`operator-card-${userId}`)
    if (operatorCard) {
      operatorCard.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  const searchedUsers = useMemo(() => {
    const caseInsensitiveSearch = search.toLowerCase()
    return allUsers.filter(user =>
      user.name.toLowerCase().includes(caseInsensitiveSearch) ||
      user.email.toLowerCase().includes(caseInsensitiveSearch)
    )
  }, [search, allUsers])

  const activeCalls = allUserCalls.filter(call => call && ActiveCall.includes(call.state)).length
  const holdCalls = allUserCalls.filter(call => call && call.state === CallStateState.ONHOLD).length
  const onlineUsers = allUsers.filter(user => user.isOnline).length
  const activityDate = moment().format('MM/DD/YYYY')
  return (
    <Paper elevation={0} sx={{ width: '100%' }}>
      <Box
        sx={{ height: ACTIVITY_HEADER_HEIGHT, display: 'flex', alignItems: 'center', px: '20px', justifyContent: 'space-between' }}
      >
        <Box display="flex" alignItems="flex-end">
          <Typography style={{ fontWeight: 500, fontSize: '32px' }}>Activity</Typography>
          <Typography sx={{ pb: '5px', pl: 1 }} style={{ fontWeight: 500, fontSize: '16px' }}>{activityDate}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <CountCard title="Unanswered Calls" count={props.callStats.totalUnansweredCallsPerDay} color="#9E0000" />
          <CountCard title="Taken Calls" count={props.callStats.totalAnsweredCallsPerDay} color="#448C3D" />
          <CountCard title="Active Calls" count={activeCalls} color="#1033A5" />
          <CountCard title="On Hold" count={holdCalls} color="#D79F2B" />
          <CountCard title="Receptionist Online" count={onlineUsers} color="#5F27CB" />
          <HeaderCard>
            <Typography sx={{ fontWeight: 600, mr: 2 }}>Search</Typography>
            <TextField
              autoComplete="off"
              value={search}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                width: 275,
                mr: '-12px'
              }}
              InputProps={{
                sx: { pl: '12px', pr: '4px', py: '6px' },
                endAdornment: (
                  <InputAdornment position="end">
                    {!!search ?
                      <IconButton sx={{ p: 0 }} onClick={clearSearch}>
                        <Close sx={{ color: '#A5B5E3' }} />
                      </IconButton>
                      :                    
                      <Search sx={{ color: '#A5B5E3' }} />
                    }
                  </InputAdornment>
                )
              }}
              inputProps={{
                style: {
                  fontSize: '12px',
                  padding: 0,
                },
              }}
              placeholder="Search by receptionist"
              variant="outlined"
            />

            <Popper
              open={!!anchorEl && !!search}
              anchorEl={anchorEl}
              placement="bottom"
            >
              <Paper
                sx={{
                  mt: 2,
                  width: 275,
                  maxHeight: 500,
                  overflow: 'auto',
                }}
                elevation={2}
              >
                <List dense={true}>
                  {searchedUsers.map(user => (
                    <ListItemButton key={user.id} onClick={() => selectUser(user.id)}>
                      <ListItemAvatar>
                        <Avatar src={user.avatar} sx={{ backgroundColor: '#1033A5' }}>
                          {nameToInitial(user.name)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={user.name}
                        secondary={user.email}
                      />
                    </ListItemButton>
                  ))}
                  {!searchedUsers.length &&
                    <ListItem>
                      <ListItemText primary="No user found." />
                    </ListItem>
                  }
                  {isSearching &&
                    <ListItem>
                      <ListItemAvatar>
                        <Skeleton variant="circular" width={40} height={40} />
                      </ListItemAvatar>
                      <ListItemText>
                        <Skeleton variant="text" width={120} />
                        <Skeleton variant="text" />
                      </ListItemText>
                    </ListItem>
                  }
                </List>
              </Paper>
            </Popper>
          </HeaderCard>
        </Box>
      </Box>
    </Paper>
  );
};

export default ActivityHeader;
