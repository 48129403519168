import { useEffect } from "react";
import { CallState, CallStateState, CallType } from "utils/calls";
import { activeCallSelector } from "reducers/selector/callSelector";
import { useAppSelector } from "reducers/hooks";
import { useDispatch } from "react-redux";
import { setIsRinging } from "reducers/slices/UIReducer";

const shouldCallRing = (callState: CallState) => {
  return (
    callState &&
    !callState.isProcessing &&
    callState.state === CallStateState.UNANSWERED &&
    callState.type === CallType.INBOUND
  )
}

/**
 * Keep track of calls and play or stop ringtone when appropriate
 */
const useRingtonePlayer = () => {
  const activeCall = useAppSelector(activeCallSelector)
  const callSlots = useAppSelector(state => state.callSlot.callSlots)
  const calls = useAppSelector(state => state.call);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!activeCall) {
      const shouldRing = Object.values(callSlots).some(slot => {
        if (slot?.claimedBy) {
          const callState = calls[slot.claimedBy]
          return callState ? shouldCallRing(callState) : false
        }
        return false
      })

      if (shouldRing) {
        dispatch(setIsRinging(true))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCall, callSlots, calls])

  useEffect(() => {
    const filledSlots = Object.values(callSlots).filter(e => !!e.claimedBy)
    if (!filledSlots.length) {
      dispatch(setIsRinging(false))
    }
    const shouldStopRinging = filledSlots.every(slot => {
      if (slot.claimedBy) {
        const callState = calls[slot.claimedBy]
        return callState ? !shouldCallRing(callState) : true
      }
      return true
    })

    if (shouldStopRinging) {
      dispatch(setIsRinging(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSlots])

  return null
}

export default useRingtonePlayer