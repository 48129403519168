import { FC } from 'react'
import { Box, Typography } from '@mui/material';
import CallTimer from './CallTimer';
import { CallState } from 'utils/calls';

interface IProps {
  callState: CallState,
  children: any,
  onClick: (event: any) => void,
  notesDisabled?: boolean,
  viewOnly?: boolean,
}

const CallNoteDisplay: FC<IProps> = ({
  callState,
  notesDisabled,
  viewOnly,
  onClick,
  children,
}: IProps) => {

  return (
    <Box
      onClick={onClick}
      sx={{
        px: 1, py: '4px',
        height: 30,
        backgroundColor: notesDisabled ? '#C2CADA' : '#E4ECFC',
        borderRadius: '5px',
        cursor: viewOnly
          ? 'pointer'
          : notesDisabled
            ? 'default'
            : 'text',
        '&:hover': {
          backgroundColor: notesDisabled ? '#C2CADA' : '#D3DBEB',
        },
        position: 'relative',
      }}
    >
      <CallTimer color="#666666" callState={callState} />
      <Typography
        sx={{
          fontSize: '12px',
          lineHeight: '15px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          height: 15,
          color: '#666666',
        }}
      >
        {callState.notes}
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          right: 8,
          top: 7,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default CallNoteDisplay