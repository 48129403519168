import { FC, useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItemButton, Paper, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ConflictingContactStatus, Contact } from "utils/accounts";
import { resolveScheduledStatusConflict } from "api/contacts";
import ContactStatusAlert from "./contactItem/ContactStatusAlert";
import AnimatedButton from "components/buttons/AnimatedButton";

interface IProps {
  contactStatuses: ConflictingContactStatus[] | null,
  contact?: Contact,
  onClose: () => void,
  submitCallback: (status: string) => void,
}

const ContactStatusConflictDialog: FC<IProps> = ({ contactStatuses, contact, onClose, submitCallback }) => {
  const [resolution, setResolution] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setResolution('')
  }, [contactStatuses])

  const handleSubmit = () => {
    if (!resolution || !contact) return

    setIsLoading(true)
    const body = (contactStatuses || []).map(status => ({
      ...status,
      isPreserved: status.isCurrent
        ? resolution === 'new' || null
        : resolution === 'existing' || null
    }))

    resolveScheduledStatusConflict(contact.accountId, contact.id, body)
      .then(() => submitCallback(resolution === 'new' ? 'created' : 'preserved'))
      .finally(() => setIsLoading(false))
  }

  const newStatuses = (contactStatuses || []).filter(e => e.isCurrent)
  const existingStatuses = (contactStatuses || []).filter(e => !e.isCurrent)
  return (
    <Dialog open={!!contactStatuses} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        {'Call Status Conflict'}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#E80B0B',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 650 }}>
        <Typography>You have statuses that overlap. Please choose which status would you like to keep and click on Proceed button.</Typography>
        <Stack mt={2} direction="row" justifyContent="space-between" spacing={2}>
          <ListItemButton
            sx={{
              display: 'block',
              p: 0,
              opacity: resolution === 'existing' ? 0.5 : 1,
            }}
            onClick={() => setResolution('new')}
            selected={resolution === 'new'}
          >
            <Paper elevation={3} sx={{ p: 1, borderRadius: '5px', backgroundColor: 'transparent' }}>
              <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>New</Typography>
              {newStatuses.map(status => (
                <Box mb={1}>
                  <ContactStatusAlert key={status.id} contactStatus={status} />
                </Box>
              ))}
            </Paper>
          </ListItemButton>
          <ListItemButton
            sx={{
              display: 'block',
              p: 0,
              opacity: resolution === 'new' ? 0.5 : 1,
            }}
            onClick={() => setResolution('existing')}
            selected={resolution === 'existing'}
          >
            <Paper elevation={3} sx={{ p: 1, borderRadius: '5px', backgroundColor: 'transparent' }}>
              <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>Existing</Typography>
              {existingStatuses.map(status => (
                <Box mb={1}>
                  <ContactStatusAlert key={status.id} contactStatus={status} />
                </Box>
              ))}
            </Paper>
          </ListItemButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <AnimatedButton
          onClick={handleSubmit}
          label="Proceed"
          loading={isLoading}
          disabled={isLoading || !resolution}
          sx={{
            width: 150,
            borderRadius: '15px',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ContactStatusConflictDialog;
