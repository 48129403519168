import { useEffect, useState } from "react"
import { RingtoneContext } from "./ringtone-context"
import { DEFAULT_VOLUME, RINGTONE_VOLUME_STORAGE } from "pages/main/settings/RingtoneSetting";
import { useAppDispatch, useAppSelector } from "reducers/hooks";
import { setIsRinging } from "reducers/slices/UIReducer";
import { Howl } from "howler";
const ringing = require('../assets/ringing.mp3');

export const RingtoneProvider = (props: any) => {
  const user = useAppSelector(state => state.user.user);
  const isRinging = useAppSelector(state => state.ui.isRinging);
  const callEnabled = useAppSelector((state) => state.ui.isCallEnabled);
  const dispatch = useAppDispatch()
  const [ringtone] = useState(() => {
    const storedVolume = localStorage.getItem(RINGTONE_VOLUME_STORAGE)
    let volume
    if (storedVolume) {
      volume = parseFloat(storedVolume)
    } else {
      volume = DEFAULT_VOLUME
    }

    return new Howl({
      src: [ringing],
      loop: true,
      volume
    })
  })

  useEffect(() => {
    if (!user?.userId) {
      handleLogout()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId])


  useEffect(() => {
    if (!callEnabled) {
      ringtone.stop()
      return
    }

    if (isRinging) {
      if (ringtone) {
        ringtone.play()
      }
    } else {
      if (ringtone) {
        ringtone.stop()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRinging, callEnabled])

  const handleLogout = () => {
    dispatch(setIsRinging(false))
    // Redux states are cleared as well, check src/redux/store/index.ts
  }

  const setVolume = (value: number) => {
    ringtone.volume(value)
  }

  return (
    <RingtoneContext.Provider value={{ setVolume }}>
      {props.children}
    </RingtoneContext.Provider>
  )
}