import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Contact, getContactStatus, ScheduledContactStatus } from "utils/accounts";

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export interface SnackbarState {
  message: string,
  severity: SnackbarSeverity,
  isOpen: boolean,
}

interface ContactForEditStatus {
  contact: Contact,
  contactStatus: ScheduledContactStatus,
}

export interface UIState {
  snackbar: SnackbarState,
  twilioLoading: boolean,
  softTwilioLoading: boolean,
  noConnection: boolean,
  noMic: boolean,
  noAudioPerm: boolean,
  isSettingsOpen: boolean,
  isGlobalHistoryOpen: boolean,
  isRinging: boolean,
  isCallEnabled: boolean,
  edgeLocation: string,
  callerDelay: string,
  contactForEditStatus: ContactForEditStatus | null,
  contactForStatusList: Contact | null,
  loginError: string,
}

interface SnackbarPayload {
  message: string,
  severity: SnackbarSeverity,
}

const initialState: UIState = {
  snackbar: { message: '', severity: SnackbarSeverity.SUCCESS, isOpen: false },
  twilioLoading: true,
  softTwilioLoading: false,
  noConnection: false,
  noMic: false,
  noAudioPerm: false,
  isSettingsOpen: false,
  isGlobalHistoryOpen: false,
  isRinging: false,
  isCallEnabled: false,
  edgeLocation: 'ashburn',
  callerDelay: '0',
  contactForEditStatus: null,
  contactForStatusList: null,
  loginError: '',
};

export const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<SnackbarPayload>) => {
      const { message, severity } = action.payload
      state.snackbar = { message, severity, isOpen: true }
    },
    closeSnackbar: (state) => {
      state.snackbar.isOpen = false
    },
    setTwilioLoading: (state, action: PayloadAction<boolean>) => {
      state.twilioLoading = action.payload
    },
    setSoftTwilioLoading: (state, action: PayloadAction<boolean>) => {
      state.softTwilioLoading = action.payload
    },
    setNoConnection: (state, action: PayloadAction<boolean>) => {
      state.noConnection = action.payload
    },
    setNoAudioPerm: (state, action: PayloadAction<boolean>) => {
      state.noAudioPerm = action.payload
    },
    setNoMic: (state, action: PayloadAction<boolean>) => {
      state.noMic = action.payload
    },
    setIsSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isSettingsOpen = action.payload
    },
    setIsGlobalHistoryOpen: (state, action: PayloadAction<boolean>) => {
      state.isGlobalHistoryOpen = action.payload
    },
    setIsRinging: (state, action: PayloadAction<boolean>) => {
      state.isRinging = action.payload
    },
    setIsCallEnabled: (state, action: PayloadAction<boolean>) => {
      state.isCallEnabled = action.payload
    },
    setEdgeLocation: (state, action: PayloadAction<string>) => {
      state.edgeLocation = action.payload
    },
    setCallerDelay: (state, action: PayloadAction<string>) => {
      state.callerDelay = action.payload
    },
    setContactForEditStatus: (state, action: PayloadAction<Contact | null>) => {
      if (action.payload) {
        state.contactForEditStatus = {
          contact: action.payload,
          contactStatus: getContactStatus(action.payload, true)
        }
      } else {
        state.contactForEditStatus = action.payload
      }
    },
    setContactStatusForEditStatus: (state, action: PayloadAction<ContactForEditStatus | null>) => {
      state.contactForEditStatus = action.payload
    },
    setContactForStatusList: (state, action: PayloadAction<Contact | null>) => {
      state.contactForStatusList = action.payload
    },
    setLoginError: (state, action: PayloadAction<string>) => {
      state.loginError = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSnackbar, closeSnackbar, setTwilioLoading,
  setSoftTwilioLoading, setNoConnection, setNoAudioPerm,
  setIsSettingsOpen, setIsGlobalHistoryOpen, setIsRinging,
  setNoMic, setIsCallEnabled, setEdgeLocation, setCallerDelay,
  setContactForEditStatus, setContactStatusForEditStatus,
  setContactForStatusList, setLoginError,
} = UISlice.actions;

export default UISlice.reducer;
