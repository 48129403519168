import React, { useEffect, useState } from "react";
import * as yup from 'yup';
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, FormLabel, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import parseYupErrors from "utils/parseYupErrors";
import { setupPassword } from "api/auth";
import BackgroundLayout from "pages/BackgroundLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import AnimatedButton from "components/buttons/AnimatedButton";

const setupPasswordSchema = yup.object().shape({
  password: yup.string().required().label('Password')
    .test('valid-password', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character',
      (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value || '')
    ),
  confirmPassword: yup.string().required().label('Confirm Password')
    .test('same-password', 'Confirm Password and Password do not match',
      (value, testContext) => value === testContext.parent.password
    )
})

interface SetPasswordForm {
  password: string,
  confirmPassword: string
}

const defaultForm: SetPasswordForm = {
  password: '',
  confirmPassword: '',
}

const SetPassword = () => {
  const [searchParams] = useSearchParams()
  const [form, setForm] = useState<SetPasswordForm>({ ...defaultForm })
  const [errors, setErrors] = useState<SetPasswordForm>({ ...defaultForm })
  const [errorMsg, setErrorMsg] = useState<string>('') // From BE
  const [loading, setLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState({
    password: false, confirmPassword: false
  })
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const confirmationCode = searchParams.get('confirmationCode')

  useEffect(() => {
    if (!confirmationCode) {
      dispatch(setSnackbar({ message: 'Confirmation code is required, please open the link from your email!', severity: SnackbarSeverity.ERROR }))
      navigate('/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationCode])

  const handleChange = (name: keyof SetPasswordForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: event.target.value,
    })
    setErrors({ ...defaultForm })
  }

  const handleClickShowPassword = (name: 'password' | 'confirmPassword') => () => {
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name]
    })
  };

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setErrors({ ...defaultForm })
    setErrorMsg('')
    setLoading(true)
    setupPasswordSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        setupPassword({ password: form.password, confirmationCode: confirmationCode || '' })
          .then((res) => {
            dispatch(setSnackbar({ message: 'Password was successfully set!', severity: SnackbarSeverity.SUCCESS }))
            navigate('/login')
          })
          .catch((err) => {
            setErrorMsg(err.response.data)
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setLoading(false)
      })
  }

  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              px: 6,
              py: 8,
              width: 350,
            }}
          >
            <Typography style={{ fontWeight: 600, fontSize: '35px', lineHeight: '50px' }}>Enter New Password</Typography>
            <Typography align="center" sx={{ my: 1, width: 260, fontSize: '12px', lineHeight: '20px', fontWeight: 600, opacity: 0.6 }}>
              Your new password must be different from previous used passwords.
            </Typography>
            <TextField
              sx={{
                my: 3,
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
              }}
              placeholder="Password"
              fullWidth
              size="small"
              value={form.password}
              name="password"
              onChange={handleChange('password')}
              type={showPassword.password ? 'text' : 'password'}
              error={!!errors.password}
              helperText={errors.password || ''}
              InputProps={{
                sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('password')}
                      sx={{ color: '#A5B5E3' }}
                    >
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <TextField
              sx={{
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
              }}
              placeholder="Confirm Password"
              fullWidth
              size="small"
              value={form.confirmPassword}
              onChange={handleChange('confirmPassword')}
              type={showPassword.confirmPassword ? 'text' : 'password'}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword || ''}
              InputProps={{
                sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('confirmPassword')}
                      sx={{ color: '#A5B5E3' }}
                    >
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <FormLabel sx={{ my: 1 }} error>{errorMsg}&nbsp;</FormLabel>
            <AnimatedButton
              type="submit"
              label="Reset Password"
              disabled={loading}
              loading={loading}
            />
          </Box>
        </form>
      </Paper>
    </BackgroundLayout>
  );
};

export default SetPassword;
