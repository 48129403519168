import { FC } from 'react';
import { Button, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/store';

interface IProps {
  loadNewerData?: () => void,
  isLoadingNewData?: boolean,
}

export const NewHistoryBanner: FC<IProps> = ({ loadNewerData, isLoadingNewData }) => {
  const newCallHistoryCount = useSelector((state: RootState) => state.callHistory.newCallHistoryCount)

  if (!newCallHistoryCount) return null
  return (
    isLoadingNewData ?
      <Skeleton height={170} sx={{ mb: 1, transform: 'none' }} />
      :
      <Button
        sx={{
          width: '100%',
          backgroundColor: '#1033A5',
          '&:hover': { backgroundColor: '#0F2284' },
          color: '#FFF',
          mb: 1,
        }}
        variant="contained"
        disableElevation
        onClick={loadNewerData}
      >
        {'Load new call(s)'}
      </Button>

  );
}

export default NewHistoryBanner;