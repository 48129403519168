import React, { FC, useCallback, useState } from 'react'
import { Box, Dialog, IconButton, Paper, PaperProps, TextField, Typography } from '@mui/material';
import { BugReport, Close } from '@mui/icons-material';
import Draggable from 'react-draggable';
import { AppLog, LOG_STORAGE } from 'utils/utils';
import { reportBug } from 'api/other';
import { useAppDispatch } from 'reducers/hooks';
import { SnackbarSeverity, setSnackbar } from 'reducers/slices/UIReducer';

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface IProps {
  open: boolean,
  setOpen: (open: boolean) => void,
}

const ReportBug: FC<IProps> = ({ open, setOpen }) => {
  const [notes, setNotes] = useState('')
  const dispatch = useAppDispatch()

  const handleSave = () => {
    if (notes.trim()) {
      let appLogs: AppLog[] = []

      try {
        const savedLogs = localStorage.getItem(LOG_STORAGE)
        if (savedLogs) {
          appLogs = JSON.parse(savedLogs)
        }
      } catch (err) {
        console.log(err)
      }

      const stackTrace = JSON.stringify(appLogs.map(e => e.log))
      reportBug(notes.trim() + `//// App Version: ${process.env.REACT_APP_VERSION}`, stackTrace).then(() => {
        dispatch(setSnackbar({ message: 'Bug report submitted!', severity: SnackbarSeverity.SUCCESS }))
      })

      setNotes('')
      setOpen(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Save and submit on enter
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSave()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const noBubbleUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRefAutoFocus = useCallback((node: any) => {
    const element = node
    if (!element) return

    // Put the cursor at the end of input
    element.setSelectionRange(element.value.length, element.value.length)
    element.focus()
  }, []);

  return (
    <Dialog
      open={open}
      hideBackdrop
      PaperComponent={PaperComponent}
      sx={{ pointerEvents: 'none' }}
      PaperProps={{
        sx: { pointerEvents: 'auto' }
      }}
      disableEnforceFocus
    >
      <Box width={400} p="20px" onClick={noBubbleUp}>
        <Box display="flex" alignItems="center" sx={{ cursor: 'move' }} id="draggable-dialog-title">
          <BugReport sx={{ color: '#1033A5', mr: 1, mt: '2px' }} />
          <Typography style={{ fontWeight: 600, fontSize: 22 }}>Report a bug</Typography>
          <IconButton sx={{ marginLeft: 'auto', mr: -1 }} onClick={() => setOpen(false)}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <TextField
          sx={{
            bgcolor: '#E4ECFC',
            borderRadius: '5px',
            mt: 1,
          }}
          inputRef={handleRefAutoFocus}
          InputProps={{ sx: { p: 1 } }}
          inputProps={{ style: { fontSize: '14px' } }}
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={notes}
          onKeyDown={handleKeyDown}
          onChange={e => setNotes(e.target.value)}
        />
      </Box>
    </Dialog>
  )
}

export default ReportBug