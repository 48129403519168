import { useEffect, useState } from "react";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import BackgroundLayout from "pages/BackgroundLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { nameToInitial } from "utils/utils";
import AnimatedButton from "components/buttons/AnimatedButton";
import { getUserDetails } from "api/users";
import { addUserDetails } from "reducers/slices/userReducer";
import ChangePasswordDialog from "./ChangePasswordDialog";
import DeleteAvatarDialog from "./DeleteAvatarDialog";
import UploadAvatarButton from "./UploadAvatarButton";
import DuoEnrollDialog from "./DuoEnrollDialog";

const MyAccount = () => {
  const [deleteAvatarOpen, setDeleteAvatarOpen] = useState<boolean>(false)
  const [changePasswordOpen, setChangePasswordOpen] = useState<boolean>(false)
  const [enrollDuoOpen, setEnrollDuoOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<string>('')
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch()

  useEffect(() => {
    fetchAccount()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId])

  const fetchAccount = () => {
    if (user?.userId) {
      getUserDetails(user.userId).then(res => {
        dispatch(addUserDetails(res.data))
      })
    }  
  }

  return (
    <BackgroundLayout sx={{ height: 'calc(100% - 68px)' }}>
      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />

      <DeleteAvatarDialog
        setIsLoading={setIsLoading}
        fetchAccount={fetchAccount}
        open={deleteAvatarOpen}
        onClose={() => setDeleteAvatarOpen(false)}
      />

      <DuoEnrollDialog
        open={enrollDuoOpen}
        onClose={() => setEnrollDuoOpen(false)}
      />

      <Paper elevation={5}>
        <Box p={6} pb={8} width={380} display="flex" flexDirection="column" alignItems="center">
          <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>My Account</Typography>
          <Avatar
            src={user?.details?.avatar}
            sx={{
              mt: 4,
              width: 128,
              height: 128,
              bgcolor: '#1033A5',
              fontSize: '72px',
            }}
          >
            {nameToInitial(user?.details?.name || '')}
          </Avatar>
          <Box mt={2} width="100%" display="flex" alignItems="center" justifyContent="center">
            <UploadAvatarButton
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              fetchAccount={fetchAccount}
            />
            <Button
              sx={{ width: 85 }}
              onClick={() => setDeleteAvatarOpen(true)}
              disabled={!!isLoading || !user?.details?.avatar}
            >
              Delete
            </Button>
          </Box>
          <Typography sx={{ mt: 3, fontSize: '28px', fontWeight: 600 }}>{user?.details?.name}</Typography>
          <Typography sx={{ mt: 1, fontSize: '16px', fontWeight: 600, color: '#1033A5' }}>{user?.details?.email}</Typography>
          <AnimatedButton
            label="Change Password"
            onClick={() => setChangePasswordOpen(true)}
            sx={{
              mt: 4,
              width: 350,
              height: 40,
              fontSize: '16px',
            }}
            disabled={!!isLoading}
          />
          {!user?.duoUserId &&
            <AnimatedButton
              label="Activate 2FA"
              onClick={() => setEnrollDuoOpen(true)}
              sx={{
                mt: 2,
                width: 350,
                height: 40,
                fontSize: '16px',
              }}
              disabled={!!isLoading}
            />
          }
        </Box>
      </Paper>
    </BackgroundLayout>
  );
};

export default MyAccount;
