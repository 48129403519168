import React, { FC } from "react";
import { Box } from "@mui/material";

interface IProps {
  sx?: any
  children?: any,
}

const BackgroundLayout: FC<IProps> = ({ sx, children }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        position: 'relative',
        ...sx,
      }}
    >
      <img
        alt=""
        src="/assets/svg/background.svg"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 0
        }}
      />
      <Box zIndex={1}>
        {children}
      </Box>
    </Box>
  );
};

export default BackgroundLayout;
