import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import ContactList from "./ContactList";
import { Refresh } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { getAccountContacts } from "api/accounts";
import { useDispatch } from "react-redux";
import { setActiveAccount } from "reducers/slices/accountPanelReducer";
import { ACCOUNT_PANEL_STATUS, Contact } from "utils/accounts";
import { spin } from "utils/animations";
import debounce from "awesome-debounce-promise";
import { setCache } from "reducers/slices/cacheReducer";
import ContactStatusDialog from "./ContactStatusDialog";
import ContactStatusListDialog from "./ContactStatusListDialog";

const debouncedGetContacts = debounce(getAccountContacts, 500)

const Contacts = () => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const accountStatus = useSelector((state: RootState) => state.accountPanel.status)
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (account.id) {
      refreshContacts(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  const refreshContacts = (isRefresh = true) => {
    if (account?.id) {
      if (isRefresh) {
        setIsRefreshing(true)
      }
      setIsLoading(true)
      debouncedGetContacts(account.id).then((res) => {
        const accountWithContacts = {
          ...account,
          contacts: res.data
            .map((contact: Contact) => ({ ...contact, accountId: account.id }))
        }

        dispatch(setActiveAccount(accountWithContacts))

        if (!account.isFromGlobalSearch) {
          dispatch(setCache({ key: account.id, value: accountWithContacts }))
        }
      }).finally(() => {
        setIsLoading(false)
        setIsRefreshing(false)
      })
    }
  }

  return (
    <Box width="100%" height="100%">
      <ContactStatusDialog />
      <ContactStatusListDialog />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ 
          borderBottom: '1px solid #E4ECFC',
        }}
      >
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            p: 2,
            pr: 0,
          }}
        >
          Contacts
        </Typography>
        {account?.id &&
          <Tooltip title="Refresh contact list details">
            <span>
              <IconButton
                onClick={() => refreshContacts()}
                sx={{
                  p: '4px',
                  color: '#1033A5',
                  animation: isRefreshing ? `${spin} 1s linear 0s infinite` : '',
                }}
              >
                <Refresh />
              </IconButton>
            </span>
          </Tooltip>
        }
      </Stack>
      <Box width="100%" height="calc(100% - 69px)">
        <ContactList
          isLoading={isLoading || accountStatus === ACCOUNT_PANEL_STATUS.LOADING}
        />
      </Box>
    </Box>
  );
};

export default Contacts;
