import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { claimSlot } from "reducers/slices/callSlotReducer";
import { useAppSelector } from "reducers/hooks";

/**
 * Calls assigner keeps track of all slots and calls behind the scene
 * when any slot opened up, it will assign a queued call into the slot
 */
const useCallsAssigner = () => {
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const callSlots = useAppSelector(state => state.callSlot.callSlots)
  const unassignedCalls = useAppSelector(state => state.callSlot.unassignedCalls);
  const priorityUnassignedCalls = useAppSelector(state => state.callSlot.priorityUnassignedCalls);
  const outboundUnassignedCall = useAppSelector(state => state.callSlot.outboundUnassignedCall);
  const dispatch = useDispatch()

  useEffect(() => {
    if (!callEnabled) return

    const now = Date.now()
    const openSlots = Object.values(callSlots).filter(e =>
      !e.claimedBy &&
      !e.disabled &&
      now > (e.cooldownPeriod || 0)
    )
    if (!openSlots.length) return
    if (!unassignedCalls.length && !priorityUnassignedCalls.length && !outboundUnassignedCall) return

    // Prioritize outbound
    const oldestUnassignedCall = outboundUnassignedCall || priorityUnassignedCalls[0] || unassignedCalls[0]
    const isOutbound = !!outboundUnassignedCall
    dispatch(claimSlot({ conferenceName: oldestUnassignedCall, isOutbound }))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSlots, unassignedCalls, priorityUnassignedCalls, callEnabled])

  return null
}

export default useCallsAssigner