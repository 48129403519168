import { useEffect, useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import {
  Check, Close, Merge, Pause,
  StopCircle, PhoneForwarded, PhonePaused, Person} from '@mui/icons-material';
import { CallStateState, CallType, CanAnswer, CanHold, CanStopTransfer, WarmTransferredTo } from 'utils/calls';
import useCallFunctions from 'hooks/useCallFunctions';
import CallDialer from './CallDialer';
import CallFlagger from './CallFlagger';
import { useDispatch } from 'react-redux';
import { setCallHistoryFilters } from 'reducers/slices/callHistoryReducer';
import SearchBar from './SearchBar';
import CallRequeue from './CallRequeue';
import UserMenu from '../settings/UserMenu';
import { useAppSelector } from 'reducers/hooks';
import { activeCallSelector, selectedCallSelector } from 'reducers/selector/callSelector';
import { AppThunkDispatch } from 'reducers/types';
import { getActiveAccount } from 'reducers/thunks/accountThunk';
import { setOpenAccountId } from 'reducers/slices/accountPanelReducer';

const Divider = () => {
  return (
    <Box
      sx={{
        height: 28,
        width: 2,
        bgcolor: '#B9C8E7',
        mx: 1,
      }}
    />
  )
}

const HANGUP_TIMEOUT = 3 * 1000

export const CallToolbar = () => {
  const selectedCall = useAppSelector(selectedCallSelector)
  const activeCall = useAppSelector(activeCallSelector)
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const {
    holdCall,
    joinCall,
    leaveCall,
    toggleWarmTransfer,
    stopTransfer,
    coldTransfer,
  } = useCallFunctions()
  const [phoneNumTo, setPhoneNumTo] = useState('');
  const [hangUpDisabled, setHangUpDisabled] = useState<boolean>(false)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (selectedCall?.joinedAt) {
      if (Date.now() - selectedCall.joinedAt < HANGUP_TIMEOUT) {
        const remainingTime = (selectedCall.joinedAt + HANGUP_TIMEOUT) - Date.now()
        setHangUpDisabled(true)
        timeout = setTimeout(() => {
          setHangUpDisabled(false)
        }, remainingTime)
      }
    } else {
      setHangUpDisabled(false)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [selectedCall?.joinedAt])

  const withSelectCallSlot = () => {
    const account = thunkDispatch(getActiveAccount())
    if (account?.isFromGlobalSearch) {
      dispatch(setOpenAccountId('call'))
    }
  }

  const onJoinCall = () => {
    if (!selectedCall) return
    withSelectCallSlot()
    joinCall(selectedCall.conferenceName)
  }

  const onHoldCall = () => {
    if (!selectedCall) return
    holdCall(selectedCall.conferenceName)
  }

  const hangupCall = () => {
    if (!selectedCall) return

    if (selectedCall.joinedAt && Date.now() - selectedCall.joinedAt < HANGUP_TIMEOUT) return

    if (selectedCall.callerLeftWarmTransfer) {
      return onStopTransfer()
    }

    leaveCall(selectedCall.conferenceName, selectedCall.type === CallType.UNBOUND)
  }

  const onColdTransfer = () => {
    if (!activeCall) return
    coldTransfer(activeCall?.conferenceName, activeCall?.warmTransferredToNum || phoneNumTo)
  }

  const onStopTransfer = () => {
    if (!selectedCall) return
    stopTransfer(selectedCall.conferenceName)
  }

  const onToggleWarmTransfer = (target: WarmTransferredTo) => {
    if (!selectedCall) return
    toggleWarmTransfer(selectedCall.conferenceName, target)
  }

  const openCallerHistory = () => {
    if (!selectedCall) return
    if (selectedCall.type === CallType.OUTBOUND) {
      dispatch(setCallHistoryFilters({ numberCallerCalled: selectedCall.callerNumber }))
    } else {
      dispatch(setCallHistoryFilters({ callerNumber: selectedCall.callerNumber }))
    }
  }

  const isProcessing = !callEnabled || selectedCall?.isProcessing || selectedCall?.state === CallStateState.TRANSFERRING

  const HangUpButton = (
    <Button
      sx={{ width: 109, minWidth: 109 }}
      startIcon={<Close sx={{ color: '#9E0000' }} />}
      onClick={hangupCall}
      disabled={
        hangUpDisabled ||
        !selectedCall ||
        (
          selectedCall.state === CallStateState.UNANSWERED && (
            selectedCall.type !== CallType.OUTBOUND ||
            (selectedCall.type === CallType.OUTBOUND && selectedCall.requeued)
          )
        ) ||
        (!!selectedCall.warmTransferredTo &&
          !selectedCall.callerLeftWarmTransfer
        ) ||
        selectedCall.conferenceName !== activeCall?.conferenceName ||
        isProcessing
      }
    >
      Hangup
    </Button>
  )

  return (
    <Box display="flex" alignItems="center">
      <Button
        sx={{ mr: '4px', width: 100, minWidth: 100 }}
        startIcon={<Check sx={{ color: '#448C3D' }} />}
        onClick={onJoinCall}
        disabled={
          !selectedCall ||
          !(CanAnswer.includes(selectedCall.state)) ||
          (selectedCall.state === CallStateState.UNANSWERED && selectedCall.type === CallType.OUTBOUND && !selectedCall.requeued) ||
          selectedCall?.isTest ||
          isProcessing ||
          selectedCall?.type === CallType.UNBOUND
        }
      >
        {!selectedCall || selectedCall?.state === CallStateState.UNANSWERED ? 'Answer' : 'Speak'}
      </Button>
      <Button
        sx={{ mr: '4px', width: 88, minWidth: 88 }}
        startIcon={<Pause sx={{ color: '#EDB63F' }} />}
        onClick={onHoldCall}
        disabled={
          !selectedCall ||
          !(CanHold.includes(selectedCall.state))||
          isProcessing ||
          selectedCall?.type === CallType.UNBOUND
        }
      >
        Hold
      </Button>
      <CallRequeue />
      {hangUpDisabled ?
        <Tooltip
          title="You just answered, please wait at least 3 seconds to hangup."
        >
          <span>
            {HangUpButton}
          </span>
        </Tooltip>
        :
        HangUpButton
      }      
      <Divider />
      
      <Button
        sx={{ mr: '4px', width: 112, minWidth: 112 }}
        startIcon={<Merge sx={{ color: '#72BA6B' }} />}
        onClick={onColdTransfer}
        disabled={
          !callEnabled ||
          !activeCall ||
          activeCall?.state === CallStateState.UNANSWERED ||
          (!phoneNumTo && !activeCall?.warmTransferredToNum) ||
          (!activeCall.warmTransferredToNum && phoneNumTo === activeCall.callerNumber) ||
          activeCall?.isProcessing ||
          activeCall?.state === CallStateState.TRANSFERRING ||
          !activeCall.transferEnabled ||
          activeCall?.type === CallType.UNBOUND ||
          activeCall.callerLeftWarmTransfer
        }
      >
        Connect
      </Button>
      <Button
        sx={{ mr: '4px', width: 120, minWidth: 120 }}
        startIcon={<StopCircle sx={{ color: '#9E0000' }} />}
        onClick={onStopTransfer}
        disabled={
          !callEnabled ||
          !selectedCall ||
          !CanStopTransfer.includes(selectedCall?.state) ||
          selectedCall?.isProcessing ||
          selectedCall?.type === CallType.UNBOUND
        }
      >
        Stop Trns
      </Button>
      <Button
        sx={{ width: 150, minWidth: 150 }}
        startIcon={selectedCall?.warmTransferredTo ? <PhoneForwarded sx={{ color: '#5F27CB' }} /> : <PhonePaused sx={{ color: '#5F27CB' }} />}
        onClick={() => onToggleWarmTransfer(selectedCall?.state === CallStateState.WITH_CLIENT ? WarmTransferredTo.CALLER : WarmTransferredTo.CLIENT)}
        disabled={
          !selectedCall ||
          !selectedCall.warmTransferredTo||
          isProcessing ||
          selectedCall?.type === CallType.UNBOUND ||
          (selectedCall.callerLeftWarmTransfer && selectedCall.state === CallStateState.WITH_CLIENT)
        }
      >
        {selectedCall?.warmTransferredTo ?
          selectedCall?.state === CallStateState.WITH_CLIENT ? 'Talk w/ Caller' : 'Talk w/ Client'
          :
          'Talk w/ Caller'
        }
      </Button>

      <Divider />

      <CallFlagger />
      <Button
        sx={{ width: 95, minWidth: 95 }}
        startIcon={<Person sx={{ color: '#2F6FCF' }} />}
        onClick={openCallerHistory}
        disabled={!selectedCall || selectedCall?.type === CallType.UNBOUND}
      >
        {selectedCall?.type === CallType.OUTBOUND ? 'Callee' : 'Caller'}
      </Button>

      <Divider />

      <CallDialer phoneNumTo={phoneNumTo} setPhoneNumTo={setPhoneNumTo} />

      <Divider />

      <Box flexGrow={1} mr={1}>
        <SearchBar />
      </Box>
      <UserMenu isReceptionist />
    </Box>
  );
}

export default CallToolbar;