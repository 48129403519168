import { FC, useState } from 'react';
import { Box, Divider, IconButton, Menu, MenuItem, MenuList, Slider, Tooltip, Typography } from '@mui/material';
import { Forward10, PauseCircle, PlayCircle, Replay10, ReplayCircleFilled, Settings } from '@mui/icons-material';
import { secondsToHoursMinuteSecond } from 'utils/utils';
import { AudioPlayer } from 'hooks/useAudioPlayer';

interface IProps {
  audioPlayer: AudioPlayer,
}

export const RecordingPlayer: FC<IProps> = (props) => {
  const { audioPlayer } = props
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const changeSpeed = (value: number) => {
    audioPlayer.changeSpeed(value)
    setAnchorEl(null)
  }
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Tooltip title="Rewind">
        <span>
          <IconButton sx={{ p: '2px', ml: '-4px' }} onClick={() => audioPlayer.rewind(10)}>
            <Replay10 sx={{ color: '#1033A5' }} />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title={
        audioPlayer.isEnded ? 'Replay' :
          audioPlayer.isPlaying ? 'Pause' : 'Play'
      }>
        <span>
          <IconButton sx={{ p: '2px', mx: 1 }} onClick={audioPlayer.isPlaying ? audioPlayer.pause : audioPlayer.play}>
            {audioPlayer.isEnded && <ReplayCircleFilled sx={{ color: '#1033A5' }} />}
            {!audioPlayer.isEnded && (
              audioPlayer.isPlaying ?
                <PauseCircle sx={{ color: '#1033A5' }} />
                :
                <PlayCircle sx={{ color: '#1033A5' }} />
            )}
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title="Forward">
        <span>
          <IconButton sx={{ p: '2px' }} onClick={() => audioPlayer.forward(10)}>
            <Forward10 sx={{ color: '#1033A5' }} />
          </IconButton>
        </span>
      </Tooltip>

      <Typography sx={{ ml: 3, fontSize: '14px' }}>{secondsToHoursMinuteSecond(audioPlayer.currentTime)}</Typography>
      <Slider
        sx={{ mx: 3, maxWidth: 200, width: 'calc(100% - 24px - 44px - 28px - 80px - 48px - 80px - 28px)' }}
        max={audioPlayer.totalDuration}
        value={audioPlayer.currentTime}
        step={0.1}
        onChange={(e: Event, newValue: number | number[]) => audioPlayer.seek(newValue as number)}
      />
      <Typography sx={{ mr: 3, fontSize: '14px', width: 56 }}>
        {audioPlayer.totalDuration ? secondsToHoursMinuteSecond(audioPlayer.totalDuration) : ''}
      </Typography>


      <Tooltip title="Playback Speed">
        <span>
          <IconButton sx={{ p: '2px' }} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <Settings sx={{ color: '#1033A5' }} />
          </IconButton>
        </span>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuList dense disablePadding>
          <MenuItem disabled sx={{ opacity: '1!important' }}>Playback speed</MenuItem>
          <Divider />
          <MenuItem selected={audioPlayer.playbackRate === 0.5} onClick={() => changeSpeed(0.5)}>x0.5</MenuItem>
          <MenuItem selected={audioPlayer.playbackRate === 1} onClick={() => changeSpeed(1)}>Normal</MenuItem>
          <MenuItem selected={audioPlayer.playbackRate === 2} onClick={() => changeSpeed(2)}>x2</MenuItem>
          <MenuItem selected={audioPlayer.playbackRate === 4} onClick={() => changeSpeed(4)}>x4</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}

export default RecordingPlayer;