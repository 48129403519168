import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import BackgroundLayout from "pages/BackgroundLayout";
import { useNavigate } from "react-router-dom";

const EmailSent = () => {
  const navigate = useNavigate()
  
  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 6,
            pt: 6,
            pb: 4,
            width: 350,
          }}
        >
          <Typography style={{ fontWeight: 500, fontSize: '36px', lineHeight: '50px' }}>Email Sent</Typography>
          <Typography align="center" sx={{ mt: 1, width: 260, fontSize: '12px', lineHeight: '20px' }}>
            The email was successfully sent! Please check your inbox.
          </Typography>
          <Typography
            sx={{
              mt: 3,
              fontWeight: 600,
              fontSize: '14px',
              color: '#1033A5',
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            onClick={() => navigate('/login')}
          >
            Back to Login
          </Typography>
        </Box>
      </Paper>
    </BackgroundLayout>
  );
};

export default EmailSent;
