import { DragIndicator } from '@mui/icons-material';
import { Box, Dialog, Paper, PaperProps, Typography } from '@mui/material';
import Draggable from 'react-draggable';

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DisconnectAlert = () => {

  return (
    <Dialog
      open={true}
      hideBackdrop
      PaperComponent={PaperComponent}
      sx={{ pointerEvents: 'none' }}
      PaperProps={{
        sx: { pointerEvents: 'auto', backgroundColor: '#FF9999', color: '#213299' }
      }}
      disableEnforceFocus
    >
      <Box width={450} p="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ cursor: 'move' }} id="draggable-dialog-title">
          <Typography style={{ fontWeight: 600, fontSize: 22 }}>Disconnected from Console Controls</Typography>
          <DragIndicator />
        </Box>
        <Typography sx={{ fontWeight: 600, fontSize: '14px', mt: 2 }}>
          Controls are temporarily lost. You might still be able to speak with the caller. Please wait for the system to reconnect.
        </Typography>
      </Box>
    </Dialog>
  )
}

export default DisconnectAlert