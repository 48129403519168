import { useState, useEffect, useMemo } from 'react';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AdditionalCallSlot from './AdditionalCallSlot';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers/store';
import moment from 'moment-timezone';
import { ACCOUNT_PANEL_STATUS, formatTimezone } from 'utils/accounts';
import Warnings from '../Warnings';

export const GreetingLine = () => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const status = useSelector((state: RootState) => state.accountPanel.status)
  // To trigger useMemo updates
  const [currentTime, setCurrentTime] = useState<Date>(new Date())

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const updateOnNextMinute = () => {
      const now = Date.now()
      const oneMinuteMs = 60 * 1000
      const nextMinuteMs = oneMinuteMs - (now % oneMinuteMs)

      timeout = setTimeout(() => {
        setCurrentTime(new Date())
        updateOnNextMinute()
      }, nextMinuteMs)
    }

    updateOnNextMinute()

    return () => clearTimeout(timeout)
  }, [])

  const localAccountTime = useMemo(() => {
    if (account.timeZone) {
      return moment().tz(formatTimezone(account.timeZone), false).format('LT')
    } else {
      return moment().format('LT')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.timeZone, currentTime])

  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: '#B4C6E7',
        borderRadius: '5px',
        p: '12px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <QuestionAnswerOutlinedIcon sx={{ color: '#213299', mr: '12px' }} />
      {status === ACCOUNT_PANEL_STATUS.LOADING &&
        <Skeleton width={900} height={23} variant="rectangular" />
      }
      {status !== ACCOUNT_PANEL_STATUS.LOADING &&
        <Typography sx={{ fontSize: '15px', fontWeight: 600, color: '#213299' }}>
          {`(${localAccountTime}) ${account?.greeting || ''}`}
        </Typography>
      }

      <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        <Warnings color="#FF3737" />
        <AdditionalCallSlot
          name="outbound-call"
          userId="receptionist"
        />
      </Box>
    </Paper>
  );
}

export default GreetingLine;