import { FormContactsRequest, FormSubmission } from 'abbyForms/utils'
import agent from './agent'

export function getForm(companyId: string, formId: string) {
  return agent.get(`/companies/${companyId}/forms/${formId}`)
}

export function getFormContacts(companyId: string, formId: string, payload: FormContactsRequest) {
  return agent.post(`/companies/${companyId}/formContacts/${formId}`, payload)
}

export function submitForm(companyId: string, payload: FormSubmission) {
  return agent.post(`/companies/${companyId}/forms`, payload)
}
