import React from "react";
import {
  Box, MenuItem, TextField, Typography
} from "@mui/material";
import { useAppSelector } from "reducers/hooks";
import { useDispatch } from "react-redux";
import { setEdgeLocation } from "reducers/slices/UIReducer";
import { activeCallSelector } from "reducers/selector/callSelector";

const EDGE_LOCATIONS = [
  { label: 'US East Coast (Virginia)', value: 'ashburn' },
  { label: 'US West Coast (Oregon)', value: 'umatilla' },
]

const TwilioEdgeLocationSetting = () => {
  const edgeLocation = useAppSelector(state => state.ui.edgeLocation);
  const activeCall = useAppSelector(activeCallSelector)
  const dispatch = useDispatch()


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setEdgeLocation(event.target.value))
  }

  return (
    <Box>
      <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Edge Location</Typography>
      <TextField
        sx={{ mt: 1 }}
        value={edgeLocation}
        onChange={handleChange}
        SelectProps={{
          sx: {
            backgroundColor: '#E4ECFC',
            borderColor: '#CBD8F1',
          },
          MenuProps: { sx: { width: 350 } },
        }}
        size="small"
        select
        fullWidth
        disabled={!!activeCall}
      >
        {EDGE_LOCATIONS.map(edgeLocation => (
          <MenuItem
            key={edgeLocation.value}
            value={edgeLocation.value}
          >
            <Typography>
              {edgeLocation.label}
            </Typography>
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default TwilioEdgeLocationSetting;
