import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, Skeleton, Stack, Typography } from "@mui/material";
import { CallRecord } from 'utils/calls';
import { CallRecordsParams, getCallRecords } from "api/callrecords";
import { History, Star } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { CallHistoryFilters, setCallHistoryFilters } from "reducers/slices/callHistoryReducer";
import CallerHistoryCard from "./CallerHistoryCard";
import { Account } from "utils/accounts";

const CallerHistoryCardMemo = React.memo(CallerHistoryCard, (prevProps, nextProps) => {
  return (
    prevProps.callRecord === nextProps.callRecord
  )
})

interface IProps {
  lastCallRecord: CallRecord | null, // Always incoming call
  account: Pick<Account, 'id' | 'name'>,
  isLoading: boolean,
  isOutbound: boolean,
  useAccountInfo?: boolean,
  noCallerNumber?: boolean,
}

const MAX_HISTORY_ITEMS = 100
const CallerHistory: FC<IProps> = ({ lastCallRecord, account, isLoading, isOutbound, useAccountInfo, noCallerNumber }) => {
  const dispatch = useDispatch()
  const [callRecords, setCallRecords] = useState<CallRecord[]>([])
  const [showOnlyFormFilled, setShowOnlyFormFilled] = useState<boolean>(true)

  const [rowsPerPage] = useState<number>(3)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(false)
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  useEffect(() => {
    if (isLoading) {
      setIsLoadingInternal(true)
    }
  }, [isLoading])

  useEffect(() => {
    setIsLoadingInternal(true)
    setPage(1)
    if (!lastCallRecord) {
      setCallRecords([])
      setHasMore(false)
      setIsLoadingInternal(isLoading)
      return
    }

    setHasMore(true)
    setIsLoadingInternal(true)

    const options: CallRecordsParams = {
      limit: rowsPerPage,
      page: 1,
      callerNumber: lastCallRecord.callerNumber || '',
      accountIds: lastCallRecord.account?.id ? [lastCallRecord.account.id] : undefined,
    }

    if (useAccountInfo) {
      delete options.callerNumber
      options.accountIds = [account.id]
    }

    if (showOnlyFormFilled) {
      options.callTypes = ['FormFilled']
    }

    getCallRecords(options).then((res) => {
      setCallRecords(res.data)
    }).finally(() => setIsLoadingInternal(false))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCallRecord, useAccountInfo, showOnlyFormFilled])

  const loadNextPage = () => {
    const nextPage = page + 1
    setPage(nextPage)
    setIsLoadingMore(true)

    const options: CallRecordsParams = {
      limit: rowsPerPage,
      page: nextPage,
      callerNumber: lastCallRecord?.callerNumber || '',
      accountIds: lastCallRecord?.account?.id ? [lastCallRecord?.account.id] : undefined,
    }

    if (useAccountInfo) {
      delete options.callerNumber
      options.accountIds = [account.id]
    }

    getCallRecords(options).then(res => {
      const newCallRecords = callRecords.concat(res.data)
      setCallRecords(newCallRecords)
      if (!res.data.length || newCallRecords.length >= MAX_HISTORY_ITEMS) {
        setHasMore(false)
      }
    }).finally(() => setIsLoadingMore(false))
  }

  const latestNote = useMemo(() => {
    if (noCallerNumber) return undefined

    return lastCallRecord?.clientKnownCaller?.notes
  }, [lastCallRecord, noCallerNumber])

  const callerNumber = useMemo(() => {
    if (noCallerNumber) return ''

    return lastCallRecord?.callerNumber || 'No history found'
  }, [lastCallRecord, noCallerNumber])

  const openCallHistory = () => {
    const filters: CallHistoryFilters = {
      account: {
        label: account.name,
        value: account.id,
      },
    }

    if (useAccountInfo || noCallerNumber) {
      dispatch(setCallHistoryFilters(filters))

      return
    }

    if (isOutbound) {
      filters.numberCallerCalled = lastCallRecord?.callerNumber || ''
    } else {
      filters.callerNumber = lastCallRecord?.callerNumber || ''
    }

    dispatch(setCallHistoryFilters(filters))
  }

  const isLoadingInfo = isLoading || isLoadingInternal
  return (
    <Box>
      {isLoadingInfo && <Skeleton width="100%" height={27} variant="rectangular" />}
      {(!isLoadingInfo && !useAccountInfo) &&
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          {isOutbound ? 'Callee' : 'Caller'}: {callerNumber}
        </Typography>
      }
      {(!isLoadingInfo && useAccountInfo) &&
        <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
          Account: {account.name}
        </Typography>
      }
      {!useAccountInfo &&
        <>
          {isLoadingInfo && <Skeleton sx={{ mt: 1, borderRadius: '5px' }} width="100%" height={54} variant="rectangular" />}
          {(!isLoadingInfo && latestNote) &&
            <Stack
              sx={{
                mt: 1, p: 1,
                border: '1px solid #CBD8F1',
                borderRadius: '5px',
                backgroundColor: '#E4ECFC',
                minHeight: 54 - 16,
              }}
              spacing={1} direction="row" alignItems="center"
            >
              <Star sx={{ fontSize: '32px', color: '#EDB63F' }} />
              <Typography style={{ fontSize: '12px' }}>{latestNote}</Typography>
            </Stack>
          }
        </>
      }

      <Button
        startIcon={<History sx={{ color: '#1033A5' }} />}
        onClick={openCallHistory}
        disableElevation
        size="small"
        sx={{
          mt: 1,
          width: '100%',
          backgroundColor: '#F5F8FD',
          color: '#1033A5'
        }}
        disabled={isLoadingInfo || !lastCallRecord}
      >
        {isOutbound ? 'Callee' : 'Caller'} History
      </Button>

      <Divider sx={{ my: 1 }} />

      <FormControlLabel
        sx={{ mb: '4px' }}
        label="Show calls with filled form(s) only"
        control={
          <Checkbox
            sx={{ mr: '4px' }}
            checked={showOnlyFormFilled}
            onChange={(e) => setShowOnlyFormFilled(e.target.checked)}
          />
        }
      />
      <Box display={isLoadingInfo ? 'none' : 'block'}>
        {callRecords.map(callRecord => (
          <CallerHistoryCardMemo key={callRecord.id} callRecord={callRecord} />
        ))}
      </Box>

      {(isLoadingInfo || isLoadingMore) &&
        [...new Array(rowsPerPage)].map((e, i) => (
          <Skeleton key={i} sx={{ mb: '4px', borderRadius: '5px' }} width="100%" height={67} variant="rectangular" />
        ))
      }
      {(isLoadingInfo && !callRecords.length) && <Skeleton sx={{ borderRadius: '5px' }} width="100%" height={32} variant="rectangular" />}
      {(hasMore && !!callRecords.length && false) && // Always show 3 for now
        <Button
          startIcon={<History sx={{ color: '#1033A5' }} />}
          size="small"
          onClick={loadNextPage}
          disableElevation
          fullWidth
          sx={{
            backgroundColor: '#F5F8FD',
            color: '#1033A5'
          }}
          disabled={isLoadingInfo || isLoadingMore}
        >
          See More
        </Button>
      }
    </Box>
  );
};

export default CallerHistory;
