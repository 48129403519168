import { Box, FormLabel, Grid, Stack, Typography } from "@mui/material"
import { FC, useMemo } from "react";
import { FieldType, FormContact, FormQuestion, InstructionPosition } from "../utils";
import QuestionInstructions from "./QuestionInstructions";
import TextfieldInput from "./TextfieldInput";
import PicklistInput from "./PicklistInput";
import AutocompletePicklistInput from "./AutocompletePicklistInput";
import AutocompletePicklistMultipleInput from "./AutocompletePicklistMultipleInput";
import MarkfieldInput from "./MarkfieldInput";
import TextareaInput from "./TextareaInput";
import CheckGreen from "assets/CheckGreen";
import CheckBlack from "assets/CheckBlack";

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  askAIForms: boolean,
  error?: string,
  states?: string[],
  contacts?: FormContact[],
}

const Question: FC<IProps> = (props) => {
  const {
    question,
    handleChange,
    value,
    error,
    askAIForms,
    states,
    contacts,
  } = props;

  const referToContact = useMemo(() => {
    if (!question.dictionaryId) return ''
    if (!question.clientDictionaryRecords) return ''
    if (!value) return ''
    if (!contacts) return ''

    const selectedAnswer = question.clientDictionaryRecords.find(e => e.id === value)
    if (!selectedAnswer) return ''

    const contact = contacts.find(e => e.id === selectedAnswer.contactId)
    if (!contact) return ''

    return contact.name
  }, [question.dictionaryId, question.clientDictionaryRecords, value, contacts])

  const dictValue = useMemo(() => {
    if (!question.dictionaryId) return ''
    if (!question.clientDictionaryRecords) return ''
    if (!value) return ''

    const selectedAnswer = question.clientDictionaryRecords.find(e => e.id === value)
    if (!selectedAnswer?.value) return ''

    return `${selectedAnswer.name} - ${selectedAnswer.value}`
  }, [question.dictionaryId, question.clientDictionaryRecords, value])

  const useAIForm = askAIForms &&
    [FieldType.Textbox, FieldType.Textarea].includes(question.fieldType) &&
    !question.isSystem

  return (
    <Grid container sx={{ mb: 3 }} spacing={2}>
      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {question.instructionPosition === InstructionPosition.Left && <QuestionInstructions question={question} />}
      </Grid>

      <Grid item xs={6} id={question.id}>
        {question.instructionPosition === InstructionPosition.Above &&
          <>
            <QuestionInstructions question={question} />
            <Box sx={{ mb: '4px' }} />
          </>
        }

        {!useAIForm &&
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: '4px', pr: 1 }}>
            <FormLabel error={!!error} required={question.isRequired} sx={{ fontWeight: 500 }}>
              {question.question}
              {(useAIForm && question.confirmSpelling) && ' (confirm the spelling)'}
            </FormLabel>

            {!!value.trim() ?
              <CheckGreen />
              :
              <CheckBlack />
            }
          </Stack>
        }

        {useAIForm &&
          <MarkfieldInput
            question={question}
            handleChange={handleChange}
            value={value}
            error={error}
          />
        }

        {(!useAIForm && question.fieldType === FieldType.Textbox) &&
          <TextfieldInput
            question={question}
            handleChange={handleChange}
            value={value}
            error={error}
          />
        }

        {(!useAIForm && question.fieldType === FieldType.Textarea) &&
          <TextareaInput
            question={question}
            handleChange={handleChange}
            value={value}
            error={error}
          />
        }

        {question.fieldType === FieldType.Picklist &&
          <PicklistInput
            question={question}
            handleChange={handleChange}
            value={value}
            error={error}
          />
        }

        {question.fieldType === FieldType.States &&
          <AutocompletePicklistInput
            question={question}
            handleChange={handleChange}
            value={value}
            options={(states || []).map(e => ({ label: e, value: e }))}
            error={error}
          />
        }

        {question.fieldType === FieldType.Contacts &&
          <AutocompletePicklistInput
            question={question}
            handleChange={handleChange}
            value={value}
            options={
              (contacts || [])
                .filter(e => !e.doNotUseInAccountContactsQuestion)
                .map(e => ({ label: e.name, value: e.id }))
            }
            error={error}
          />
        }

        {question.fieldType === FieldType.Custom &&
          <AutocompletePicklistInput
            question={question}
            handleChange={handleChange}
            value={value}
            options={
              (question.clientDictionaryRecords || [])
                .map(e => ({ label: e.name, value: e.id }))
            }
            error={error}
          />
        }

        {question.fieldType === FieldType.ContactsMultiple &&
          <AutocompletePicklistMultipleInput
            question={question}
            handleChange={handleChange}
            value={value}
            options={
              (contacts || [])
                .map(e => ({ label: e.name, value: e.id }))
            }
            error={error}
          />
        }

        {referToContact && <Typography>Use call handling procedure for {referToContact}</Typography>}
        {dictValue && <Typography>{dictValue}</Typography>}

        <Box sx={{ mb: '4px' }} />
        {(!question.instructionPosition || question.instructionPosition === InstructionPosition.Below) && <QuestionInstructions question={question} />}
        {question.HTMLInstructions &&
          <Typography component="div">
            <div dangerouslySetInnerHTML={{ __html: question.HTMLInstructions }}></div>
          </Typography>
        }
        {(question.fieldType === FieldType.Picklist && value) &&
          <Typography component="div">
            <div dangerouslySetInnerHTML={{ __html: question.picklist?.find(e => e.id === value)?.instructions || '' }}></div>
          </Typography>
        }
      </Grid>


      <Grid item xs={3}>
        {question.instructionPosition === InstructionPosition.Right && <QuestionInstructions question={question} />}
      </Grid>
    </Grid>
  )
}

export default Question;
