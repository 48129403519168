import { ValidationError } from "yup"

const parseYupErrors = (yupError: ValidationError) => {
  const errors: any = {}

  yupError.inner.forEach(err => {
    if (err.path) {
      errors[err.path] = err.message
    }
  })

  return errors
}

export default parseYupErrors