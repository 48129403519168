import { FC, useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FilterItem } from './useCallHistory';

interface IProps {
  name: string,
  selectedItems: FilterItem[],
  setSelectedItems: (items: FilterItem[]) => void,
  defaultOptions?: FilterItem[],
  fetchOptions?: (search?: string) => any,
  optionsFormatter: (items: any) => FilterItem[],
  search?: boolean, // Allow async search of the options
}

export const CallHistoryFilter: FC<IProps> = ({ name, selectedItems, setSelectedItems, defaultOptions, fetchOptions, optionsFormatter, search }) => {
  const [options, setOptions] = useState<FilterItem[]>(defaultOptions || [])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  useEffect(() => {
    if (fetchOptions) {
      fetchOptions().then((res: any) => {
        const options = optionsFormatter(res.data)
        setOptions(options)
      }) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (search) {
      if (fetchOptions) {
        setIsLoading(true)

        fetchOptions(inputValue).then((res: any) => {
          const options = optionsFormatter(res.data)
          setOptions(options)
        }).finally(() => setIsLoading(false))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  const handleChange = (e: any, newValue: FilterItem[] | null) => {
    setSelectedItems(newValue || [])
  }

  const handleInputChange = (e: any, newValue: string) => {
    setInputValue(newValue)
  }

  const filterOptions = (options: FilterItem[]) => {
    return options.filter(e => e.label.toLowerCase().includes(inputValue.toLowerCase()))
  }

  return (
    <Box mt="12px" p="2px">
      <Typography sx={{ fontWeight: 700, fontSize: '16px' }}>{name}</Typography>
      <Autocomplete
        sx={{ mt: '4px' }}
        size="small"
        fullWidth
        multiple
        limitTags={10}
        options={options}
        onChange={handleChange}
        value={selectedItems}
        loading={isLoading}
        disableCloseOnSelect
        openOnFocus
        getOptionLabel={(option: FilterItem) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onInputChange={handleInputChange}
        filterOptions={search ? filterOptions : undefined}
        noOptionsText="No results found"
        style={{ backgroundColor: '#E4ECFC', borderRadius: '5px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={!selectedItems.length ? 'Filter by ' + name : ''}
          />
        )}
      />
    </Box>
  );
}

export default CallHistoryFilter;