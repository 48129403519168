import { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { FilterItem } from './useCallHistory';
import CallHistoryFilter from './Filter';
import { getBlocks, getTeams } from 'api/callrecords';
import { searchUsers } from 'api/users';
import { searchCompanies } from 'api/accounts';
import debounce from 'awesome-debounce-promise';

export const ACTIVITY_TYPES = [
  { label: 'Answered call', value: 'Answered' },
  { label: 'Flagged call', value: 'Flagged' },
  { label: 'Form filled', value: 'FormFilled' },
  { label: 'Requeued call', value: 'Requeued' },
  { label: 'Transferred call', value: 'Transferred' },
  { label: 'Unanswered call', value: 'Unanswered' },
]

export const DIRECTIONS = [
  { label: 'Inbound Call', value: 'incoming' },
  { label: 'Outbound Call', value: 'outgoing' },
]

interface IProps {
  selectedBlocks: FilterItem[],
  setSelectedBlocks: (items: FilterItem[]) => void,
  selectedTeams: FilterItem[],
  setSelectedTeams: (items: FilterItem[]) => void,
  selectedReceptionists: FilterItem[],
  setSelectedReceptionists: (items: FilterItem[]) => void,
  selectedActivityTypes: FilterItem[],
  setSelectedActivityTypes: (items: FilterItem[]) => void,
  selectedAccounts: FilterItem[],
  setSelectedAccounts: (items: FilterItem[]) => void,
  selectedDirections: FilterItem[],
  setSelectedDirections: (items: FilterItem[]) => void,
}

const debouncedSearchUsers = debounce(searchUsers, 500)
const debouncedSearchCompanies = debounce(searchCompanies, 500)

export const CallHistoryFilters: FC<IProps> = ({
  selectedBlocks, setSelectedBlocks,
  selectedTeams, setSelectedTeams,
  selectedReceptionists, setSelectedReceptionists,
  selectedActivityTypes, setSelectedActivityTypes,
  selectedAccounts, setSelectedAccounts,
  selectedDirections, setSelectedDirections,
}) => {
  const formatTeamsOptions = (options: any[]) => {
    return options
      .map((e: any) => ({
        label: e.name,
        value: e.name === 'All Teams' ? 'all' : e.id
      }))
  }

  const formatReceptionistOptions = (options: any[]) => {
    return options
      .map((e: any) => ({ label: e.name, value: e.id }))
      .sort((a: FilterItem, b: FilterItem) => a.label > b.label ? 1 : -1)
  }

  const formatCompaniesOptions = (options: any[]) => {
    return options
      .map((e: any) => ({ label: e.name, value: e.id }))
      .sort((a: FilterItem, b: FilterItem) => a.label > b.label ? 1 : -1)
  }

  return (
    <Paper
      sx={{
        p: 2,
        pr: 1,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        height: 'calc(100% - 32px)',
      }}
      elevation={10}
    >
      <Typography style={{ fontWeight: 600, fontSize: '22px' }}>Filters</Typography>
      <Box
        sx={{
          pr: 1,
          maxHeight: 'calc(100% - 33px)',
          overflowY: 'auto',
          width: 550,
        }}
      >
        <Box>
          <CallHistoryFilter
            name="Blocks"
            selectedItems={selectedBlocks}
            setSelectedItems={setSelectedBlocks}
            fetchOptions={getBlocks}
            optionsFormatter={(items) => items.map((e: any) => ({ label: e, value: e }))}
          />
        </Box>
        <Box>
          <CallHistoryFilter
            name="Teams"
            selectedItems={selectedTeams}
            setSelectedItems={setSelectedTeams}
            fetchOptions={getTeams}
            optionsFormatter={formatTeamsOptions}
          />
        </Box>
        <Box>
          <CallHistoryFilter
            name="Receptionist"
            selectedItems={selectedReceptionists}
            setSelectedItems={setSelectedReceptionists}
            search
            fetchOptions={(search?: string) => debouncedSearchUsers(search as string, 20, true)}
            optionsFormatter={formatReceptionistOptions}
          />
        </Box>
        <Box>
          <CallHistoryFilter
            name="Activity Type"
            selectedItems={selectedActivityTypes}
            setSelectedItems={setSelectedActivityTypes}
            defaultOptions={ACTIVITY_TYPES}
            optionsFormatter={items => items}
          />
        </Box>
        <Box>
          <CallHistoryFilter
            name="Accounts"
            selectedItems={selectedAccounts}
            setSelectedItems={setSelectedAccounts}
            search
            fetchOptions={(search?: string) => debouncedSearchCompanies(search as string, 20)}
            optionsFormatter={formatCompaniesOptions}
          />
        </Box>

        <Box>
          <CallHistoryFilter
            name="Direction"
            selectedItems={selectedDirections}
            setSelectedItems={setSelectedDirections}
            defaultOptions={DIRECTIONS}
            optionsFormatter={items => items}
          />
        </Box>

      </Box>
    </Paper>
  );
}

export default CallHistoryFilters;