import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { removeTask } from "reducers/slices/accountPanelReducer";

type TaskMessage = {
  source: string,
  status: string,
  taskId: string,
}

const useTaskIframeListener = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const onIframeMessage = (message: MessageEvent<TaskMessage>) => {
      if (typeof message.data === 'object') {
        const { source, status, taskId } = message.data
        if (source === 'task' && status === 'submitted') {
          dispatch(removeTask(taskId))
        }
      }
    }

    window.addEventListener('message', onIframeMessage)
    return () => {
      window.removeEventListener('message', onIframeMessage)
    }
  }, [dispatch])

  return null;
};

export default useTaskIframeListener;
