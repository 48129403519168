import React, { FC, useEffect, useMemo, useState } from "react";
import { Box, Collapse, Paper } from "@mui/material";
import GroupHeader from "./GroupHeader";
import CallList from "./CallList";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { CallGroup } from "utils/activity";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface IProps {
  group: CallGroup,
  totalCalls: number,
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined,
}

const GroupContainer: FC<IProps> = ({ group, totalCalls, dragHandleProps }) => {
  const selectedUser = useSelector((state: RootState) => state.activityView.selectedUser)
  const groupUsers = useSelector((state: RootState) =>
    group.userIds.map(userId => state.activityView.allUsers[userId]))

  const [showMore, setShowMore] = useState<boolean>(false)

  useEffect(() => {
    if (!!selectedUser) {
      if (groupUsers.find(e => e.id === selectedUser)) {
        setShowMore(true)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  const sortedUsers = useMemo(() => {
    return groupUsers
      .sort((a, b) => a.isOnline === b.isOnline
        ? (a.name < b.name ? -1 : 1)
        : a.isOnline ? -1 : 1
      )
  }, [groupUsers])

  return (
    <Paper
      elevation={0}
      sx={{
        width: 'calc(100% - 32px)',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        px: 2,
      }}
    >
      <GroupHeader
        name={group.name}
        users={groupUsers}
        showMore={showMore}
        setShowMore={setShowMore}
        totalCalls={totalCalls}
        dragHandleProps={dragHandleProps}
      />
      <Collapse in={showMore} unmountOnExit mountOnEnter>
        <Box
          sx={{
            borderTop: '1px solid #CBD8F1',
            py: '20px',
          }}
        >
          {sortedUsers.map(user => (
            <CallList
              key={user.id}
              operator={user}
            />
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
};

export default GroupContainer;
