import { Box } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useMemo } from "react";
import AbbyForm from "./Form"

const PublicForm = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const linkParams = useMemo(() => {
    return {
      formId: params.formId,
      token: searchParams.get('token'),
      callerNumber: searchParams.get('callerNumber'),
      callerName: searchParams.get('callerName'),
      createdByName: searchParams.get('createdByName'),
      contactID: searchParams.get('contactID'),
      type: searchParams.get('type'),
    }
  }, [params, searchParams])

  const handleAfterSubmit = (callerNumber: string, accountName: string) => {
    const searchParams = { callerNumber, accountName };
    const searchString = new URLSearchParams(searchParams).toString();

    setTimeout(() => {
      navigate(`/submitted?${searchString}`)
    }, 500)
  }

  return (
    <Box width="100%" height="100vh">
      <AbbyForm
        {...linkParams}
        afterSubmit={handleAfterSubmit}
        isRendered
      />
    </Box>
  )
}

export default PublicForm;
