import React from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { ACCOUNT_PANEL_STATUS } from "utils/accounts";
import { Refresh } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setOpenAccountId } from "reducers/slices/accountPanelReducer";

const STATUS_TEXT_MAP = {
  [ACCOUNT_PANEL_STATUS.FIRST_LOAD]: 'No client selected',
  [ACCOUNT_PANEL_STATUS.LOADING]: 'Loading client info...',
  [ACCOUNT_PANEL_STATUS.LOADED]: 'Loading client info...',
  [ACCOUNT_PANEL_STATUS.NOT_FOUND]: 'Unable to fetch client info',
}

const Status = () => {
  const status = useSelector((state: RootState) => state.accountPanel.status)
  const dispatch = useDispatch()

  const refreshAccount = () => {
    dispatch(setOpenAccountId('last'))
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '28px',
          color: '#A4A4A4',
        }}
      >
        {STATUS_TEXT_MAP[status]}
      </Typography>

      {status === ACCOUNT_PANEL_STATUS.NOT_FOUND &&
        <Tooltip title="Refetch client info">
          <span>
            <IconButton
              onClick={refreshAccount}
              sx={{
                p: '4px',
                color: '#1033A5',
              }}
            >
              <Refresh />
            </IconButton>
          </span>
        </Tooltip>
      }
    </Stack>
  );
};

export default Status;
