import moment from 'moment-timezone';
import { useEffect } from "react";
import { logger } from "utils/utils";
import { ContextProvider } from "context/ContextProvider";
import GlobalSnackbar from "components/GlobalSnackbar";
import { store } from "reducers/store";
import { Provider } from "react-redux";
import { AppRoutes } from "./routes/AppRoutes";


const App = () => {
  useEffect(() => {
    moment.tz.setDefault('America/Los_Angeles');

    logger("APP VERSION:", process.env.REACT_APP_VERSION);

    const beforeUnloadHandler = (event: any) => {
      event.preventDefault()
      event.returnValue = true
    }

    window.addEventListener('beforeunload', beforeUnloadHandler)

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
    }
  }, [])

  useEffect(() => {
    const errorHandler = (err: any) => {
      logger(err)
    }

    window.addEventListener('error', errorHandler)

    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  return (
    <Provider store={store}>
      <ContextProvider>
        <GlobalSnackbar />
        <AppRoutes />
      </ContextProvider>
    </Provider>
  );
};



export default App;
