import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { duoEnroll, duoEnrollStatus } from "api/authDuo";
import AnimatedButton from "components/buttons/AnimatedButton";
import jwtDecode from "jwt-decode";
import BackgroundLayout from "pages/BackgroundLayout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";
import { LoggedInUser, updateUser } from "reducers/slices/userReducer";


const Enroll = () => {
  const [isEnrolling, setIsEnrolling] = useState<boolean>(false)
  const [duoUserId, setDuoUserId] = useState<string>('')
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('')
  const [activationCode, setActivationCode] = useState<string>('')
  const params = useParams();
  const token = params.token as string;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user] = useState<LoggedInUser | null>(() => {
    const token = params.token
    if (!token) return null

    const user = jwtDecode(token)
    return user as LoggedInUser
  })

  useEffect(() => {
    if (!user?.passed2fa && user?.duoUserId) {
      navigate('/duo/auth/' + token)
    }
  }, [user, navigate, token])

  useEffect(() => {
    let interval: NodeJS.Timer

    if (activationCode && duoUserId && token) {
      interval = setInterval(() => {
        duoEnrollStatus({ activationCode, duoUserId }, token).then((res) => {
          const response = res.data
          if (response === 'success') {
            clearInterval(interval)
            navigate('/')

            dispatch(setSnackbar({ message: 'Authentication added, please login again.', severity: SnackbarSeverity.SUCCESS }))
          }
        })
      }, 2000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [activationCode, duoUserId, navigate, token, dispatch])

  const startEnrollment = () => {
    setIsEnrolling(true)
    duoEnroll(token).then((res) => {
      const response = res.data.response
      setDuoUserId(response.user_id)
      setQrCodeUrl(response.activation_barcode)
      setActivationCode(response.activation_code)
    })
  }

  const skipEnrollment = () => {
    dispatch(updateUser(params.token as string))
  }

  const required = !user?.passed2fa
  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <Box px={6} py={4}>
          {!isEnrolling &&
            <Box width={400}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '24px', }}>Enable 2-Factor Authentication</Typography>
              <Typography align="center" sx={{ mt: 1, lineHeight: '20px' }}>
                {required && '2FA is required to access Call Center. Please setup your Duo mobile app before continuing.'}
                {!required && 'Keep your account secure by enabling Two Factor Authentication through Duo mobile.'}
              </Typography>
              <AnimatedButton
                sx={{ mt: 4 }}
                label="SETUP 2FA"
                onClick={startEnrollment}
              />
              {!required &&
                <Button
                  onClick={skipEnrollment}
                  variant="text"
                  sx={{ mt: 2, width: '100%' }}
                >
                  Skip for now
                </Button>
              }
            </Box>
          }

          {isEnrolling &&
            <Box width={900}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '24px', }}>Setting Up 2-Factor Authentication</Typography>
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <Stack alignItems="center">
                    <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>1. Install</Typography>
                    <Typography>Duo Mobile is free for both iPhone and Android.</Typography>
                    <img width="200px" height="200px" alt="qr" src="/assets/qr/duo-apple.png" />
                    <Typography sx={{ fontSize: '12px', mt: -2 }}>iPhone</Typography>
                    <img width="200px" height="200px" alt="qr" src="/assets/qr/duo-android.png" />
                    <Typography sx={{ fontSize: '12px', mt: -2 }}>Android</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack alignItems="center">
                    <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>2. Tap "+" and Scan</Typography>
                    <Typography>Open Duo Mobile and tap the "+" button to add an account.</Typography>
                    <Typography>Scanning this barcode will link the app to your account.</Typography>
                    {qrCodeUrl && <img width="300px" height="300px" alt="qr" src={qrCodeUrl} />}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          }
        </Box>
      </Paper>
    </BackgroundLayout>
  );
};

export default Enroll;
