import React, { FC } from "react";
import { Avatar, AvatarGroup, Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Check, DragIndicator, KeyboardArrowDown, KeyboardArrowUp, Pause, Phone, SupportAgent } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { ActiveCall, CallStateState } from "utils/calls";
import { nameToInitial } from "utils/utils";
import { User } from "utils/activity";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";


interface IProps {
  name: string,
  users: User[],
  showMore: boolean,
  setShowMore: (showMore: boolean) => void,
  totalCalls: number,
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined,
}

const GroupHeader: FC<IProps> = ({ name, users, showMore, setShowMore, totalCalls, dragHandleProps }) => {
  const groupUserCalls = useSelector((state: RootState) => {
    let conferenceNames: string[] = []
    users.forEach(user => {
      conferenceNames = conferenceNames.concat(state.callSlot.otherUserCalls[user.id])
    })

    let callStates = conferenceNames.map(conferenceName => state.call[conferenceName])
    return callStates
  })

  const activeCalls = groupUserCalls.filter(call => call && ActiveCall.includes(call.state)).length
  const holdCalls = groupUserCalls.filter(call => call && call.state === CallStateState.ONHOLD).length
  return (
    <Grid
      container
      sx={{
        height: 60,
        alignItems: 'center',
        px: '4px',
      }}
    >
      <Grid item xs={3} container alignItems="center">
        <Box {...dragHandleProps} width={24} height={24}>
          <DragIndicator sx={{ color: '#405CB7' }} />
        </Box>
        <Typography sx={{ ml: 3 }} style={{ fontSize: '18px', fontWeight: 600 }}>{name}</Typography>
      </Grid>
      <Grid item xs={3} container justifyContent="center">
        <AvatarGroup max={6}>
          {users.map(user => (
            <Tooltip key={user.id} placement="top" title={`${user.name} (${user.email}) - ${user.role}`}>
              <Avatar src={user.avatar} sx={{ backgroundColor: '#1033A5', width: 32, height: 32 }}>
                {nameToInitial(user.name)}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      </Grid>
      <Grid item xs={3} container alignItems="center" justifyContent="center">
        <Check sx={{ color: '#448C3D' }} />
        <Typography sx={{ width: 40, ml: 1, mr: 2 }} style={{ fontSize: '16px', fontWeight: 700, color: '#448C3D' }}>{totalCalls}</Typography>
        <Phone sx={{ color: '#1033A5' }} />
        <Typography sx={{ width: 40, ml: 1, mr: 2 }} style={{ fontSize: '16px', fontWeight: 700, color: '#1033A5' }}>{activeCalls}</Typography>
        <Pause sx={{ color: '#D79F2B' }} />
        <Typography sx={{ width: 40, ml: 1, mr: 2 }} style={{ fontSize: '16px', fontWeight: 700, color: '#D79F2B' }}>{holdCalls}</Typography>
        <SupportAgent sx={{ color: '#5F27CB' }} />
        <Typography sx={{ width: 40, ml: 1 }} style={{ fontSize: '16px', fontWeight: 700, color: '#5F27CB' }}>{users.filter(user => user.isOnline).length}</Typography>
      </Grid>
      <Grid
        item xs={3} container
        sx={{
          alignItems: 'center',
          justifyContent: 'flex-end',
          cursor: 'pointer',
        }}
        onClick={() => setShowMore(!showMore)}
      >
        <Typography style={{ fontSize: '16px', fontWeight: 600, color: '#1033A5', width: 75 }}>
          {showMore ? 'See Less' : 'See More'}
        </Typography>
        <IconButton onClick={() => {}}>
          {showMore ?
            <KeyboardArrowUp sx={{ color: '#1033A5 ' }} />
            :
            <KeyboardArrowDown sx={{ color: '#1033A5 ' }} />
          }
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GroupHeader;
