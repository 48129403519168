import React, { useEffect, useState } from "react";
import { Avatar, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from "@mui/material";
import { AccountCircle, BugReport, History, Logout, Settings, ViewStream } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addUserDetails, logout } from "reducers/slices/userReducer";
import { RootState } from "reducers/store";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "api/users";
import { ViewRoute, nameToInitial } from "utils/utils";
import { setIsGlobalHistoryOpen, setIsSettingsOpen } from "reducers/slices/UIReducer";
import ReportBug from "./ReportBug";

export const HEADER_HEIGHT = 68
const ICON_COLOR = '#1033A5'

const UserMenu = ({ isReceptionist }: { isReceptionist?: boolean}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [reportBugOpen, setReportBugOpen] = useState<boolean>(false)
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.userId) {
      getUserDetails(user.userId).then(res => {
        dispatch(addUserDetails(res.data))
      })
    }
  }, [user?.userId, dispatch])

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const openMyAccount = () => {
    setAnchorEl(null)
    navigate('/my-account')
  }

  const openActivityView = () => {
    setAnchorEl(null)
    navigate(ViewRoute.ACTIVITY_VIEW)
  }

  const openSettings = () => {
    setAnchorEl(null)
    dispatch(setIsSettingsOpen(true))
  }

  const openCallHistory = () => {
    setAnchorEl(null)
    dispatch(setIsGlobalHistoryOpen(true))
  }

  const openReportBug = () => {
    setAnchorEl(null)
    setReportBugOpen(true)
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const actions = [
    { label: 'Activity View', onClick: openActivityView, icon: <ViewStream sx={{ color: ICON_COLOR }} />, isReceptionist: true },
    { label: 'Call History', onClick: openCallHistory, icon: <History sx={{ color: ICON_COLOR }} />, isReceptionist: true },
    { label: 'My Account', onClick: openMyAccount, icon: <AccountCircle sx={{ color: ICON_COLOR }} /> },
    { label: 'Settings', onClick: openSettings, icon: <Settings sx={{ color: ICON_COLOR }} /> },
    { label: 'divider', onClick: () => {}, icon: null },
    { label: 'Report a bug', onClick: openReportBug, icon: <BugReport sx={{ color: ICON_COLOR }} /> },
    { label: 'Log out', onClick: handleLogout, icon: <Logout sx={{ color: ICON_COLOR, ml: '3px' }} /> },
  ].filter(e => !e.isReceptionist || e.isReceptionist === isReceptionist)

  return (
    <>
      <IconButton sx={{ padding: '2px' }} onClick={openMenu}>
        <Avatar src={user?.details?.avatar} sx={{ width: 32, height: 32, bgcolor: '#1033A5' }}>
          {nameToInitial(user?.details?.name || '')}
        </Avatar>
      </IconButton>

      <ReportBug open={reportBugOpen} setOpen={setReportBugOpen} />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        disableAutoFocus
      >
        <List sx={{ pb: 1 }} dense disablePadding>
          <ListItem sx={{ py: 1, pr: 5 }}>
            <ListItemText
              primary={user?.details?.name}
              secondary={user?.details?.email}
              primaryTypographyProps={{ style: { fontWeight: 600, fontSize: '14px' } }}
              secondaryTypographyProps={{ style: { fontWeight: 600, fontSize: '12px', color: '#1033A5' } }}
            />
          </ListItem>
          <Divider />
          {actions.map((action, i) => (action.label === 'divider' ? 
            <Divider key={i} />
            :
            <ListItem disablePadding key={action.label}>
              <ListItemButton sx={{ py: 1 }} onClick={action.onClick}>
                <ListItemIcon sx={{ minWidth: 0, pr: 1 }}>
                  {action.icon}
                </ListItemIcon>
                <ListItemText
                  primary={action.label}
                  primaryTypographyProps={{ style: { fontWeight: 600 } }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>

  );
};

export default UserMenu;
