export interface User {
  avatar: string,
  userBlock: string | null,
  teamBlock: string,
  email: string,
  firstName: string,
  id: string,
  isOnline: boolean,
  name: string,
  role: string,
  spanishCalls: boolean,
  teamId: string,
  teamName: string,
  extension?: {
    evoLevels: string,
    id: string,
    name: string
  }
}

export interface CallGroup {
  id: string,
  name: string,
  userIds: string[],
}

export const GROUP_ORDER_STORAGE = 'GROUP_ORDER_STORAGE'
