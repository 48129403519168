import { cleanPhoneNumber } from "./cleanPhoneNumber";

const addCountryCodeToNum = (phoneNumber: string) => {
  let num = phoneNumber;
  if (!num.startsWith('+')) {
    if (num.startsWith('1')) {
      num = '+' + num;
    } else {
      num = '+1' + num;
    }
  }
  return num;
}

export const isSameNumber = (phoneNumber1?: string | null, phoneNumber2?: string | null) => {
  if (!phoneNumber1 || !phoneNumber2) return false

  const num1 = addCountryCodeToNum(cleanPhoneNumber(phoneNumber1));
  const num2 = addCountryCodeToNum(cleanPhoneNumber(phoneNumber2));

  return num1 === num2;
};
