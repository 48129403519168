import { createAppSelector } from "reducers/hooks";
import { isActiveCall } from "utils/calls";
import { CallState } from "utils/calls";

export const selectedCallSelector = createAppSelector(
  [
    state => state.callSlot.selectedCall,
    (state, properties: (keyof CallState)[] = []) => properties,
  ],
  (selectedCall, properties) => {
    if (properties.length && selectedCall) {
      const partialCall: Partial<CallState> = {}
      properties.forEach(property => {
        // @ts-ignore
        partialCall[property] = selectedCall[property]
      })

      return partialCall as CallState
    }

    return selectedCall
  }
)

export const activeCallSelector = createAppSelector(
  [
    state => state.callSlot.activeCall,
    (state, properties: (keyof CallState)[] = []) => properties,
  ],
  (activeCall, properties) => {
    if (properties.length && activeCall) {
      const partialCall: Partial<CallState> = {}
      properties.forEach(property => {
        // @ts-ignore
        partialCall[property] = activeCall[property]
      })

      return partialCall as CallState
    }

    return activeCall
  }
)

// For ghost call cleaner
export const activeCallSelector2 = createAppSelector(
  [
    state => state.call,
    state => state.user.user,
    (state, properties: (keyof CallState)[] = []) => properties,
  ],
  (calls, user, properties) => {
    const activeCall = Object.values(calls)
      .filter(call => !call?.isRemoved)
      .find(call => call && (call.isProcessing || isActiveCall(call, user?.userId)))

    if (properties.length && activeCall) {
      const partialCall: Partial<CallState> = {}
      properties.forEach(property => {
        // @ts-ignore
        partialCall[property] = activeCall[property]
      })

      return partialCall as CallState
    }

    return activeCall
  }
)
