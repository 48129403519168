import React, { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import AnimatedButton from "components/buttons/AnimatedButton";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import { changeAvatar } from "api/users";

type IProps = {
  isLoading: string,
  setIsLoading: (isLoading: string) => void,
  fetchAccount: () => void,
}

const UploadAvatarButton: FC<IProps> = ({ isLoading, setIsLoading, fetchAccount }) => {
  const fileInput = useRef<HTMLInputElement | null>(null)
  const dispatch = useDispatch()

  const onUploadClick = () => {
    if (fileInput.current) {
      fileInput.current.click()
    }
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > 1024 * 1024) {
        dispatch(setSnackbar({ message: 'Maximum image size is 1MB', severity: SnackbarSeverity.ERROR }))
        return
      }

      const formData = new FormData()
      formData.append('file', file, file.name)

      setIsLoading('upload')
      changeAvatar(formData).then(res => {
        fetchAccount()
        dispatch(setSnackbar({ message: 'Avatar is successfully uploaded', severity: SnackbarSeverity.SUCCESS }))
      }).finally(() => {
        event.target.value = ''
        setIsLoading('')
      })
    }
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={handleUpload}
      />
      <AnimatedButton
        label="Upload Avatar"
        sx={{
          mr: 1,
          width: 130,
          borderRadius: '5px',
        }}
        onClick={onUploadClick}
        loading={isLoading === 'upload'}
        disabled={!!isLoading}
      />
    </>
  );
};

export default UploadAvatarButton;
