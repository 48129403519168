import { FC, useEffect } from 'react';
import useAudioPlayer from 'hooks/useAudioPlayer';
import RecordingPlayer from './RecordingPlayer';

interface IProps {
  recordingLink: string,
  isGlobal?: boolean,
  isViewingGlobalCallHistory?: boolean,
}

export const AudioRecordingPlayer: FC<IProps> = ({ recordingLink, isGlobal, isViewingGlobalCallHistory }) => {
  const audioPlayer = useAudioPlayer(recordingLink)

  useEffect(() => {
    if ((isGlobal && !isViewingGlobalCallHistory) || // On global but hide global history
      (!isGlobal && isViewingGlobalCallHistory) // On account history but opening global history
    ) {
      if (audioPlayer.isPlaying) {
        audioPlayer.pause()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewingGlobalCallHistory, isGlobal])

  return (
    <RecordingPlayer audioPlayer={audioPlayer}  />
  );
}

export default AudioRecordingPlayer;