import React, { useCallback, useEffect, useState } from "react";
import * as yup from 'yup';
import { Close, Flag } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, PaperProps, TextField, Typography } from "@mui/material";
import useCallFunctions from "hooks/useCallFunctions";
import AnimatedButton from "components/buttons/AnimatedButton";
import { flagCall } from "api/callrecords";
import { CallState, CallStateState, CallType, FlagNote } from "utils/calls";
import parseYupErrors from "utils/parseYupErrors";
import Draggable from "react-draggable";
import { useAppDispatch, useAppSelector } from "reducers/hooks";
import { selectedCallSelector } from "reducers/selector/callSelector";
import { AppThunkDispatch } from "reducers/types";
import { getSelectedCall } from "reducers/thunks/callThunks";

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-flag-note"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const flagSchema = yup.object().shape({
  reasons: yup.string().trim().required().max(2000).label('Flag Reasons'),
})

interface FlagForm {
  reasons: string,
}

const defaultForm: FlagForm = {
  reasons: '',
}

const CallFlagger = () => {
  const { updateCall } = useCallFunctions()
  const selectedCall = useAppSelector(state =>
    selectedCallSelector(state, ['flagNotes', 'state', 'type'])) as Pick<CallState, 'flagNotes' | 'state' | 'type'> | undefined
  const { user } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch() as AppThunkDispatch

  const [callState, setCallState] = useState<CallState | undefined>(undefined)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState<FlagForm>({ ...defaultForm })
  const [errors, setErrors] = useState<FlagForm>({ ...defaultForm })

  useEffect(() => {
    if (open) {
      const selectedCall = dispatch(getSelectedCall())
      setCallState(selectedCall)
    }
  }, [open, dispatch])

  useEffect(() => {
    setForm({ ...defaultForm })
  }, [callState?.conferenceName])

  const handleChange = (name: keyof FlagForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: event.target.value,
    })
    setErrors({ ...defaultForm })
  }

  const handleSave = () => {
    setErrors({ ...defaultForm })
    setIsLoading(true)
    flagSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        if (callState?.callRecordId) {
          flagCall(callState.conferenceName, callState.callRecordId, form.reasons.trim()).then((res) => {
            const flagNotes: FlagNote[] = [...callState.flagNotes || []]
            flagNotes.push({
              createdOn: new Date().toISOString(),
              answeredBy: user?.details?.name || '',
              note: form.reasons.trim()
            })
            updateCall(callState.conferenceName, { flagNotes })
            setOpen(false)
            setForm({ ...defaultForm })
          }).finally(() => {
            setIsLoading(false)
          })
        } else {
          setIsLoading(false)
        }
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setIsLoading(false)
      })
  }

  const handleClose = () => {
    if (!isLoading) {
      setOpen(false)
    }
  }

  const handleRefAutoFocus = useCallback((node: any) => {
    const element = node
    if (!element) return

    // Put the cursor at the end of input
    element.setSelectionRange(element.value.length, element.value.length)
    element.focus()
  }, []);

  const isSelectedCallFlagged = !!selectedCall?.flagNotes?.length
  return (
    <>
      <Button
        sx={{
          mr: '4px',
          minWidth: 84,
          backgroundColor: isSelectedCallFlagged ? '#FF3737' : '#FFFFFF',
          color: isSelectedCallFlagged ? '#FFFFFF' : '#000000',
          '&:hover': {
            backgroundColor: isSelectedCallFlagged ? '#E80B0B' :'#EEEEEE'
          },
        }}
        startIcon={<Flag sx={{ color: isSelectedCallFlagged ? '#FFFFFF' : '#E80B0B' }} />}
        onClick={() => setOpen(true)}
        disabled={
          !selectedCall ||
          (!isSelectedCallFlagged && selectedCall?.state === CallStateState.UNANSWERED) ||
          selectedCall?.type === CallType.UNBOUND
        }
      >
        Flag
      </Button>
    
      <Dialog
        open={open}
        hideBackdrop
        PaperComponent={PaperComponent}
        sx={{ pointerEvents: 'none' }}
        PaperProps={{
          sx: { pointerEvents: 'auto' }
        }}
        disableEnforceFocus
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" sx={{ cursor: 'move' }} id="draggable-flag-note">
            <Flag sx={{ color: '#E80B0B', mr: 1 }} />
            <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>Flag Call</Typography>
          </Box>

          <IconButton
            disabled={isLoading}
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 14,
              top: 12,
            }}
          >
            <Close sx={{ fontSize: '32px' }} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ pb: 0, width: 450 }}>
          <Typography sx={{ mt: 1, fontSize: '20px', fontWeight: 600 }}>{callState?.name}</Typography>
          <Typography sx={{ mt: 1, fontSize: '16px', fontWeight: 600, color: '#1033A5' }}>{callState?.callerNumber}</Typography>
          <Typography sx={{ mt: 2, mb: 1, fontSize: '14px', fontWeight: 600 }}>Reason for call flagging</Typography>

          <TextField
            sx={{
              borderRadius: '5px',
            }}
            InputProps={{ sx: { p: 2, bgcolor: '#E4ECFC' } }}
            inputProps={{
              style: { fontSize: '16px' },
              maxLength: 2000,
            }}
            fullWidth
            autoFocus
            variant="outlined"
            multiline
            rows={4}
            value={form.reasons}
            inputRef={handleRefAutoFocus}
            onChange={handleChange('reasons')}
            error={!!errors.reasons}
            helperText={errors.reasons || (form.reasons.length + '/2000 ')}
          />

        </DialogContent>
        <DialogActions sx={{ pb: 3, px: 3 }}>
          <AnimatedButton
            loading={isLoading}
            label="Flag Call"
            onClick={handleSave}
            sx={{ width: 110, height: 40 }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CallFlagger;
