import React, { useState } from "react";
import * as yup from 'yup';
import { Box, Button, FormLabel, Paper, TextField, Typography } from "@mui/material";
import parseYupErrors from "utils/parseYupErrors";
import { resetPassword } from "api/auth";
import BackgroundLayout from "pages/BackgroundLayout";
import { useNavigate } from "react-router-dom";
import AnimatedButton from "components/buttons/AnimatedButton";

const loginSchema = yup.object().shape({
  email: yup.string().email('Email Address format is invalid').required().label('Email Address'),
})

interface LoginForm {
  email: string,
}

const defaultForm: LoginForm = {
  email: '',
}

const ResetPassword = () => {
  const [form, setForm] = useState<LoginForm>({ ...defaultForm })
  const [errors, setErrors] = useState<LoginForm>({ ...defaultForm })
  const [errorMsg, setErrorMsg] = useState<string>('') // From BE
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleChange = (name: keyof LoginForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: event.target.value,
    })
    setErrors({ ...defaultForm })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setErrors({ ...defaultForm })
    setErrorMsg('')
    setLoading(true)
    loginSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        resetPassword({ login: form.email }).then((res) => {
          navigate('/email-sent')
        })
        .catch((err) => {
          setErrorMsg(err.response.data)
        })
        .finally(() => {
          setLoading(false)
        })
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setLoading(false)
      })
  }

  return (
    <BackgroundLayout>
      <Paper elevation={5}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              px: 6,
              py: 8,
              width: 350,
            }}
          >
            <Typography style={{ fontWeight: 500, fontSize: '36px', lineHeight: '50px' }}>Reset Password</Typography>
            <Typography align="center" sx={{ mt: 1, width: 260, fontSize: '12px', lineHeight: '20px' }}>
              Enter the mail associated with your account and we'll send an email with instructions to reset your password.
            </Typography>
            <TextField
              sx={{
                mt: 2,
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '10px' }
              }}
              placeholder="Email Address"
              fullWidth
              size="small"
              value={form.email}
              onChange={handleChange('email')}
              error={!!errors.email}
              helperText={errors.email || ''}
              InputProps={{
                sx: { bgcolor: '#E4ECFC', borderRadius: '10px', },
              }}
            />
            <FormLabel sx={{ mb: 1 }} error>{errorMsg}&nbsp;</FormLabel>
            <AnimatedButton
              loading={loading}
              label="Send Instructions"
              type="submit"
              disabled={loading}
            />
            <Button
              onClick={() => navigate('/login')}
              variant="text"
              sx={{ mt: 2, width: '100%' }}
            >
              Login
            </Button>
          </Box>
        </form>
      </Paper>
    </BackgroundLayout>
  );
};

export default ResetPassword;
