import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { PauseCircle, PlayCircle, ReplayCircleFilled } from '@mui/icons-material';
import { FC } from 'react';
import { AudioPlayer } from 'hooks/useAudioPlayer';

interface IProps {
  audioPlayer: AudioPlayer,
  isClient?: boolean
}

export const RecordingPlayerSmall: FC<IProps> = ({ audioPlayer, isClient }) => {

  return (
    <>
      <Tooltip title={
        audioPlayer.isEnded ? 'Replay' :
          audioPlayer.isPlaying ? 'Pause' : 'Play'
      }>
        <Box ml="-4px" position="relative" display="flex" alignItems="center" alignContent="center">
          <CircularProgress
            sx={{
              position: 'absolute',
            }}
            size={28}
            variant="determinate"
            value={(audioPlayer.isEnded || !audioPlayer.totalDuration) ? 0 
              : ((audioPlayer.currentTime / audioPlayer.totalDuration) * 100)
            }
          />
          <IconButton sx={{ p: '2px' }} onClick={audioPlayer.isPlaying ? audioPlayer.pause : audioPlayer.play}>
            {audioPlayer.isEnded && <ReplayCircleFilled sx={{ color: '#1033A5' }} />}
            {!audioPlayer.isEnded && (
              audioPlayer.isPlaying ?
                <PauseCircle sx={{ color: '#1033A5' }} />
                :
                <PlayCircle sx={{ color: '#1033A5' }} />
            )}
          </IconButton>
        </Box>
      </Tooltip>
      <Typography sx={{ fontWeight: 700, fontSize: '14px', color: '#1033A5', ml: 1 }}>
        Call Recording {isClient ? '(Receptionist & Callee)' : '(Caller & Receptionist)'}
      </Typography>
    </>
  )
}

export default RecordingPlayerSmall;