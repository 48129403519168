import { useEffect, useState } from "react"
import { PhoneTonePlayer } from "play-dtmf"
import { DtmfContext } from "./dtmf-context"

export const DtmfProvider = (props: any) => {
  const [dtmfPlayer, setDtmfPlayer] = useState<PhoneTonePlayer | null>(null)

  useEffect(() => {
    setupDtmfPlayer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setupDtmfPlayer = () => {
    const audioContext = new AudioContext()
    const newPlayer = new PhoneTonePlayer(audioContext)

    setDtmfPlayer(newPlayer)
  }

  return (
    <DtmfContext.Provider value={{ dtmfPlayer }}>
      {props.children}
    </DtmfContext.Provider>
  )
}