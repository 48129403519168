import { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import { CallRecordsParams, getCallRecords } from 'api/callrecords';
import { CallRecord } from 'utils/calls';
import { useDispatch } from 'react-redux';
import { CallHistoryFilters as CallHistoryFiltersState, resetNewCallHistoryCount } from 'reducers/slices/callHistoryReducer';
import debounce from 'awesome-debounce-promise';

export interface FilterItem {
  label: string,
  value: string,
}

interface IProps {
  isGlobal?: boolean,
  defaultFilters?: {
    accounts: FilterItem[],
  }
}

export const MAX_HISTORY_ITEMS = 100
const debouncedCallRecords = debounce(getCallRecords, 500)

export const useCallHistory = ({ isGlobal, defaultFilters }: IProps) => {
  const [startDate, setStartDate] = useState<Moment | null>(moment().subtract(1, 'year').startOf('day'))
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [selectedBlocks, setSelectedBlocks] = useState<FilterItem[]>([])
  const [selectedTeams, setSelectedTeams] = useState<FilterItem[]>([])
  const [selectedReceptionists, setSelectedReceptionists] = useState<FilterItem[]>([])
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<FilterItem[]>([])
  const [selectedAccounts, setSelectedAccounts] = useState<FilterItem[]>(defaultFilters?.accounts || [])
  const [selectedDirections, setSelectedDirections] = useState<FilterItem[]>([])
  const [otherFilters, setOtherFilters] = useState<CallHistoryFiltersState>({})
  
  const [callRecords, setCallRecords] = useState<CallRecord[]>([])
  const [rowsPerPage] = useState<number>(20)
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const dispatch = useDispatch()

  const callRecordsQuery: Partial<CallRecordsParams> = useMemo(() => {
    return {
      searchString: otherFilters.everywhere || undefined,
      callerNumber: otherFilters.callerNumber || undefined,
      numberCallerCalled: otherFilters.numberCallerCalled || undefined,
      notes: otherFilters.note || undefined,
      transferToNumber: otherFilters.trfToNum || undefined,
      callSid: otherFilters.callSid || undefined,

      block: selectedBlocks.map(e => e.value),
      teamIds: selectedTeams.map(e => e.value),
      receptionistIds: selectedReceptionists.map(e => e.value),
      callTypes: selectedActivityTypes.map(e => e.value),
      accountIds: selectedAccounts.map(e => e.value),
      direction: selectedDirections.map(e => e.value),

      from: startDate?.format('YYYY-MM-DD') || undefined,
      to: endDate?.format('YYYY-MM-DD') || undefined,
    }
  }, [otherFilters, selectedBlocks, selectedTeams, selectedReceptionists,
    selectedActivityTypes, selectedAccounts, selectedDirections, startDate, endDate])

  useEffect(() => {
    refreshHistory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRecordsQuery])

  const refreshHistory = () => {
    if (isGlobal) {
      dispatch(resetNewCallHistoryCount())
    }

    setHasMore(true)
    setCallRecords([])
    debouncedCallRecords({
      ...callRecordsQuery,
      limit: rowsPerPage,
      page: 1,
    }).then(res => {
      setPage(1)
      setCallRecords(res.data)
      if (!res.data.length) {
        setHasMore(false)
      }
    })
  }

  const loadNextPage = () => {
    const nextPage = page + 1
    setPage(nextPage)

    // TODO: Load using latest data's date as start from date range, as there might be new data in between, making pagination invalid.
    getCallRecords({
      ...callRecordsQuery,
      limit: rowsPerPage,
      page: nextPage
    }).then(res => {
      const newCallRecords = callRecords.concat(res.data)
      setCallRecords(newCallRecords)
      if (!res.data.length || newCallRecords.length >= MAX_HISTORY_ITEMS) {
        setHasMore(false)
      }
    })
  }

  return {
    startDate, setStartDate,
    endDate, setEndDate,
    selectedBlocks, setSelectedBlocks,
    selectedTeams, setSelectedTeams,
    selectedReceptionists, setSelectedReceptionists,
    selectedActivityTypes, setSelectedActivityTypes,
    selectedAccounts, setSelectedAccounts,
    selectedDirections, setSelectedDirections,
    otherFilters, setOtherFilters,
    callRecords, setCallRecords,
    hasMore, setHasMore,

    callRecordsQuery, refreshHistory, loadNextPage
  }
}

export default useCallHistory;