import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CallState } from "utils/calls";

export interface _CallState {
  [conferenceName: string]: CallState | undefined,
}

const initialState: _CallState = {}

interface AddCallsPayload {
  calls: CallState[],
}

export const callSlotSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    addCall: (state, action: PayloadAction<CallState>) => {
      const { conferenceName } = action.payload
      if (!state[conferenceName]) {
        state[conferenceName] = action.payload
      }
    },
    addCalls: (state, action: PayloadAction<AddCallsPayload>) => {
      const { calls } = action.payload

      calls.forEach(newState => {
        const { conferenceName } = newState
        const existingState = state[conferenceName]

        if (existingState?.isRemoved) {
          return
        }

        // Skip if existing is newer than newState
        if (existingState && existingState.updatedAt && newState.updatedAt) {
          if (existingState.updatedAt > newState.updatedAt) {
            return
          }
        }

        // Preserve transfersDialed from the existing state because we don't receive it from the server
        const transfersDialed = existingState?.transfersDialed || [];

        state[conferenceName] = {
          ...newState,
          isProcessing: state[conferenceName]?.isProcessing,
          transfersDialed
        }
      })
    },
    updateCall: (state, action: PayloadAction<CallState>) => {
      const { payload } = action
      state[payload.conferenceName] = payload
    },
    removeCall: (state, action: PayloadAction<string>) => {
      const conferenceName = action.payload
      if (state[conferenceName]) {
        // @ts-ignore the call state is defined, checked above
        state[conferenceName].isRemoved = true // Flag the call as removed
        // @ts-ignore the call state is defined, checked above
        state[conferenceName].isProcessing = false
        // @ts-ignore the call state is defined, checked above
        state[conferenceName].removedAt = Date.now()
      }
    },
    deleteCall: (state, action: PayloadAction<string>) => {
      const conferenceName = action.payload
      state[conferenceName] = undefined
    },
    // Clear all except the specified conference names
    clearCalls: (state, action: PayloadAction<string[]>) => {
      const newState: _CallState = {}
      action.payload.forEach(conferenceName => {
        newState[conferenceName] = state[conferenceName]
      })

      return newState
    },
  },
});

export const { addCall, addCalls, updateCall, removeCall, deleteCall, clearCalls } = callSlotSlice.actions;

export default callSlotSlice.reducer;
