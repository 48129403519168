import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FilterItem } from "pages/main/callHistory/useCallHistory";

export interface CallHistoryFilters {
  everywhere?: string,
  callerNumber?: string,
  numberCallerCalled?: string,
  note?: string,
  trfToNum?: string,
  account?: FilterItem,
  callSid?: string,
}

export interface CallHistoryState {
  newCallHistoryCount: number,
  filters: CallHistoryFilters,
  isViewingGlobalCallHistory?: boolean,
  isListeningToRecording: string | null,
}

const initialState: CallHistoryState = {
  newCallHistoryCount: 0,
  filters: {},
  isViewingGlobalCallHistory: false,
  isListeningToRecording: null,
};

export const CallHistorySlice = createSlice({
  name: "callHistory",
  initialState,
  reducers: {
    addNewCallHistoryCount: (state) => {
      state.newCallHistoryCount += 1
    },
    resetNewCallHistoryCount: (state) => {
      state.newCallHistoryCount = 0
    },
    /**
     * Will open call history as well
     */
    setCallHistoryFilters: (state, action: PayloadAction<CallHistoryFilters>) => {
      state.filters = action.payload
    },
    setIsViewingGlobalCallHistory: (state, action: PayloadAction<boolean>) => {
      state.isViewingGlobalCallHistory = action.payload
    },
    setIsListeningToRecording: (state, action: PayloadAction<string | null>) => {
      state.isListeningToRecording = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  addNewCallHistoryCount, resetNewCallHistoryCount, setCallHistoryFilters,
  setIsViewingGlobalCallHistory, setIsListeningToRecording
 } = CallHistorySlice.actions;

export default CallHistorySlice.reducer;
