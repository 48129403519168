import { FC, useMemo } from 'react'
import { Button, Paper, Typography } from '@mui/material';
import { ActiveCall, CallStateState, CallType, getCallColors } from 'utils/calls';
import { VolumeUp } from '@mui/icons-material';
import { useAppSelector } from 'reducers/hooks';
import useCallFunctions from 'hooks/useCallFunctions';
import CallerInfo from './CallerInfo';
import { useDispatch } from 'react-redux';
import { openCallNote } from 'reducers/slices/callSlotReducer';
import CallNoteDisplay from './CallNoteDisplay';

interface IProps {
  conferenceName: string,
  isListening: boolean,
}

const CallCardSmall: FC<IProps> = ({
  conferenceName,
  isListening,
}: IProps) => {
  const callState = useAppSelector(state => state.call[conferenceName || ''])
  const loggedInUser = useAppSelector(state => state.user.user)
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const { joinCall } = useCallFunctions()
  const dispatch = useDispatch()

  const [cardColor, textColor] = useMemo(() => {
    return getCallColors(callState?.state)
  }, [callState])

  const onTakeCall = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    if (callState?.type === CallType.OUTBOUND && callState?.state === CallStateState.UNANSWERED) return

    switch (callState?.state) {
      case CallStateState.UNANSWERED:
      case CallStateState.ANSWERED:
      case CallStateState.ONHOLD:
      case CallStateState.TRANSFERRING:
      case CallStateState.WITH_CALLER:
      case CallStateState.WITH_CLIENT:
        joinCall(conferenceName, false, true)
        break;
      
      default:
        break;
    }
  }

  if (!callState || callState.isRemoved) return null

  const openNote = () => {
    dispatch(openCallNote({
      callerNumber: callState.callerNumber,
      callRecordId: callState.callRecordId,
      conferenceName: callState.conferenceName,
      hipaaCompliant: callState.hipaaCompliant,
      name: callState.name,
      notes: callState.notes,
      viewMode: true,
    }))
  }

  return (
    <Paper
      sx={{
        width: 200,
        height: 98,
        p: 1,
        bgcolor: cardColor,
        borderRadius: '5px',
        cursor: 'pointer',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CallerInfo callState={callState} textColor={textColor} small />
      <CallNoteDisplay callState={callState} onClick={openNote} viewOnly>
        {(isListening && callState && [CallStateState.WITH_CALLER, CallStateState.WITH_CLIENT].includes(callState.state)) &&
          <Typography sx={{ fontSize: '14px', fontWeight: 600, color: '#666666', mr: '6px' }}>
            {callState.state === CallStateState.WITH_CALLER ? 'caller' : 'client'}
          </Typography>
        }
        {(isListening && callState && ActiveCall.includes(callState.state)) &&
          <VolumeUp sx={{ color: '#666666', fontSize: '20px' }} />
        }
        {(!isListening && callState?.conferenceJoinedBy !== loggedInUser?.userId) &&
          <Button
            sx={{
              p: '6px',
              width: 70,
              height: 24,
              fontSize: '12px',
              '&.Mui-disabled': { backgroundColor: '#b8b8b8' },
            }}
            onClick={onTakeCall}
            disabled={!callEnabled ||
              (callState?.state === CallStateState.TRANSFERRING && callState.callerLeftWarmTransfer) ||
              callState?.isProcessing ||
              (callState?.type === CallType.OUTBOUND && callState.state === CallStateState.UNANSWERED)
            }
          >
            Take Call
          </Button>
        }
      </CallNoteDisplay>
    </Paper>
  );
}

export default CallCardSmall