import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@mui/material';
import { closeSnackbar } from 'reducers/slices/UIReducer';
import { RootState } from 'reducers/store';

const GlobalSnackbar = () => {
  const dispatch = useDispatch()
  const { message, severity, isOpen } = useSelector((state: RootState) => state.ui.snackbar)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnackbar());
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ mt: '-12px' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalSnackbar