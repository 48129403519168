import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Collapse, Fade, IconButton, InputAdornment, ListItemButton, MenuItem, Paper, Popover, Select, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { ArrowBack, ArrowDropDown, ArrowDropUp, Close, KeyboardReturn, Search } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { setOpenAccountId, setSearchedContact } from 'reducers/slices/accountPanelReducer';
import { globalSearch } from 'api/globalsearch';
import debounce from 'awesome-debounce-promise';
import Highlighter from "react-highlight-words";

const MINI_THRESHOLD = 365
const SMALL_RESULTS = 4

const highlighterStyle = {
  color: 'inherit',
  backgroundColor: 'rgba(16, 51, 165, 0.1)',
}

const ItemName = styled(Typography)(() => ({
  fontSize: '14px',
  color: '#1033A5',
  fontWeight: 600,
}))

const ItemInfo = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#72767E',
  fontWeight: 600,
}))

const IconBox = styled(Box)(() => ({
  backgroundColor: '#F1F5FE',
  borderRadius: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const KeyTip = styled(Typography)(() => ({
  color: '#8694BF',
  fontSize: '14px',
  fontWeight: 400,
}))

interface MatchedField {
  name: string,
  value: string
}
interface SearchResult {
  id: string,
  name: string,
  matchedFields: MatchedField[],
}
export interface ContactResult extends SearchResult {
  accountId: string,
  accountName: string,
  firstName: string,
  lastName: string,
}
interface IProps {
  title: string,
  results: SearchResult[] | ContactResult[],
  itemRenderer: (item: SearchResult | ContactResult) => any,
  isShowingAll?: boolean,
  onShowAll: () => void,
  isCollapsed: boolean,
  setIsCollapsed: (isCollapsed: boolean) => void,
  focusId: string,
  onItemClick: (item: SearchResult | ContactResult) => void,
  isSearching: boolean,
}

const ResultsList: FC<IProps> = ({
  title, results, itemRenderer,
  isShowingAll, onShowAll,
  isCollapsed, setIsCollapsed,
  focusId, onItemClick,
  isSearching,
}) => {

  const resultsToShow = useMemo(() => {
    return isShowingAll ? results : results.slice(0, SMALL_RESULTS)
  }, [results, isShowingAll])

  return (
    <Box
      sx={{
        borderBottom: '1px solid #E3EBFC',
      }}
    >
      <ListItemButton
        onClick={() => !isShowingAll && setIsCollapsed(!isCollapsed)}
        id={`search-collapse-${title.toLowerCase()}`}
        sx={{
          '&:hover': { backgroundColor: '#F1F5FE' },
          backgroundColor: focusId === `search-collapse-${title.toLowerCase()}` ? '#F1F5FE' : undefined,
        }}
      >
        <Stack direction="row" spacing={1}>
          <Typography sx={{ fontSize: '14px', color: '#8694BF', fontWeight: 600 }}>{title}</Typography>
          <Typography
            sx={{
              fontSize: '14px',
              color: '#8694BF',
              fontWeight: 600,
              backgroundColor: '#E3EBFC',
              padding: '0px 4px',
            }}
          >
            {results.length}
          </Typography>
          {!isShowingAll &&
            <ArrowDropDown
              sx={{
                color: '#8694BF',
                rotate: isCollapsed ? '0deg' : '-180deg',
                transition: 'rotate 0.3s'
              }}
            />
          }
        </Stack>
      </ListItemButton>
      <Collapse in={!isCollapsed || isShowingAll}>
        {resultsToShow.map(item => (
          <ListItemButton
            key={item.id} dense
            id={`search-item-${title.toLowerCase()}-${item.id}`}
            onClick={() => onItemClick(item)}
            sx={{
              '&:hover': { backgroundColor: '#F1F5FE' },
              backgroundColor: focusId === `search-item-${title.toLowerCase()}-${item.id}` ? '#F1F5FE' : undefined
            }}
          >
            {itemRenderer(item)}
          </ListItemButton>
        ))}
        <Collapse in={isSearching} appear>
          <Box px={2} mb={1} width="calc(100% - 32px)">
            <Skeleton
              variant="rectangular"
              width="100%"
              height={60}
              sx={{ borderRadius: '5px' }}
            />
          </Box>
        </Collapse>
        {(resultsToShow.length < results.length && !isShowingAll) &&
          <ListItemButton
            onClick={onShowAll}
            id={`search-all-${title.toLowerCase()}`}
            sx={{
              '&:hover': { backgroundColor: '#F1F5FE' },
              backgroundColor: focusId === `search-all-${title.toLowerCase()}` ? '#F1F5FE' : undefined
            }}
          >
            <Typography sx={{ fontSize: '14px', color: '#2F54CF', fontWeight: 600 }}>See all results</Typography>
          </ListItemButton>
        }
      </Collapse>
    </Box>
  )
}

const debouncedSearch = debounce(globalSearch, 500)

const SearchBar = () => {
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const [resultsOpen, setResultsOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('everywhere')
  const [contacts, setContacts] = useState<ContactResult[]>([])
  const [companies, setCompanies] = useState<SearchResult[]>([])
  const [showingAll, setShowingAll] = useState<'contacts' | 'companies' | ''>('')
  const [isContactsCollapsed, setIsContactsCollapsed] = useState<boolean>(false)
  const [isCompaniesCollapsed, setIsCompaniesCollapsed] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState<number>(-1)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const dispatch = useDispatch()

  const boxWidth = anchorEl?.current?.getBoundingClientRect().width || 0
  const isMinimizedMode = boxWidth < MINI_THRESHOLD

  useEffect(() => {
    const typeMap: { [name: string]: string } = {
      everywhere: 'all',
      contacts: 'contact',
      companies: 'company',
    }
    if (search.length >= 2) {
      setIsSearching(true)
      debouncedSearch(typeMap[searchType], search).then((res) => {
        const contacts: ContactResult[] = (res.data.contacts || []).map((contact: ContactResult) => {
          const matchedName = contact.matchedFields.find(e => e.name === 'Name')
          if (matchedName && matchedName.value !== contact.name) {
            contact.matchedFields.unshift({ name: 'Alias', value: matchedName.value })
          }

          return contact
        })

        setContacts(contacts)
        setCompanies(res.data.accounts || [])
        setIsSearching(false)
      })
    }
  }, [search, searchType])

  // This should reflect the state of the UI
  // Helps us keep track of keyboard navigation
  const navigationMap = useMemo(() => {
    if (searchType === 'contacts') return contacts.map(item => `search-item-contacts-${item.id}`)
    if (searchType === 'companies') return contacts.map(item => `search-item-companies-${item.id}`)
    
    if (searchType === 'everywhere') {
      if (!showingAll) {
        let navigationMap = []
        navigationMap.push('search-collapse-companies')
        if (!isCompaniesCollapsed) {
          const smallCompanies = companies.slice(0, SMALL_RESULTS)
          navigationMap = navigationMap.concat(smallCompanies.map(item => `search-item-companies-${item.id}`))
          if (smallCompanies.length < companies.length) {
            navigationMap.push('search-all-companies')
          }
        }

        navigationMap.push('search-collapse-contacts')
        if (!isContactsCollapsed) {
          const smallContacts = contacts.slice(0, SMALL_RESULTS)
          navigationMap = navigationMap.concat(smallContacts.map(item => `search-item-contacts-${item.id}`))
          if (smallContacts.length < contacts.length) {
            navigationMap.push('search-all-contacts')
          }
        }
        return navigationMap
      }

      if (showingAll === 'contacts') {
        let navigationMap = []
        navigationMap.push('search-back')
        navigationMap = navigationMap.concat(contacts.map(item => `search-item-contacts-${item.id}`))
        return navigationMap
      }
      if (showingAll === 'companies') {
        let navigationMap = []
        navigationMap.push('search-back')
        navigationMap = navigationMap.concat(companies.map(item => `search-item-companies-${item.id}`))
        return navigationMap
      }
    }
    return []
  }, [searchType, contacts, companies, showingAll, isContactsCollapsed, isCompaniesCollapsed])

  const focusId = useMemo(() => {
    return navigationMap[tabIndex]
  }, [navigationMap, tabIndex])

  useEffect(() => {
    if (focusId) {
      const item = document.getElementById(focusId)
      if (item) {
        item.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [focusId])

  const handleNavigation = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'ArrowDown') {
      if (tabIndex < navigationMap.length - 1) setTabIndex(tabIndex + 1)
    }
    if (e.key === 'ArrowUp') {
      if (tabIndex > -1) setTabIndex(tabIndex - 1)
    }
    if (e.key === 'Enter') {
      if (focusId) {
        const item = document.getElementById(focusId)
        if (item) {
          item.click()
          if (focusId === 'search-back') {
            setTabIndex(0)
          }
          if (focusId.includes('search-all')) {
            setTabIndex(SMALL_RESULTS + 1)
          }
        }
      }
    }
  }

  const handleSearchClick = () => {
    if (search.length && !resultsOpen) {
      setResultsOpen(true)
    }
  }

  const handleChange = (e: any) => {
    setSearch(e.target.value)
    setResultsOpen(true)
  }

  const SearchBox = (onPopover = false) => (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '5px',
        borderBottomLeftRadius: onPopover ? 0 : '5px',
        borderBottomRightRadius: onPopover ? 0 : '5px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        px={1} py="2px"
        height={38 - 4}
      >
        {(!isMinimizedMode || onPopover) &&
          <Typography sx={{ fontWeight: 600, fontSize: '14px', mr: 1, minWidth: 46 }}>Search</Typography>
        }
        {(!isMinimizedMode || onPopover) &&
          <Select
            size="small"
            sx={{
              fontSize: '14px',
              backgroundColor: '#F9FBFF',
              borderColor: '#E3EBFC',
              minWidth: 120,
              mr: 1,
            }}
            SelectDisplayProps={{
              style: {
                padding: '4px 32px 4px 8px',
                fontSize: '14px',
              }
            }}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            onClick={handleSearchClick}
            onKeyDown={(e) => e.stopPropagation()}
            MenuProps={{
              onKeyDown: (e) => e.stopPropagation()
            }}
          >
            <MenuItem value="everywhere">Everywhere</MenuItem>
            <MenuItem value="companies">Companies</MenuItem>
            <MenuItem value="contacts">Contacts</MenuItem>
          </Select>
        }
        <TextField
          autoFocus={onPopover}
          autoComplete="off"
          style={{ backgroundColor: '#E4ECFC' }}
          value={search}
          fullWidth
          size="small"
          onChange={handleChange}
          onClick={handleSearchClick}
          placeholder={(!isMinimizedMode || onPopover) ? 'Type here' : 'Search anything'}
          inputProps={{
            style: { padding: '4px 0px 4px 8px' }
          }}
          InputProps={{
            sx: { fontSize: '14px', pr: '8px' },
            endAdornment: (
              <InputAdornment position="end">
                {!!search ?
                  <IconButton sx={{ p: 0 }} onClick={() => setSearch('')}>
                    <Close sx={{ color: '#A5B5E3', fontSize: '18px' }} />
                  </IconButton>
                  :
                  <Search sx={{ color: '#A5B5E3', fontSize: '18px' }} />
                }
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Paper>
  )

  const handleContactClick = (item: SearchResult | ContactResult) => {
    const contact = item as ContactResult
    dispatch(setOpenAccountId(contact.accountId))
    dispatch(setSearchedContact(contact))
    setResultsOpen(false)
  }

  const handleCompanyClick = (item: SearchResult | ContactResult) => {
    const account = item as SearchResult
    dispatch(setOpenAccountId(account.id))
    setResultsOpen(false)
  }

  // Show at most just 50 chars
  const formatMatchedValue = (value: string) => {
    const MAX_LENGTH = 80
    if (value.length <= MAX_LENGTH) return value

    let remainingLength = MAX_LENGTH
    let formatted = ''

    const searchIndex = value.toLowerCase().indexOf(search.toLowerCase())
    const frontPart = value.substring(0, searchIndex)
    const endPart = value.substring(searchIndex)

    const maxFrontLength = Math.floor(remainingLength / 2)
    if (frontPart.length <= maxFrontLength) {
      formatted += frontPart
      remainingLength -= frontPart.length
    } else {
      formatted += '...' + frontPart.substring(frontPart.length - maxFrontLength)
      remainingLength -= maxFrontLength
    }

    if (endPart.length <= remainingLength) {
      formatted += endPart.length
    } else {
      formatted += endPart.substring(-remainingLength) + '...'
    }

    return formatted
  }

  return (
    <>
      <Popover
        open={resultsOpen}
        anchorEl={anchorEl.current}
        onClose={() => setResultsOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'transparent',
            width: boxWidth,
            minWidth: isMinimizedMode ? boxWidth + 182 : boxWidth,
          }
        }}
        elevation={1}
        container={anchorEl.current}
        TransitionComponent={Fade}
        transitionDuration={{ enter: 0, exit: 500 }}
        marginThreshold={0}
        onKeyDown={handleNavigation}
        onMouseDown={() => setTabIndex(-1)}
        disableRestoreFocus
      >
        {SearchBox(true)}
        <Box bgcolor="white">

          <Collapse in={resultsOpen} appear timeout={300}>
            <Box
              sx={{
                borderTop: '1px solid #E3EBFC',
                maxHeight: 
                  'calc(100vh ' +
                  '- 68px ' + // App header
                  '- 24px ' + // Body margin top + bottom
                  '- 172px ' + // Call cards
                  '- 48px - 8px ' + // Greeting line + margin top
                  '- 38px - 8px ' + // Toolbar + margin top
                  '- 42px)', // Bottom nav area
                minHeight:
                  'calc(100vh - 68px - 24px - 172px - 48px - 8px - 38px - 8px - 42px)',
                overflowY: 'auto',
              }}
            >
              <Collapse in={!!showingAll && searchType === 'everywhere'}>
                <ListItemButton
                  dense
                  onClick={() => setShowingAll('')}
                  id="search-back"
                  sx={{
                    borderTop: '1px solid #E3EBFC',
                    '&:hover': { backgroundColor: '#F1F5FE' },
                    backgroundColor: focusId === 'search-back' ? '#F1F5FE' : undefined,
                  }}
                >
                  <Stack direction="row" spacing={1} py="4px" alignItems="center">
                    <ArrowBack sx={{ color: '#2F54CF' }} />
                    <Typography sx={{ fontWeight: 600, fontSize: '14px', color: '#2F54CF' }}>Back to full list</Typography>
                  </Stack>
                </ListItemButton>
              </Collapse>

              {(showingAll !== 'contacts' && searchType !== 'contacts') &&
                <ResultsList
                  title="Companies"
                  focusId={focusId}
                  results={companies}
                  isShowingAll={showingAll === 'companies' || searchType === 'companies'}
                  onShowAll={() => setShowingAll('companies')}
                  isCollapsed={isCompaniesCollapsed}
                  setIsCollapsed={setIsCompaniesCollapsed}
                  onItemClick={handleCompanyClick}
                  isSearching={isSearching}
                  itemRenderer={(item: SearchResult | ContactResult) => {
                    const account = item as SearchResult
                    return (
                      <Box>
                        <ItemName>
                          <Highlighter
                            searchWords={[search.toLowerCase()]}
                            autoEscape={true}
                            textToHighlight={account.name}
                            highlightStyle={highlighterStyle}
                          />
                        </ItemName>
                        {account.matchedFields.filter(e => e.name !== 'Name').map(match => (
                          <ItemInfo key={match.name}>
                            {match.name}:&nbsp;
                            <Highlighter
                              searchWords={[search.toLowerCase()]}
                              autoEscape={true}
                              textToHighlight={formatMatchedValue(match.value)}
                              highlightStyle={highlighterStyle}
                            />
                          </ItemInfo>
                        ))}
                      </Box>
                    )
                  }}
                />
              }
              {(showingAll !== 'companies' && searchType !== 'companies') &&
                <ResultsList
                  title="Contacts"
                  focusId={focusId}
                  results={contacts}
                  isShowingAll={showingAll === 'contacts' || searchType === 'contacts'}
                  onShowAll={() => setShowingAll('contacts')}
                  isCollapsed={isContactsCollapsed}
                  setIsCollapsed={setIsContactsCollapsed}
                  onItemClick={handleContactClick}
                  isSearching={isSearching}
                  itemRenderer={(item: SearchResult | ContactResult) => {
                    const contact = item as ContactResult
                    return (
                      <Box>
                        <ItemName>
                          <Highlighter
                            searchWords={[search.toLowerCase()]}
                            autoEscape={true}
                            textToHighlight={contact.name}
                            highlightStyle={highlighterStyle}
                          />
                        </ItemName>
                        <ItemInfo>
                          <Highlighter
                            searchWords={[search.toLowerCase()]}
                            autoEscape={true}
                            textToHighlight={contact.accountName}
                            highlightStyle={highlighterStyle}
                          />
                        </ItemInfo>
                        {contact.matchedFields
                          .filter(e => e.name !== 'Name')
                          .map(match => (
                            <ItemInfo key={match.name}>
                              {match.name}:&nbsp;
                              <Highlighter
                                searchWords={[search.toLowerCase()]}
                                autoEscape={true}
                                textToHighlight={formatMatchedValue(match.value)}
                                highlightStyle={highlighterStyle}
                              />
                            </ItemInfo>
                          ))
                        }
                      </Box>
                    )
                  }}
                />
              }
            </Box>

            <Stack
              direction="row"
              borderTop="1px solid #E3EBFC"
              py={1} pl={2}
            >
              <IconBox width={24} height={24} mr="4px"><ArrowDropUp sx={{ color: '#8694BF' }} /></IconBox>
              <IconBox width={24} height={24} mr={1}><ArrowDropDown sx={{ color: '#8694BF' }} /></IconBox>
              <KeyTip sx={{ mr: 2 }}>To navigate</KeyTip>
              <IconBox width={24} height={24} mr={1}><KeyboardReturn sx={{ color: '#8694BF', fontSize: '18px' }} /></IconBox>
              <KeyTip sx={{ mr: 2 }}>To select</KeyTip>
              <IconBox px="4px" height={24} mr={1}>
                <Typography sx={{ fontSize: '12px', color: '#8694BF', fontWeight: 600 }}>esc</Typography>
              </IconBox>
              <KeyTip sx={{ mr: 2 }}>To dismiss</KeyTip>
            </Stack>
          </Collapse>
        </Box>
      </Popover>

      <Box ref={anchorEl}>
        {SearchBox()}
      </Box>
    </>
  );
}

export default SearchBar;