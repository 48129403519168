import { createContext } from "react";

interface RingtoneInterface {
  setVolume: (value: number) => void,
}

export const RingtoneContext = createContext<RingtoneInterface>({
  // These values are not being used, just to keep typescript happy
  setVolume: (value) => {},
});
