import React, { useEffect, useMemo, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import GroupContainer from "./GroupContainer";
import { ACTIVITY_HEADER_HEIGHT } from "./ActivityHeader";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { CallStats } from "./ActivityView";
import { CallGroup, GROUP_ORDER_STORAGE } from "utils/activity";
import { logger } from "utils/utils";

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const GroupList = (props: { callStats: CallStats }) => {
  const [groupOrder, setGroupOrder] = useState<string[]>([])
  const groups = useSelector((state: RootState) => state.activityView.groups)
  const loggedInUser = useSelector((state: RootState) => state.user.user)

  useEffect(() => {
    let existingOrder = groupOrder.filter(groupId => groups.find(group => group.id === groupId))
    if (!existingOrder.length) {
      const localOrderString = localStorage.getItem(GROUP_ORDER_STORAGE + loggedInUser?.userId)
      if (localOrderString) {
        try {
          existingOrder = JSON.parse(localOrderString)
        } catch (err) {
          logger('An error occured when parsing JSON string',  localOrderString)
        }
      }
    }

    const unorderedGroups = groups.filter(group => !existingOrder.includes(group.id)).map(e => e.id)
    const newOrder = existingOrder.concat(unorderedGroups)

    setGroupOrder(newOrder)
    localStorage.setItem(GROUP_ORDER_STORAGE + loggedInUser?.userId, JSON.stringify(newOrder))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups])

  const getGroupTotalCall = (teamId: string) => {
    const stat = props.callStats.teams.find(e => e.teamId === teamId)
    return stat?.callsPerDay || 0
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    const newOrder = reorder(
      groupOrder,
      result.source.index,
      result.destination.index
    )

    setGroupOrder(newOrder)
    localStorage.setItem(GROUP_ORDER_STORAGE + loggedInUser?.userId, JSON.stringify(newOrder))
  }

  const orderedGroups = useMemo(() => {
    const orderedGroups: CallGroup[] = []
    groupOrder.forEach(groupId => {
      const group = groups.find(e => e.id === groupId)
      if (group) orderedGroups.push(group)
    })

    return orderedGroups
  }, [groupOrder, groups])

  return (
    <Box
      sx={{
        width: 'calc(100% - 40px)',
        height: `calc(100% - ${ACTIVITY_HEADER_HEIGHT}px - 40px)`,
        p: '20px',
        overflow: 'auto',
      }}
    >
      {!groups.length &&
        [...new Array(3)].map((e, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            sx={{
              width: '100%', height: 60,
              mb: 1, borderRadius: '5px',
            }}
          />
        ))
      }
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {orderedGroups.map((group, index) => (
                <Draggable key={group.name} draggableId={group.name} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <GroupContainer
                        group={group}
                        totalCalls={getGroupTotalCall(group.id)}
                        dragHandleProps={provided.dragHandleProps}
                      />
                      {/* Use this instead of bottom margin so it looks nice on dnd */}
                      <Box width="100%" height={8}></Box>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default GroupList;
