import { Close } from "@mui/icons-material";
import {
  Box, Dialog, IconButton, 
  Typography
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setIsSettingsOpen } from "reducers/slices/UIReducer";
import RingtoneSetting from "./RingtoneSetting";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import AudioIOSetting from "./AudioIOSetting";
import TwilioEdgeLocationSetting from "./TwilioEdgeLocationSetting";
import TwilioDelaySetting from "./TwilioDelaySetting";

const SettingsDialog = () => {
  const open = useSelector((state: RootState) => state.ui.isSettingsOpen)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setIsSettingsOpen(false))
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 14,
          top: 12,
        }}
      >
        <Close sx={{ fontSize: '32px' }} />
      </IconButton>
      <Box p={4} width={450}>
        <Typography mt={2} sx={{ fontSize: '26px', fontWeight: 600, textAlign: 'center' }}>Settings</Typography>
        <Typography mt={2} sx={{ fontSize: '16px', fontWeight: 600 }}>Audio Settings</Typography>
        <AudioIOSetting open={open} />
        <RingtoneSetting open={open} />

        <Typography mt={4} sx={{ fontSize: '16px', fontWeight: 600 }}>Twilio Settings</Typography>
        <TwilioEdgeLocationSetting />
        <TwilioDelaySetting />
      </Box>
    </Dialog>
  );
};

export default SettingsDialog;
