import React, { useLayoutEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import GreetingLine from "./GreetingLine";
import CallToolbar from "./CallToolbar";
import CallSlot from "./CallSlot";
import AccountLayout from "../account/AccountLayout";
import { useAppSelector } from "reducers/hooks";
import CallDisabledBar from "./CallDisabledBar";

// Receptionist view
const ReceptionistView = () => {
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const [maxCards, setMaxCards] = useState(8);

  useLayoutEffect(() => {
    const getMaxCards = () => {
      const wrapper = document.getElementById('cardsWrapper')
      const width = wrapper?.clientWidth || 0

      // +8 since last card has no margin right 8px
      const maxCards = Math.floor((width + 8) / 238)
      setMaxCards(maxCards)
    }

    // There is some complexity involved with letting maxCards gets updated on resize
    // So for now we should not handle resize
    // window.addEventListener('resize', getMaxCards)
    getMaxCards()

    // return () => window.removeEventListener('resize', getMaxCards)
  }, [])

  return (
    <Box position="relative" p="12px" height={`calc(100% - 24px - 4px)`}>
      <Box id="cardsWrapper" display="flex">
        {callEnabled && [...Array(maxCards)].map((e, index) => (
          <Box
            key={index}
            sx={{
              mr: 1,
              '&:last-child': { mr: 0 }
            }}
          >
            <CallSlot
              name={`call` + (index + 1)}
              userId="receptionist"
            />
          </Box>
        ))}
      </Box>
      {!callEnabled &&
        <Box>
          <CallDisabledBar />
        </Box>
      }

      <Box mt={1}>
        <GreetingLine />
      </Box>

      {callEnabled &&
        <Box mt={1}>
          <CallToolbar />
        </Box>
      }

      <Box mt={1}></Box>
      <AccountLayout />

      <Typography
        sx={{
          position: 'absolute',
          bottom: -2,
          right: 16,
          fontSize: '10px',
          lineHeight: '10px',
          color: 'black',
          textAlign: 'right',
        }}
      >
        v{process.env.REACT_APP_VERSION || '1.0.0'}
      </Typography>
    </Box >
  );
};

export default ReceptionistView;
