import { Box, CircularProgress, Dialog, Paper, Typography } from "@mui/material";
import logo from '../assets/logo.svg';
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";

const SoftLoading = () => {
  const softTwilioLoading = useSelector((state: RootState) => state.ui.softTwilioLoading);

  return (
    <Dialog open={softTwilioLoading}>
      <Paper elevation={5}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 6,
            py: 8,
            width: 350,
          }}
        >
          <Box mb={8}>
            <img width="175" height="85" src={logo} alt="Logo" />
          </Box>
          <CircularProgress sx={{ color: '#1033A5' }} />
          <Typography sx={{ mt: 4, mb: '67px' }} style={{ color: '#1033A5', fontSize: '14px', fontWeight: 600 }}>Connecting to Twilio, please wait...</Typography>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default SoftLoading;
