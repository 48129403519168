import { Box, Paper, Stack, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom";

const FormSubmitted = () => {
  const [searchParams] = useSearchParams();

  const caller = searchParams.get('caller') || ''
  const accountName = searchParams.get('accountName') || ''
  return (
    <Box width="100%" height="100vh">
      <Box
        sx={{
          width: 'calc(100% - 20%)',
          minHeight: 'calc(100% - 64px)',
          backgroundColor: 'rgb(237, 242, 247)',
          px: '10%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 4,
        }}
      >
        <Paper sx={{ width: '100%', minWidth: 1100, maxWidth: 1200 }}>
          <Stack sx={{ p: 10 }} alignItems="center">
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>Form was successfully submitted!</Typography>
            <Typography sx={{ mt: 4 }}>Caller: {caller}</Typography>
            <Typography sx={{ mt: 2 }}>Account: {accountName}</Typography>
          </Stack>
        </Paper>
      </Box>
    </Box>
  )
}

export default FormSubmitted;
