import { Fade, Popover, PopoverProps } from "@mui/material"

export const CustomPopover = (props: PopoverProps) => {
  return (
    <Popover
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      elevation={1}
      TransitionComponent={Fade}
      transitionDuration={{ enter: 0, exit: 500 }}
      {...props}
      PaperProps={{
        ...props?.PaperProps,
        sx: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: 'transparent',
          width: 275,
          ...props?.PaperProps?.sx,
        },
      }}
    >
      {props.children}
    </Popover>
  )
}
