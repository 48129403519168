import agent, { ccAgent } from './agent'

export function getAllUsers() {
  return ccAgent.get('/user/list', { params: { limit: 1000 } })
}

export function searchUsers(search: string, limit: number = 1000, all = false) {
  return ccAgent.get('/user/list', { params: { user: search, limit, all } })
}

export function getUserDetails(userId: string) {
  return agent.get('/user/' + userId)
}

export function changeAvatar(formData: FormData) {
  return agent.post('/user/avatar', formData)
}

export function deleteAvatar() {
  return agent.delete('/user/avatar')
}

export function changePassword(oldPassword: string, newPassword: string) {
  return agent.post('/user/change-password', { oldPassword, newPassword })
}
