import { ccAgent } from "./agent"


export function getUnansweredConferences() {
  return ccAgent.get('/conferences/unanswered')
}

export function getActiveConferences() {
  return ccAgent.get('/conferences')
}

export function getUserConferences() {
  return ccAgent.get('/conferences/user')
}
