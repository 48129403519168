import io, { Socket } from "socket.io-client";
import { logger } from "utils/utils";

export const initSocket = () => {
  const token = localStorage.getItem("token");
  return io(
    `${process.env.REACT_APP_CC_API_SOCKET}`,
    {
      auth: {
        token,
      },
      transports: ["websocket"],
      path: "/api/v1/websocket",
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      timeout: 5 * 1000,
    }
  )
}

export const subscribeToEvent = (socket: Socket, eventName: string, callback: Function) => {
  socket.on(eventName, (...args: any[]) => {
    logger(eventName, ...args)
    callback(...args);
  })
};
