import { useEffect, useState } from "react"
import { Socket } from "socket.io-client"
import { initSocket } from "utils/socket/socket"
import { SocketContext } from "./socket-context"
import { useDispatch, useSelector } from "react-redux"
import { setNoConnection } from "reducers/slices/UIReducer"
import { RootState } from "reducers/store"
import { logger } from "utils/utils"

export const SocketProvider = (props: any) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.userId) {
      setupSocket()
    } else {
      handleLogout()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId])

  const setupSocket = () => {
    if (socket) {
      socket.io.removeAllListeners()
      socket.disconnect()
    }

    logger('Subscribing to socket events');
    const newSocket = initSocket()

    newSocket.on('connect', () => {
      // CCAA-1155 No connection will be updated after we fetch calls
      // dispatch(setNoConnection(false))
      logger('connected to socket')
    });

    newSocket.on('connect_error', () => {
      logger('socket connection error')
    });

    newSocket.on('disconnect', (reason: string) => {
      dispatch(setNoConnection(true))
      logger('disconnected from socket, reason: ' + reason)
    });

    const events = [
      'close',
      'error',
      'open',
      'packet',
      'ping',
      'reconnect',
      'reconnect_attempt',
      'reconnect_error',
      'reconnect_failed',
    ]

    events.forEach(ev => {
      newSocket.io.on(ev as any, () => {
        console.log(`socket '${ev}' event`)
      }) 
    })
    setSocket(newSocket)
  }

  const handleLogout = () => {
    if (socket) socket.disconnect()
    // Redux states are cleared as well, check src/redux/store/index.ts
  }

  return (
    <SocketContext.Provider value={{ socket }}>
      {props.children}
    </SocketContext.Provider>
  )
}