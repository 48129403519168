import React, { FC, useEffect, useState } from "react";
import * as yup from 'yup';
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Dialog, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import parseYupErrors from "utils/parseYupErrors";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import AnimatedButton from "components/buttons/AnimatedButton";
import { useDispatch } from "react-redux";
import { changePassword } from "api/users";

const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required().label('Old Password'),
  newPassword: yup.string().required().label('New Password')
    .test('valid-password', 'Password must have at least 8 characters, one capital letter, one small letter, one number and one special character',
      (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(value || '')
    ),
  confirmPassword: yup.string().required().label('Confirm Password')
    .test('same-password', 'Confirm Password and New Password do not match',
      (value, testContext) => value === testContext.parent.newPassword
    )
})

interface ChangePasswordForm {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
}

const defaultForm: ChangePasswordForm = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

interface FieldProps {
  form: ChangePasswordForm,
  errors: ChangePasswordForm,
  handleChange: any,
  name: 'oldPassword' | 'newPassword' | 'confirmPassword',
  placeholder: string,
}

const PasswordField: FC<FieldProps> = ({ form, errors, handleChange, name, placeholder }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <TextField
      sx={{ mt: 3 }}
      placeholder={placeholder}
      fullWidth
      size="small"
      value={form[name]}
      name={name}
      onChange={handleChange(name)}
      type={showPassword ? 'text' : 'password'}
      error={!!errors[name]}
      helperText={errors[name] || ''}
      InputProps={{
        sx: { bgcolor: '#E4ECFC' },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              sx={{ color: '#A5B5E3' }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

interface IProps {
  open: boolean,
  onClose: any,
}

const ChangePasswordDialog: FC<IProps> = ({ open, onClose }) => {
  const [form, setForm] = useState<ChangePasswordForm>({ ...defaultForm })
  const [errors, setErrors] = useState<ChangePasswordForm>({ ...defaultForm })
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setErrors({ ...defaultForm })
    setForm({ ...defaultForm })
  }, [open])

  const handleClose = () => {
    if (!loading) {
      onClose()
    }
  }

  const handleChange = (name: keyof ChangePasswordForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [name]: event.target.value,
    })
    setErrors({ ...defaultForm })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    setErrors({ ...defaultForm })
    setLoading(true)
    changePasswordSchema
      .validate(form, { abortEarly: false })
      .then(() => {
        changePassword(form.oldPassword, form.newPassword)
          .then((res) => {
            dispatch(setSnackbar({ message: 'Password was successfully changed!', severity: SnackbarSeverity.SUCCESS }))
            onClose()
          })
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((err: yup.ValidationError) => {
        setErrors(parseYupErrors(err))
        setLoading(false)
      })
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 6,
            py: 8,
            width: 350,
            position: 'relative'
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
            }}
            onClick={handleClose}
            disabled={loading}
          >
            <Close />
          </IconButton>

          <Typography style={{ fontWeight: 600, fontSize: '28px', lineHeight: '50px' }}>Change Password</Typography>
          <Typography align="center" sx={{ my: 1, width: 260, fontSize: '12px', lineHeight: '20px', fontWeight: 600, opacity: 0.6 }}>
            Your new password must be different from previous used passwords.
          </Typography>

          <PasswordField
            form={form}
            errors={errors}
            handleChange={handleChange}
            name="oldPassword"
            placeholder="Old Password"
          />

          <PasswordField
            form={form}
            errors={errors}
            handleChange={handleChange}
            name="newPassword"
            placeholder="New Password"
          />

          <PasswordField
            form={form}
            errors={errors}
            handleChange={handleChange}
            name="confirmPassword"
            placeholder="Confirm Password"
          />

          <AnimatedButton
            sx={{ mt: 6 }}
            type="submit"
            label="Change Password"
            loading={loading}
            disabled={loading}
          />
        </Box>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialog;
