import {useContext, useEffect, useState} from "react";
import {SocketContext} from "../context/socket-context";
import {Socket} from "socket.io-client";

const useSocket = () => {
    const { socket } = useContext(SocketContext) || {};
    const [readySocket, setReadySocket] = useState<Socket | null>(null);

    useEffect(() => {
        if (socket) {
            if (socket.connected) {
                setReadySocket(socket);
            } else {
                socket.once("connect", () => {
                    setReadySocket(socket);
                });
            }
        }
    }, [socket]);

    return readySocket;
};

export default useSocket