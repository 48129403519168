import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Box, Collapse, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import SideInfo from "./components/SideInfo";
import { Close, KeyboardArrowUp, Person, Phone, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { highlightHtml } from "utils/utils";
import { HOLIDAY_HOURS_OF_OPERATION_LABEL, HOURS_DAYS_OF_OPERATION_LABEL } from 'utils/accounts';
import AccountCallerHistory from "./components/AccountCallerHistory";
import { AppThunkDispatch } from "reducers/types";
import { useDispatch } from "react-redux";
import { handleSelectedCallLink } from "reducers/thunks/accountThunk";

const SCRIPTS_GUIDES_ID = 'scriptsAndCallGuides'

interface IProps {
  showCustomHours: () => void,
  showCustomHolidayHours: () => void,
  searchOverride: string,
  clearSearchOverride: () => void,
}

const Account: FC<IProps> = ({ showCustomHours, showCustomHolidayHours, searchOverride, clearSearchOverride }) => {
  const [search, setSearch] = useState<string>('')
  const [showInfo, setShowInfo] = useState<boolean>(true)
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const selectedContact = useSelector((state: RootState) => state.accountPanel.selectedContact)
  const thunkDispatch = useDispatch() as AppThunkDispatch

  useEffect(() => {
    if (searchOverride) {
      setSearch(searchOverride)
      clearSearchOverride()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOverride])

  useLayoutEffect(() => {
    if ([HOLIDAY_HOURS_OF_OPERATION_LABEL, HOURS_DAYS_OF_OPERATION_LABEL].includes(searchOverride)) {
      const hoursFaq = document.getElementById('accountFaqs')
      if (hoursFaq) {
        hoursFaq.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
    }
  }, [searchOverride])

  useLayoutEffect(() => {
    const handleAnchorClick = (e: MouseEvent) => {
      const el = e.target as HTMLElement
      if (el) {
        let tagEl: HTMLElement = el
        let limit = 0 // Just in case we get into infinite loop somehow

        // Go through parents until we found anchor
        while (tagEl.id !== SCRIPTS_GUIDES_ID && limit < 10) {
          if (tagEl.tagName === 'A') {
            break;
          }

          if (!tagEl.parentElement) {
            break;
          }

          tagEl = tagEl.parentElement
          limit++
        }

        if (tagEl.tagName === 'A') {
          e.preventDefault()
          e.stopPropagation()

          // @ts-ignore
          thunkDispatch(handleSelectedCallLink({ name: el.textContent, link: tagEl.attributes.href?.textContent }))
        }
      }
    }

    const el = document.getElementById(SCRIPTS_GUIDES_ID)
    if (el) {
      el.addEventListener('click', handleAnchorClick)
    }

    return () => {
      const el = document.getElementById(SCRIPTS_GUIDES_ID)
      if (el) {
        el.removeEventListener('click', handleAnchorClick)
      }
    }
  }, [account.id, thunkDispatch])

  useEffect(() => {
    setShowInfo(true)
    setSearch('')
  }, [account.id])

  const filteredFaqs = useMemo(() => {
    if (!account.faqs) return []

    const faqs = account.faqs.slice()
    if (account.customHoursDaysOfOperation2) {
      faqs.unshift({
        id: 'customHoursDaysOfOperation2',
        question: HOURS_DAYS_OF_OPERATION_LABEL,
        answer: account.customHoursDaysOfOperation2,
      })
    }

    if (account.customHolidayHoursOfOperation) {
      faqs.unshift({
        id: 'customHolidayHoursOfOperation',
        question: HOLIDAY_HOURS_OF_OPERATION_LABEL,
        answer: account.customHolidayHoursOfOperation,
      })
    }

    const regEscape = (v: string) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

    return faqs.filter(faq => {
        if ([HOLIDAY_HOURS_OF_OPERATION_LABEL, HOURS_DAYS_OF_OPERATION_LABEL].includes(search)) {
          // Force exact match for hours of operation view
          return faq.question === search
        } else {
          // When using the test method with the /g (global) flag, the regex maintains an internal state between calls.
          // Calling test multiple times in a loop, the state is updated, potentially skipping matches.
          // For that reason we recreate searchKey each time.
          const searchKey = new RegExp('(?<!<[^>]*)' + regEscape(search), 'ig');
          return (faq.question && searchKey.test(faq.question)) ||
          (faq.answer && searchKey.test(faq.answer))
        }
      }
    )
  }, [account.customHoursDaysOfOperation2, account.customHolidayHoursOfOperation, account.faqs, search])

  return (
    <Box display="flex" height="calc(100% - 36px)">
      <Box
        sx={{
          mt: 3, pr: 2, mr: 1,
          width: '320px',
          height: 'calc(100% - 24px)',
          overflowY: 'auto',
        }}
      >
        <AccountCallerHistory />
        <SideInfo showCustomHours={showCustomHours} showCustomHolidayHours={showCustomHolidayHours} />
      </Box>
      <Box
        sx={{
          width: 'calc(100% - 8px - 320px)',
          mt: 3, mb: 1, pr: 1,
          height: 'calc(100% - 24px - 8px)',
          overflowY: 'auto',
        }}
      >
        {account.commonMistakes &&
          <Box
            sx={{
              mb: 2, py: 2, px: 3,
              backgroundColor: '#F5F8FD',
            }}
          >
            <Typography style={{ fontSize: '24px', fontWeight: 600 }}>Bolo Instructions</Typography>
            <Typography style={{ fontSize: '14px' }} component="div">
              <div dangerouslySetInnerHTML={{ __html: `<mark><b>${account.commonMistakes}</b></mark>` }}></div>
            </Typography>
          </Box>
        }

        {(account.scriptsAndCallGuides || selectedContact.otherInfoInEVO) &&
          <Box
            sx={{
              py: 2, px: 3,
              backgroundColor: '#F5F8FD',
            }}
          >
            <Typography style={{ fontSize: '24px', fontWeight: 600 }}>Scripts and Call Guides</Typography>
            <Collapse in={!!(selectedContact.accountId === account.id && selectedContact.otherInfoInEVO)}>
              <Stack my={1} direction="row" spacing={1} alignItems="center">
                <Phone sx={{ color: '#1033A5', width: 20, height: 20 }} />
                <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>Contact Call Guide</Typography>
              </Stack>
              <Typography style={{ fontSize: '14px' }} component="div">
                <div dangerouslySetInnerHTML={{ __html: selectedContact.otherInfoInEVO || '' }}></div>
              </Typography>
            </Collapse>
            <Stack my={1} direction="row" spacing={1} alignItems="center">
              <Person sx={{ color: '#1033A5', width: 20, height: 20 }} />
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>Account Call Guide</Typography>
            </Stack>
            <Typography style={{ fontSize: '14px' }} component="div">
              <div id={SCRIPTS_GUIDES_ID} dangerouslySetInnerHTML={{ __html: account.scriptsAndCallGuides || 'N/A' }}></div>
            </Typography>
          </Box>
        }

        <Box
          id="accountFaqs"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 2, py: 2, px: 3,
            height: 70 - 32,
            backgroundColor: '#F5F8FD',
          }}
        >
          <Typography style={{ fontSize: '24px', fontWeight: 600 }}>FAQs</Typography>
          <Typography sx={{ ml: 'auto', fontSize: '16px', fontWeight: 600 }}>Search</Typography>
          <TextField
            sx={{ ml: 2, backgroundColor: '#E4ECFC', width: '40%' }}
            value={search}
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by FAQs"
            InputProps={{
              style: { fontSize: '14px' },
              endAdornment: (
                <InputAdornment position="end">
                  {!!search ?
                    <IconButton sx={{ p: 0 }} onClick={() => setSearch('')}>
                      <Close sx={{ color: '#A5B5E3' }} />
                    </IconButton>
                    :
                    <Search sx={{ color: '#A5B5E3' }} />
                  }
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Box
          sx={{
            pb: 2, px: 3,
            overflow: 'auto',
            backgroundColor: '#F5F8FD',
            transition: 'height 0.3s',
          }}
        >
          {!filteredFaqs.length &&
            <Box display="flex" alignItems="center" justifyContent="center" height="80%">
              <Typography style={{ fontSize: '24px', fontWeight: 600 }}>
                {account.faqs.length ? 'No FAQs found' : 'No FAQs'}
              </Typography>
            </Box>
          }
          {filteredFaqs.map(faq => (
            <Box
              key={faq.id}
              sx={{ mb: 2, '&:last-child': { mb: 0 } }}
            >
              <Typography style={{ fontWeight: 600, fontSize: '14px' }} component="div">
                <div dangerouslySetInnerHTML={{ __html: highlightHtml(faq.question, search) }}></div>
              </Typography>
              <Typography style={{ fontSize: '14px' }} component="div">
                <div dangerouslySetInnerHTML={{ __html: highlightHtml(faq.answer, search) }}></div>
              </Typography>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            py: 2, px: 3, mt: 2,
            backgroundColor: '#F5F8FD',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => setShowInfo(!showInfo)}
          >
            <Typography style={{ fontSize: '24px', fontWeight: 600 }}>What does your organization do?</Typography>
            <IconButton sx={{ p: 0 }}>
              <KeyboardArrowUp
                sx={{
                  fontSize: '32px',
                  transform: !showInfo ? 'rotate(180deg)' : '',
                  transition: 'transform 0.4s',
                }}
              />
            </IconButton>
          </Box>
          <Collapse in={showInfo} timeout={300}>
            <Typography style={{ fontSize: '14px' }} component="div">
              <div dangerouslySetInnerHTML={{ __html: account.whatDoesYourOrganizationDo || '' }}></div>
            </Typography>
          </Collapse>
        </Box>

      </Box>
    </Box>
  );
};

export default Account;
