import { useMemo, useRef, useState } from 'react';
import { Box, Button, Collapse, IconButton, InputAdornment, ListItem, ListItemButton, Skeleton, TextField, Typography } from '@mui/material';
import { Close, PhoneCallback, Search } from '@mui/icons-material';
import { CallStateState, CallType } from 'utils/calls';
import useCallFunctions from 'hooks/useCallFunctions';
import { CustomPopover } from 'components/CustomPopover';
import { searchUsers } from 'api/users';
import { useDispatch } from 'react-redux';
import { setAndGroupUsers } from 'reducers/slices/activityViewReducer';
import Highlighter from 'react-highlight-words';
import { useAppSelector } from 'reducers/hooks';
import { selectedCallSelector } from 'reducers/selector/callSelector';
import useThrottledAction from 'hooks/useThrottledAction';

export const CallRequeue = () => {
  const anchorEl = useRef(null)
  const [showOptions, setShowOptions] = useState(false)
  const [receptionistDropdownOpen, setReceptionistDropdownOpen] = useState(false)
  const [receptionistSearch, setReceptionistSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const receptionists = useAppSelector(state => state.activityView.allUsers)
  const loggedInUser = useAppSelector(state => state.user.user);
  const selectedCall = useAppSelector(selectedCallSelector)
  const { requeueCall } = useCallFunctions()
  const isThrottled = useThrottledAction()
  const dispatch = useDispatch()

  const onSpecificReceptionist = () => {
    setShowOptions(false)
    setReceptionistDropdownOpen(true)

    setIsLoading(true)
    searchUsers('', 1000, true).then((res: any) => {
      dispatch(setAndGroupUsers(res.data))
      setIsLoading(false)
    })
  }

  const onRequeueCall = (receptionistId?: string) => () => {
    if (!selectedCall) return
    if (isThrottled()) return
    requeueCall(selectedCall.conferenceName, receptionistId)
    setShowOptions(false)
    setReceptionistDropdownOpen(false)
  }

  const filteredReceptionists = useMemo(() => {
    const search = receptionistSearch.toLowerCase()

    return Object.values(receptionists).filter(e => (
      e.isOnline && 
      e.id !== loggedInUser?.userId && (
        e.name?.toLowerCase().indexOf(search) !== -1 ||
        e.email?.toLowerCase().indexOf(search) !== -1 ||
        e.teamName?.toLowerCase().indexOf(search) !== -1 ||
        e.role?.toLowerCase().indexOf(search) !== -1
      )
    ))
  }, [receptionists, receptionistSearch, loggedInUser?.userId])

  const isProcessing = selectedCall?.isProcessing || selectedCall?.state === CallStateState.TRANSFERRING
  return (
    <>
      <CustomPopover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ sx: { width: 229, borderTopRightRadius: '5px' } }}
        anchorEl={anchorEl.current}
        container={anchorEl.current}
        open={showOptions}
        onClose={() => setShowOptions(false)}
      >
        <Collapse in={showOptions} appear timeout={300}>
          <Box bgcolor="#FFF">
            <ListItemButton
              onClick={onRequeueCall()}
              sx={{
                display: 'block',
                px: 2, py: 1,
                cursor: 'pointer',
              }}
            >
              <Typography>To any receptionists</Typography>
            </ListItemButton>
            <ListItemButton
              onClick={onSpecificReceptionist}
              sx={{
                display: 'block',
                px: 2, py: 1,
                cursor: 'pointer',
              }}
            >
              <Typography>To specific receptionist</Typography>
            </ListItemButton>
          </Box>
        </Collapse>
      </CustomPopover>

      <CustomPopover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{ sx: { width: 229, borderTopRightRadius: '5px' } }}
        anchorEl={anchorEl.current}
        container={anchorEl.current}
        open={receptionistDropdownOpen}
        onClose={() => setReceptionistDropdownOpen(false)}
      >
        <Collapse in={receptionistDropdownOpen} appear timeout={300}>
          <Box
            sx={{
              borderTop: '1px solid #E3EBFC',
              height: 450,
              backgroundColor: '#FFF',
            }}
          >
            <Box
              sx={{
                width: '100%',
                pt: 2, pb: '4px',
                height: 'calc(100% - 16px - 4px)',
              }}
            >
              <Box px="10px" pb={1} width="calc(100% - 20px)" height={46}>
                <TextField
                  fullWidth
                  style={{ backgroundColor: 'white' }}
                  value={receptionistSearch}
                  size="small"
                  onChange={(e) => setReceptionistSearch(e.target.value)}
                  placeholder="Search Receptionist"
                  InputProps={{
                    style: { fontSize: '14px', paddingLeft: '8px', paddingRight: 6 },
                    startAdornment: (
                      <InputAdornment position="start" sx={{ mr: '4px' }}>
                        <Search sx={{ color: '#A5B5E3' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {!!receptionistSearch &&
                          <IconButton sx={{ p: '2px' }} onClick={() => setReceptionistSearch('')}>
                            <Close sx={{ color: '#A5B5E3' }} />
                          </IconButton>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: '100%',
                  height: 'calc(100% - 54px)',
                  overflowY: 'auto',
                }}
              >
                {(!isLoading && !filteredReceptionists.length) &&
                  <ListItem>
                    <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                      There are no receptionists found.
                    </Typography>
                  </ListItem>
                }
                {!isLoading && filteredReceptionists.map(receptionist => (
                  <ListItemButton
                    key={receptionist.id}
                    onClick={onRequeueCall(receptionist.id)}
                    sx={{
                      display: 'block',
                      px: 2, py: 1,
                      cursor: 'pointer',
                      backgroundColor: '#F8FBFE',
                    }}
                  >
                    <Typography style={{ fontWeight: 600, fontSize: '14px', color: '#1033A5' }}>
                      <Highlighter
                        searchWords={[receptionistSearch.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={receptionist.name}
                      />
                    </Typography>
                    <Typography sx={{ fontSize: '13px', mt: '4px' }}>
                      <Highlighter
                        searchWords={[receptionistSearch.toLowerCase()]}
                        autoEscape={true}
                        textToHighlight={`${
                          receptionist.teamName ||
                          (receptionist.userBlock ?
                            `Block ${receptionist.userBlock}` : 'No team')
                          }, ${receptionist.role}`}
                      />
                    </Typography>
                  </ListItemButton>
                ))}
                {isLoading &&
                  [...new Array(6)].map((e, i) => (
                    <Box pb="4px" key={i}>
                      <Skeleton width="100%" height={56.5} variant="rectangular" />
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Collapse>
      </CustomPopover>

      <Box ref={anchorEl}>
        <Button
          sx={{
            mr: '4px',
            width: 116,
            minWidth: 116,
          }}
          startIcon={<PhoneCallback sx={{ color: '#6A3686' }} />}
          onClick={() => setShowOptions(true)}
          disabled={
            !selectedCall ||
            selectedCall.state !== CallStateState.ANSWERED ||
            isProcessing ||
            selectedCall.type === CallType.UNBOUND
          }
        >
          Requeue
        </Button>
      </Box>
    </>
  );
}

export default CallRequeue;