import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactResult } from "pages/main/receptionist/SearchBar";
import { Account, ACCOUNT_PANEL_STATUS, Contact, PanelTask, TASKS_STORAGE } from "utils/accounts";

type UpdateContactPayload = {
  id: string,
  update: Partial<Contact>,
}

export interface accountPanel {
  activeAccount: Account,
  status: ACCOUNT_PANEL_STATUS,
  tasks: PanelTask[],
  selectedTask: string,
  searchedContact: ContactResult | null,
  selectedContact: Contact,

  // Trigger useAccountsFetcher to fetch specific account
  openAccountId: string,
}

const initialState: accountPanel = {
  // @ts-ignore
  activeAccount: {},
  status: ACCOUNT_PANEL_STATUS.FIRST_LOAD,
  tasks: [],
  selectedTask: '',
  searchedContact: null,

  // @ts-ignore
  selectedContact: {},

  openAccountId: '',
}

export const accountPanelSlice = createSlice({
  name: "accountPanel",
  initialState,
  reducers: {
    setActiveAccount: (state, action: PayloadAction<Account>) => {
      const account = action.payload
      state.activeAccount = account
    },
    setAccountPanelStatus: (state, action: PayloadAction<ACCOUNT_PANEL_STATUS>) => {
      state.status = action.payload
    },
    setTask: (state, action: PayloadAction<PanelTask[]>) => {
      return {
        ...state,
        tasks: action.payload,
      }
    },
    addTask: (state, action: PayloadAction<PanelTask>) => {
      const tasks = state.tasks.slice()
      let taskName = action.payload.name

      const duplicateTask = tasks.find(e => e.name === taskName)
      // Only push duplicate task if it's link and the link is different
      if (duplicateTask) {
        if (action.payload.callSid && duplicateTask.callSid !== action.payload.callSid) {
          let index = 2
          let newTaskName = `${taskName} (${index})`
          // eslint-disable-next-line no-loop-func
          while (tasks.find(e => e.name === newTaskName)) {
            index += 1
            newTaskName = `${taskName} (${index})`
          }
          taskName = newTaskName
          tasks.push({
            ...action.payload,
            name: taskName,
          })
        }
      } else {
        tasks.push(action.payload)
      }

      localStorage.setItem(TASKS_STORAGE, JSON.stringify(tasks))
      return {
        ...state,
        tasks,
        selectedTask: taskName,
      }
    },
    removeTask: (state, action: PayloadAction<string>) => {
      const taskName = action.payload
      const tasks = state.tasks.slice().filter(task => task.name !== taskName)

      localStorage.setItem(TASKS_STORAGE, JSON.stringify(tasks))
      return {
        ...state,
        tasks,
        selectedTask: state.selectedTask === taskName ? '' : state.selectedTask,
      }
    },
    setSelectedTask: (state, action: PayloadAction<string>) => {
      state.selectedTask = action.payload
    },
    setSearchedContact: (state, action: PayloadAction<ContactResult | null>) => {
      state.searchedContact = action.payload
    },
    setSelectedContact: (state, action: PayloadAction<Contact>) => {
      state.selectedContact = action.payload
    },
    setOpenAccountId: (state, action: PayloadAction<string>) => {
      state.openAccountId = action.payload
      state.selectedTask = ''
    },
    updateContact: (state, action: PayloadAction<UpdateContactPayload>) => {
      const contacts = state.activeAccount.contacts
      const contactIndex = contacts.findIndex(e => e.id === action.payload.id)
      if (contactIndex !== -1) {
        const existingContact = state.activeAccount.contacts[contactIndex]
        const updatedContact = action.payload.update

        state.activeAccount.contacts[contactIndex] = {
          ...existingContact,
          ...updatedContact,
        }
      }
    },
  },
});

export const {
  setActiveAccount, setAccountPanelStatus,
  setTask, addTask, removeTask, setSelectedTask,
  setSearchedContact, setSelectedContact,
  setOpenAccountId, updateContact,
} = accountPanelSlice.actions;

export default accountPanelSlice.reducer;
