import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Box, Button, Paper, Tooltip, Typography } from "@mui/material";
import NoCallCardSmall from "../callCard/NoCallCardSmall";
import { Circle, Close, Hearing } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CallCardSmall from "../callCard/CallCardSmall";
import { setListeningToOperatorId } from "reducers/slices/callSlotReducer";
import Highlighter from "react-highlight-words";
import { User } from "utils/activity";
import { setUserOnline } from "reducers/slices/activityViewReducer";
import { operatorCanPickUpCall } from "utils/calls";
import { useAppSelector } from "reducers/hooks";

interface IProps {
  operator: User,
}


const CallList: FC<IProps> = ({ operator }) => {
  const searchUser = useAppSelector(state => state.activityView.searchUser)
  const selectedUser = useAppSelector(state => state.activityView.selectedUser)
  const operatorCalls = useAppSelector(state => state.callSlot.otherUserCalls[operator.id])
  const loggedInUser = useAppSelector(state => state.user.user);
  const listeningToOperatorId = useAppSelector(state => state.callSlot.listeningToOperatorId)
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)

  const unansweredCalls = useAppSelector(state => {
    const unansweredCalls = state.callSlot.unansweredCalls
    const userUnansweredCalls = unansweredCalls.filter((conferenceName) => {
      const call = state.call[conferenceName]
      return call && operatorCanPickUpCall({ ...operator, evoLevels: operator.extension?.evoLevels }, call)
    })

    return userUnansweredCalls
  })

  const [maxCards, setMaxCards] = useState(8);
  const dispatch = useDispatch()

  // Check if any offline user is assigned a call and 
  // assume that they are online.
  useEffect(() => {
    if (!operator.isOnline) {
      if (operatorCalls?.length) {
        dispatch(setUserOnline(operator.id))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorCalls])

  useLayoutEffect(() => {
    const getMaxCards = () => {
      const wrappers = document.getElementsByClassName('activityCardsWrapper')
      if (!wrappers.length) return

      const wrapper = wrappers[0]
      const width = wrapper?.clientWidth || 0

      // +8 since last card has no margin right 8px
      const maxCards = Math.floor(width / 220)
      setMaxCards(maxCards)
    }

    // There is some complexity involved with letting maxCards gets updated on resize
    // So for now we should not handle resize
    // window.addEventListener('resize', getMaxCards)
    getMaxCards()

    // return () => window.removeEventListener('resize', getMaxCards)
  }, [])

  const calls = useMemo(() => {
    let calls = operatorCalls || []

    // Fill with unanswered calls
    if (calls.length < maxCards) {
      calls = calls.concat(unansweredCalls.slice(0, maxCards - calls.length))
    }

    // Fill with undefined
    if (calls.length < maxCards) {
      calls = calls.concat([...new Array(maxCards - calls.length)])
    }

    if (calls.length > maxCards) {
      return calls.slice(0, maxCards)
    }

    return calls
  }, [operatorCalls, unansweredCalls, maxCards])

  const isListening = listeningToOperatorId === operator.id
  const isSelected = operator.id === selectedUser
  return (
    <Paper
      id={`operator-card-${operator.id}`}
      elevation={0}
      sx={{
        p: '12px',
        pb: 2,
        mb: 2,
        '&:last-child': { mb: 0 },
        outline: isSelected ? '2px solid yellow' : '',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography style={{ fontWeight: 600, fontSize: '18px' }}>
            <Highlighter
              searchWords={[!selectedUser ? searchUser : '']}
              autoEscape={true}
              textToHighlight={operator.name}
            />
          </Typography>
          <Typography sx={{ ml: '4px' }} style={{ fontWeight: 400, fontSize: '16px' }}>{`(${operator.role})`}</Typography>
          <Tooltip title={operator.isOnline ? 'Currently logged in' : 'Currently logged out'}>
            <Circle
              sx={{
                ml: 1,
                color: operator.isOnline ? '#3DBF30' : '#A6A6A6',
                fontSize: '12px',
              }}
            />
          </Tooltip>
        </Box>
        {(loggedInUser?.customClaims?.role === 'Manager' && !!operatorCalls?.length) &&
          <Box display="flex" alignItems="center">
            <Button
              sx={{
                mr: 1,
                backgroundColor: isListening ? '#5F27CB!important' : '',
                color: isListening ? '#FFFFFF' : '',
              }}
              startIcon={<Hearing sx={{ color: isListening ? '#FFFFFF' : '#5F27CB' }} />}
              disabled={!callEnabled || (!isListening && !operatorCalls?.length)}
              onClick={() => !isListening && dispatch(setListeningToOperatorId(operator.id))}
            >
              Listen in
            </Button>
            <Button
              startIcon={<Close sx={{ color: '#9E0000' }} />}
              disabled={listeningToOperatorId !== operator.id}
              onClick={() => dispatch(setListeningToOperatorId(null))}
            >
              Leave call
            </Button>
          </Box>
        }
      </Box>
      {(!!operatorCalls?.length || operator.isOnline) &&
        <Box display="flex" mt={2} className="activityCardsWrapper" justifyContent="space-between">
          {calls.map((e, i) => (
            <Box
              key={i}
              sx={{
                p: '1px',
                '&:last-child': { mr: 0 },
              }}
            >
              {!!e ?
                <CallCardSmall conferenceName={e} isListening={isListening} />
                :
                <NoCallCardSmall />
              }
            </Box>
          ))}
        </Box>
      }
      </Paper>
  );
};

export default CallList;
