import React, { useCallback, useContext, useEffect, useState } from "react";
import { Mic, PlayCircle } from "@mui/icons-material";
import {
  Box, Button, InputLabel, LinearProgress, MenuItem,
  Stack, TextField, Typography
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setNoAudioPerm, setNoMic } from "reducers/slices/UIReducer";
import { TwilioContext } from "context/twilio-context";
import { logger } from "utils/utils";
import { useAppSelector } from "reducers/hooks";

const AudioIOSetting = ({ open }: { open: boolean }) => {
  const device = useContext(TwilioContext)?.device
  const isLoadingDevice = useAppSelector(state => state.ui.twilioLoading || state.ui.softTwilioLoading);

  const [inputChangeDisabled, setInputChangeDisabled] = useState<boolean>(false)
  const [outputChangeDisabled, setOutputChangeDisabled] = useState<boolean>(false)

  const [inputDevice, setInputDevice] = useState<string>('')
  const [outputDevice, setOutputDevice] = useState<string>('')

  const [inputDevices, setInputDevices] = useState<InputDeviceInfo[]>([])
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([])

  const [micVolume, setMicVolume] = useState<number>(0)
  const dispatch = useDispatch()

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const inputDevices = devices.filter(e => e.kind === 'audioinput')
      if (!inputDevices.length) {
        logger('No mic detected', devices)
        dispatch(setNoMic(true))
        return
      }

      // Trigger mic permission
      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
        stream.getAudioTracks().forEach(track => track.stop())
        getAndSetDevices()
      }).catch((err) => {
        logger('An error occured when triggering mic permission', err)
        dispatch(setNoAudioPerm(true))
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (open) {
      getAndSetDevices()
      inputDevice && listenToMic()
    }
    if (!open) {
      inputDevice && stopListeningToMic()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, device, inputDevice, isLoadingDevice])

  useEffect(() => {
    if (!inputChangeDisabled && device?.audio && inputDevice) {
      device.audio.setInputDevice(inputDevice).then(() => {
        if (!open) {
          // If not unset, the mic will keep taking inputs
          // Even when unset, the selected device will be remembered
          device?.audio?.unsetInputDevice()
        }
      }).catch((err) => {
        logger('An error occured when stopping mic input', err)
        setInputChangeDisabled(true)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, inputDevice, inputChangeDisabled, isLoadingDevice])

  useEffect(() => {
    if (!outputChangeDisabled && device?.audio && outputDevice) {
      device.audio.speakerDevices.set(outputDevice).catch((err) => {
        logger('An error occured when stopping speaker output', err)
        setOutputChangeDisabled(true)
      })
    }
  }, [device, outputDevice, outputChangeDisabled, isLoadingDevice])

  const getAndSetDevices = () => {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const inputDevices = devices.filter(e => e.kind === 'audioinput')
      const outputDevices = devices.filter(e => e.kind === 'audiooutput')
      setInputDevices(inputDevices as InputDeviceInfo[])
      setOutputDevices(outputDevices)

      const storedInputDevice = localStorage.getItem('inputDevice')
      const storedOutputDevice = localStorage.getItem('outputDevice')
      if (storedInputDevice && inputDevices.find(e => e.deviceId === storedInputDevice)) {
        setInputDevice(storedInputDevice)
      } else {
        setInputDevice('default')
      }
      if (storedOutputDevice && outputDevices.find(e => e.deviceId === storedOutputDevice)) {
        setOutputDevice(storedOutputDevice)
      } else {
        setOutputDevice('default')
      }
    })
  }

  const setVolume = useCallback((vol: number) => {
    setMicVolume(vol)
  }, [])

  const listenToMic = () => {
    if (!inputChangeDisabled && device?.audio) {
      const audio = device.audio
      audio.setInputDevice(inputDevice).then(() => {
        audio.on('inputVolume', setVolume)
      })
    }
  }
  const stopListeningToMic = () => {
    if (device?.audio) {
      device.audio.off('inputVolume', setVolume)
      device.audio.unsetInputDevice()
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputDevice(event.target.value)
    localStorage.setItem('inputDevice', event.target.value)
  }
  const handleOutputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setOutputDevice(event.target.value)
    localStorage.setItem('outputDevice', event.target.value)
  }

  const testSpeaker = () => {
    if (device?.audio) {
      device.audio.speakerDevices.test()
    }
  }

  return (
    <Box>
      <Typography mt={1} sx={{ fontSize: '14px', fontWeight: 600 }}>Speaker</Typography>
      <TextField
        sx={{ mt: 1 }}
        value={outputDevice}
        onChange={handleOutputChange}
        SelectProps={{
          sx: {
            backgroundColor: '#E4ECFC',
            borderColor: '#CBD8F1',
          },
          MenuProps: { sx: { width: 350 } },
        }}
        disabled={outputChangeDisabled}
        size="small"
        select
        fullWidth
      >
        {outputDevices.map(device => (
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {device.label}
            </Typography>
          </MenuItem>
        ))}
        {!outputDevices.some(e => e.deviceId === 'default') && <MenuItem value="default">Default</MenuItem>}
      </TextField>
      {outputChangeDisabled && <InputLabel error>This browser does not support speaker selection.</InputLabel>}
      {!outputChangeDisabled &&
        <Button
          onClick={testSpeaker}
          sx={{
            color: '#1033A5',
            lineHeight: '20px',
            fontWeight: 700,
            mt: 1,
            borderRadius: '10px',
          }}
          variant="text"
          startIcon={<PlayCircle />}
        >
          Test
        </Button>
      }

      <Typography mt={2} sx={{ fontSize: '14px', fontWeight: 600 }}>Microphone</Typography>
      <TextField
        sx={{ mt: 1 }}
        value={inputDevice}
        onChange={handleInputChange}
        SelectProps={{
          sx: {
            backgroundColor: '#E4ECFC',
            borderColor: '#CBD8F1',
          },
          MenuProps: { sx: { width: 350 } },
        }}
        size="small"
        select
        fullWidth
        disabled={inputChangeDisabled}
      >
        {inputDevices.map(device => (
          <MenuItem
            key={device.deviceId}
            value={device.deviceId}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {device.label}
            </Typography>
          </MenuItem>
        ))}
        {!inputDevices.some(e => e.deviceId === 'default') && <MenuItem value="default">Default</MenuItem>}
      </TextField>
      {inputChangeDisabled && <InputLabel error>This browser does not support microphone selection.</InputLabel>}
      {!inputChangeDisabled &&
        <Stack mt={1} spacing={1} direction="row" alignItems="center">
          <Mic sx={{ color: '#1033A5', m: '4px' }} />
          <LinearProgress
            variant="determinate"
            sx={{ width: '100%', height: 10 }}
            value={micVolume * 100}
          />
        </Stack>
      }
    </Box>
  );
};

export default AudioIOSetting;
