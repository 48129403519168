import { FC, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Contact, ScheduledContactStatus } from "utils/accounts";
import { useDispatch } from "react-redux";
import { setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";
import { updateCurrentAlert } from 'api/contacts';
import ContactStatusAlert from "./contactItem/ContactStatusAlert";

interface IProps {
  contactStatus: ScheduledContactStatus | null,
  contact: Contact | null,
  closeArchive: () => void,
  onArchiveCallback: () => void,
}

const ContactStatusArchiveDialog: FC<IProps> = ({ contactStatus, contact, closeArchive, onArchiveCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleArchive = () => {
    if (contactStatus && contact) {
      setIsLoading(true)

      updateCurrentAlert(contact.accountId, contact.id, {archiveScheduler: true}).then(() => {
        dispatch(setSnackbar({ message: 'Contact Call Status archived!', severity: SnackbarSeverity.SUCCESS }))
        onArchiveCallback()
      }).finally(() => setIsLoading(false))
    }
  }
  
  return (
    <Dialog open={!!contactStatus} onClose={() => closeArchive()}>
      <DialogTitle>
        {'Archive Status'}
        <IconButton
          onClick={() => closeArchive()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#E80B0B',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pr: 6 }}>
        <Typography>Are you sure you want to archive this status?</Typography>
        {(contactStatus && contact) &&
          <ContactStatusAlert contactStatus={contactStatus} contact={contact} />
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={closeArchive}>Cancel</Button>
        <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleArchive}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactStatusArchiveDialog;
