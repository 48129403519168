import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface PendingEvent {
  eventName: string,
  data: any,
}

// CCAA-1448 On edge cases, user might receive call events before they finished fetching all calls.
// Resulting in the fetched calls being a few events behind.
// We want to keep these events and run them after user have finished fetching the calls.
export interface EventState {
  acceptEvents: boolean,
  pendingEvents: PendingEvent[],
}

const initialState: EventState = {
  acceptEvents: false,
  pendingEvents: [],
};


export const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setAcceptEvents: (state, action: PayloadAction<boolean>) => {
      state.acceptEvents = action.payload
    },
    addPendingEvent: (state, action: PayloadAction<PendingEvent>) => {
      state.pendingEvents.push(action.payload)
    },
    clearPendingEvent: (state) => {
      state.pendingEvents = []
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setAcceptEvents, addPendingEvent, clearPendingEvent
} = EventSlice.actions;

export default EventSlice.reducer;
