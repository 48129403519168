import { Box, Stack, Tooltip, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { PanelTask } from "utils/accounts";
import TaskAbbyForm from "./TaskAbbyForm";

const LinkText = styled(Typography)(() => ({
  height: 20,
  fontWeight: 600,
  fontSize: '16px',
  color: '#1033A5',
  textDecoration: 'underline',
  cursor: 'pointer'
}))

const Divider = () => {
  return (
    <Typography
      sx={{
        height: 20,
        fontWeight: 600,
        fontSize: '16px',
      }}
    >|
    </Typography>
  )
}

const TaskFrame = ({ isAbby, isSelected, task }: { isAbby?: boolean, isSelected: boolean, task: PanelTask }) => {
  const [showCopied, setShowCopied] = useState<boolean>(false)

  const onCopyUrl = () => {
    setShowCopied(true)

    navigator.clipboard.writeText(task.link)

    setTimeout(() => {
      setShowCopied(false)
    }, 1000)
  }

  return (
    <Box
      key={task.name}
      display={isSelected ? 'block' : 'none'}
      width="100%"
      height={`calc(100% - 40px)`}
    >
      <Stack pb="6px" direction="row" spacing={1}>
        <Typography
          sx={{
            height: 20,
            fontWeight: 600,
            fontSize: '16px',
          }}
        >
          {'If the website failed to load, click '}
          <LinkText
            // @ts-ignore
            component="span"
            onClick={() => window.open(task.link, '_blank')}
          >
            here
          </LinkText>
          {' to open it in a new tab'}
        </Typography>

        <Divider />

        <Tooltip title={task.link}>
          <LinkText>Show URL</LinkText>
        </Tooltip>

        <Divider />

        <Tooltip title="Copied!" open={showCopied} disableFocusListener disableHoverListener disableTouchListener>
          <LinkText onClick={onCopyUrl}>Copy URL</LinkText>
        </Tooltip>
      </Stack>

      {isAbby &&
        <TaskAbbyForm taskId={task.name} link={task.link} isSelected={isSelected} />
      }
      {!isAbby &&
        <iframe
          src={task.link}
          title={task.name}
          id={task.name}
          style={{
            width: '100%',
            height: 'calc(100% - 36px)',
          }}
          referrerPolicy="no-referrer-when-downgrade"
          // All but allow-top-navigation
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        >
        </iframe>
      }
    </Box>

  );
};

export default TaskFrame;
