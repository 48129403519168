import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box, Button, Slider, Typography
} from "@mui/material";
import { RingtoneContext } from "context/ringtone-context";
import { PlayCircle, StopCircle } from "@mui/icons-material";
import { Howl } from "howler";
const ringing = require('../../../assets/ringing.mp3');

export const RINGTONE_VOLUME_STORAGE = 'RINGTONE_VOLUME_STORAGE'
export const DEFAULT_VOLUME = 0.8

interface IProps {
  open: boolean,
}

const RingtoneSetting: FC<IProps> = ({ open }) => {
  const { setVolume } = useContext(RingtoneContext)
  const [isTestRinging, setIsTestRinging] = useState<boolean>(false)
  const [ringVolume, setRingVolume] = useState<number>(() => {
    const storedVolume = localStorage.getItem(RINGTONE_VOLUME_STORAGE)
    if (storedVolume) {
      return parseFloat(storedVolume)
    } else {
      return DEFAULT_VOLUME
    }
  })
  const [testRingtone] = useState(() => {
    const storedVolume = localStorage.getItem(RINGTONE_VOLUME_STORAGE)
    let volume
    if (storedVolume) {
      volume = parseFloat(storedVolume)
    } else {
      volume = DEFAULT_VOLUME
    }

    return new Howl({
      src: [ringing],
      loop: true,
      volume
    })
  })

  useEffect(() => {
    if (!open) {
      stopRinging()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setVolume(ringVolume)
    testRingtone.volume(ringVolume)
    localStorage.setItem(RINGTONE_VOLUME_STORAGE, ringVolume.toString())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ringVolume, setVolume])

  const startRinging = () => {
    testRingtone.play()
    setIsTestRinging(true)
  }

  const stopRinging = () => {
    testRingtone.stop()
    setIsTestRinging(false)
  }

  const onRingtoneVolumeChange = (e: Event, newValue: number | number[]) => {
    setRingVolume(newValue as number)
  }

  const toggleRingtone = () => {
    if (isTestRinging) {
      stopRinging()
    } else {
      startRinging()
    }
  }

  return (
    <Box>
      <Typography mt={2} sx={{ fontSize: '14px', fontWeight: 600 }}>Ringtone Volume</Typography>
      <Slider
        sx={{ mr: 1, width: 'calc(100% - 8px)' }}
        max={1}
        value={ringVolume}
        step={0.01}
        onChange={onRingtoneVolumeChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => Math.floor(value * 100) + '%'}
      />
      <Button
        onClick={toggleRingtone}
        sx={{
          color: '#1033A5',
          lineHeight: '20px',
          fontWeight: 700,
          borderRadius: '10px',
        }}
        variant="text"
        startIcon={isTestRinging ? <StopCircle /> : <PlayCircle />}
      >
        {isTestRinging ? 'Stop' : 'Test'}
      </Button>
    </Box>
  );
};

export default RingtoneSetting;
