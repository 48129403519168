import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Collapse, IconButton, Paper, Stack, TextField, Tooltip, Typography, styled } from '@mui/material';
import {
  Dialpad, MergeType, Phone
} from '@mui/icons-material';
import { CallStateState, CallType, isActiveCallTransferable } from 'utils/calls';
import useCallFunctions from 'hooks/useCallFunctions';
import CallAsIcon from 'assets/CallAsIcon';
import { Dtmf } from 'play-dtmf';
import { CustomPopover } from 'components/CustomPopover';
import { useAppSelector } from 'reducers/hooks';
import { activeCallSelector } from 'reducers/selector/callSelector';
import { isSameNumber } from 'utils/isSameNumber';

const DialButton = styled(Button)(() => ({
  minWidth: 36,
  width: 36,
  height: 24,
  fontSize: '12px',
  fontWeight: 600,
  borderRadius: '5px',
  backgroundColor: '#E4ECFC',
  '&:hover': { backgroundColor: '#D3DBEB' },
}))

interface IProps {
  phoneNumTo: string,
  setPhoneNumTo: React.Dispatch<React.SetStateAction<string>>
}

const CallDialer: FC<IProps> = ({ phoneNumTo, setPhoneNumTo }) => {
  const anchorEl = useRef(null)
  const activeCall = useAppSelector(activeCallSelector)
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)

  const [dialerDisabled, setDialerDisabled] = useState<boolean>(false)
  const [dialInputOpen, setDialInputOpen] = useState<boolean>(false)
  const [digitsDialed, setDigitsDialed] = useState<string>('')
  const {
    outboundOrTransfer,
    outboundCall,
    sendDigits,
    dialExtension,
    coldTransfer,
  } = useCallFunctions()

  useEffect(() => {
    setDigitsDialed('')
  }, [dialInputOpen])

  // Stops user from dialing in succession
  const putDialerOnCooldown = () => {
    setDialerDisabled(true)
    setTimeout(() => {
      setDialerDisabled(false)
    }, 500)
  }

  const onColdTransfer = () => {
    putDialerOnCooldown()
    if (!activeCall) return
    coldTransfer(activeCall.conferenceName, phoneNumTo)
  }

  const onDial = () => {
    putDialerOnCooldown()
    outboundOrTransfer(phoneNumTo)
    if (phoneNumTo.includes('?')) {
      setDialInputOpen(true)
    }
  }

  const onDialAs = () => {
    putDialerOnCooldown()
    outboundCall(phoneNumTo, true)
  }

  const onDialInput = (num: Dtmf) => {
    putDialerOnCooldown()
    setDigitsDialed(digitsDialed + num)
    sendDigits(num)
  }

  const onDialExtension = (extension: string) => {
    if (!activeCall) return
    putDialerOnCooldown()
    dialExtension(activeCall, extension)
  }

  const isProcessing = (
    activeCall?.isProcessing ||
    activeCall?.state === CallStateState.TRANSFERRING ||
    (activeCall && !activeCall.transferEnabled)
  ) || dialerDisabled || !callEnabled

  const isDialingVm = phoneNumTo.includes('*99')

  const extensionsAvailable = useMemo(() => {
    if (activeCall?.type === CallType.OUTBOUND && !activeCall?.callerNumber?.includes('?')) return []
    if (activeCall?.type === CallType.INBOUND && !activeCall?.warmTransferredToNum?.includes('?')) return []

    let targetNumber = ''
    if (activeCall?.type === CallType.OUTBOUND) {
      targetNumber = activeCall.callerNumber
    }
    if (activeCall?.type === CallType.INBOUND) {
      targetNumber = activeCall.warmTransferredToNum || ''
    }

    const extensions = targetNumber.split('?').map(e => e.trim())
    extensions.splice(0, 1)

    return extensions

  }, [activeCall])

  const DialerBox = (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '5px',
        borderBottomLeftRadius: (dialInputOpen) ? 0 : '5px',
        borderBottomRightRadius: (dialInputOpen) ? 0 : '5px',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        pl="6px" pr={1} py="2px"
        width="calc(275px - 14px)"
      >
        <Tooltip title="Show Dialpad">
          <span>
            <IconButton
              size="small"
              sx={{ color: '#2F6FCF' }}
              onClick={() => setDialInputOpen(!dialInputOpen)}
              // disabled={!activeCall}
            >
              <Dialpad />
            </IconButton>
          </span>
        </Tooltip>
        <TextField
          sx={{
            bgcolor: '#E4ECFC',
            borderRadius: '5px',
            ml: '6px', mr: 1,
            width: 120,
          }}
          InputProps={{ sx: { py: '4px', px: 1 } }}
          inputProps={{
            style: {
              fontSize: '14px',
              padding: 0,
            }
          }}
          fullWidth
          variant="outlined"
          value={phoneNumTo}
          onChange={(e) => setPhoneNumTo(e.target.value)}
        />
        <Tooltip title={isDialingVm && !!activeCall ? 'Dial is disabled for VM, please use Connect instead' : 'Dial'} sx={{ p: '2px' }}>
          <span>
            <IconButton
              size="small"
              sx={{ color: '#448C3D' }}
              onClick={onDial}
              disabled={
                !phoneNumTo ||
                !!activeCall?.warmTransferredTo ||
                (isDialingVm && !!activeCall) ||
                isSameNumber(phoneNumTo, activeCall?.callerNumber) ||
                isProcessing ||
                activeCall?.type === CallType.UNBOUND
              }
            >
              <Phone />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Connect" sx={{ p: '2px' }}>
          <span>
            <IconButton
              size="small"
              sx={{ color: '#D79F2B' }}
              onClick={onColdTransfer}
              disabled={
                !phoneNumTo ||
                !isActiveCallTransferable(activeCall) ||
                activeCall?.callerLeftWarmTransfer ||
                !callEnabled
              }
            >
              <MergeType />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Dial As" sx={{ p: '2px' }}>
          <span>
            <IconButton
              size="small"
              sx={{ color: '#2F6FCF' }}
              onClick={onDialAs}
              disabled={
                !phoneNumTo ||
                !!activeCall || // Can only be used for outbound call, so no active call
                isProcessing
              }
            >
              <CallAsIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Paper>
  )

  return (
    <>
      <CustomPopover
        anchorEl={anchorEl.current}
        container={anchorEl.current}
        open={dialInputOpen}
        onClose={() => setDialInputOpen(false)}
      >
        {DialerBox}
        <Collapse in={dialInputOpen} appear timeout={300}>
          <Box
            sx={{
              px: 1, py: '12px',
              borderTop: '1px solid #E3EBFC',
              backgroundColor: 'white',
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              {['1', '2', '3', '4', '5'].map(num => (
                <DialButton
                  key={num}
                  disableElevation
                  onClick={() => onDialInput(num as Dtmf)}
                  sx={{ color: '#2F6FCF' }}
                >
                  {num}
                </DialButton>
              ))}
              <DialButton
                disableElevation
                onClick={() => onDialInput('*')}
                sx={{
                  backgroundColor: '#F0F0F0',
                  '&:hover': { backgroundColor: '#DEDEDE' }
                }}
              >
                {'\uff0a'}
              </DialButton>
            </Stack>
            <Stack sx={{ mt: 1 }} direction="row" justifyContent="space-between">
              {['6', '7', '8', '9', '0'].map(num => (
                <DialButton
                  key={num}
                  disableElevation
                  onClick={() => onDialInput(num as Dtmf)}
                  sx={{ color: '#2F6FCF' }}
                >
                  {num}
                </DialButton>
              ))}
              <DialButton
                disableElevation
                onClick={() => onDialInput('#')}
                sx={{
                  backgroundColor: '#F0F0F0',
                  '&:hover': { backgroundColor: '#DEDEDE' }
                }}
              >
                #
              </DialButton>
            </Stack>
            <Collapse in={!!extensionsAvailable.length}>
              {extensionsAvailable.map(ext => (
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: '11px',
                    fontWeight: 600,
                    color: activeCall?.extensionsDialed?.includes(ext) ? '#800080' : '#1033A5',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => onDialExtension(ext)}
                >
                  {ext}
                </Typography>
              ))}
            </Collapse>
            <Collapse in={!!digitsDialed}>
              <Typography sx={{ fontSize: '11px', mt: 1 }}>
                {digitsDialed}
              </Typography>
            </Collapse>
          </Box>
        </Collapse>
      </CustomPopover>

      <Box ref={anchorEl}>
        {DialerBox}
      </Box>
    </>
  );
}

export default CallDialer;