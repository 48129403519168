import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { LAST_ACCOUNT_STORAGE, TASKS_STORAGE } from "utils/accounts";

interface UserDetails {
  id: string,
  avatar: string,
  userBlock: string | null,
  teamBlock: string,
  calendlyLink: string,
  calendlyLinkLiveChat:string,
  email: string,
  evoLevels: string,
  extension: string,
  firstName: string,
  name: string,
  role: string,
  spanishCalls: string,
  teamId: string,
  teamName: string,
}

interface User {
  userId: string,
  customClaims?: {
    role: string,
  },
  passed2fa?: boolean,
  duoUserId?: string,
  auth2faMethods?: string[],
  iat?: number,
  details?: UserDetails,
}
export type LoggedInUser = User

export interface userState {
  user: User | null;
  loading: boolean;
}

const token = localStorage.getItem("token");
let storedUser: User | null = null
if (token) {
  try {
    storedUser = jwt_decode(token);
    if (!storedUser?.passed2fa) {
      storedUser = null
      localStorage.removeItem("token")
    }
  } catch (err) {
    localStorage.removeItem("token")
  }
}

const initialState: userState = {
  user: storedUser,
  loading: false,
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<string>) => {
      const decoded: User = jwt_decode(action.payload);

      localStorage.setItem("token", action.payload);
      return {
        ...state,
        loading: false,
        user: decoded,
      };
    },
    addUserDetails: (state, action: PayloadAction<UserDetails>) => {
      if (state.user) {
        state.user.details = action.payload
      }
    },
    logout: (state) => {
      localStorage.removeItem(LAST_ACCOUNT_STORAGE);
      localStorage.removeItem(TASKS_STORAGE);
      localStorage.removeItem("token");

      return {
        ...state,
        user: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, addUserDetails, logout } = authSlice.actions;

export default authSlice.reducer;
