import { IconButton, InputAdornment, MenuItem, Typography } from "@mui/material"
import { FC } from "react";
import { FormQuestion } from "../utils";
import { Close } from "@mui/icons-material";
import StyledTextField from "./StyledTextField";

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  error?: string,
  textArea?: boolean,
}

const PicklistInput: FC<IProps> = (props) => {
  const { question, handleChange, value, error } = props;

  return (
    <StyledTextField
      select
      size="small"
      fullWidth
      onChange={e => handleChange(question.id, e.target.value)}
      value={value}
      error={!!error}
      helperText={error}
      SelectProps={{
        sx: {
          backgroundColor: '#E4ECFC',
          borderColor: '#CBD8F1',
          borderRadius: '25px',
        },
        displayEmpty: true,
        renderValue: value !== "" ? undefined : () => <Typography style={{ color: '#a6b6e3', fontWeight: 500 }}>Please Select</Typography>
      }}
      InputProps={{
        sx: { bgcolor: '#E4ECFC', borderRadius: '25px' },
        endAdornment: (
          !!value &&
          <InputAdornment position="end">
            <IconButton sx={{ p: 0, mr: 3 }} onClick={() => handleChange(question.id, '')}>
              <Close sx={{ fontSize: '18px' }} />
            </IconButton>
          </InputAdornment>
        )
      }}
    >
      {question.picklist && question.picklist.map(picklist => (
        <MenuItem key={picklist.id} value={picklist.id}>
          <Typography>
            {picklist.value}
          </Typography>
        </MenuItem>
      ))}
    </StyledTextField>
  )
}

export default PicklistInput;
