import agent from './agent'

export function getScheduledStatuses(companyId: string, contactId: string) {
  return agent.get(`/companies/${companyId}/contacts/${contactId}/contact-status-schedulers`)
}

export function createScheduledStatus(companyId: string, contactId: string, body: any) {
  return agent.post(`/companies/${companyId}/contacts/${contactId}/contact-status-schedulers`, body)
}

export function resolveScheduledStatusConflict(companyId: string, contactId: string, body: any) {
  return agent.patch(`/companies/${companyId}/contacts/${contactId}/resolve-call-status-scheduler-conflict`, body)
}

export function updateScheduledStatus(id: string, companyId: string, contactId: string, body: any) {
  return agent.patch(`/companies/${companyId}/contacts/${contactId}/contact-status-schedulers/${id}`, body)
}

export function deleteScheduledStatus(id: string, companyId: string, contactId: string) {
  return agent.delete(`/companies/${companyId}/contacts/${contactId}/contact-status-schedulers/${id}`)
}

export function updateCurrentAlert(companyId: string, contactId: string, body: any) {
  return agent.patch(`/companies/${companyId}/contacts/${contactId}/call-status`, body)
}

