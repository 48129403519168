import { FC, useMemo } from 'react';
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Archive, Delete } from '@mui/icons-material';
import { Contact, ScheduledContactStatus, getContactUnavailability } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import { formatDate, getPartsFromAccountTz } from 'utils/utils';
import EditIcon from "assets/EditIcon";
import { useAppSelector } from '../../../../../reducers/hooks';

interface IProps {
  search?: string,
  contactStatus: ScheduledContactStatus,
  contact?: Contact,
  onDelete?: () => void,
  onEdit?: () => void,
  isArchivable?: boolean,
}

const ContactStatusAlert: FC<IProps> = ({ contactStatus, contact, search = '', onEdit, onDelete, isArchivable = false }) => {
  const account = useAppSelector(state => state.accountPanel.activeAccount);
  const currentUnavailability = getContactUnavailability(contactStatus, contact)
  const {timezoneCode, timezoneName} = getPartsFromAccountTz(account?.timeZone);
  const formattedDates = useMemo(() => {
    const dateFormat = `MM/DD/YYYY h:mm A`;
    const dateFormatAndTz = `MM/DD/YYYY h:mm A [${timezoneName}]`;

    if (!contactStatus.applyStatusOn && !contactStatus.revertStatusOn) return 'UFN';

    return contactStatus.revertStatusOn
      ? `${formatDate(contactStatus.applyStatusOn, dateFormat, timezoneCode)} - ${formatDate(contactStatus.revertStatusOn, dateFormatAndTz, timezoneCode)}`
      : `${formatDate(contactStatus.applyStatusOn, dateFormatAndTz, timezoneCode)} - UFN`;
  }, [contactStatus.applyStatusOn, contactStatus.revertStatusOn, timezoneCode, timezoneName]);

  return (
    <Box width="100%">
      {contactStatus.changeStatusTo === 'Yes' &&
        <Box
          sx={{
            p: '12px',
            width: 'calc(100% - 24px - 2px)',
            border: '1px solid rgba(68, 140, 61, 0.6)',
            borderRadius: '5px',
            backgroundColor: 'rgba(68, 140, 61, 0.2)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
              {formattedDates}
            </Typography>
            <Typography style={{ fontSize: '12px' }}>Taking Calls</Typography>
            {contactStatus.doNotConnect && (
              <Typography style={{ fontSize: '12px' }}>
                <Highlighter
                  searchWords={[search.toLowerCase()]}
                  autoEscape
                  textToHighlight={`Do not connect: ${contactStatus.doNotConnect.split(';').join(',')}`}
                />
              </Typography>
            )}
            {contactStatus.statusNote && (
              <Typography style={{ fontSize: '12px' }}>
                <Highlighter
                  searchWords={[search.toLowerCase()]}
                  autoEscape
                  textToHighlight={`Note: ${contactStatus.statusNote}`}
                />
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: '2px' }}>
            {onEdit && (
              <IconButton sx={{ p: 0 }} onClick={onEdit}>
                <Tooltip title="Update Alert">
                  <EditIcon sx={{ fontSize: '18px' }} />
                </Tooltip>
              </IconButton>
            )}
            {onDelete && (
              <IconButton sx={{ p: 0 }} onClick={onDelete}>
                {isArchivable && <Tooltip title="Archive Alert">
                  <Archive sx={{ fontSize: '18px' }} />
                </Tooltip>}

                {!isArchivable && <Tooltip title="Delete Alert">
                  <Delete sx={{ fontSize: '18px' }} />
                </Tooltip>}
              </IconButton>
            )}
          </Box>
        </Box>
      }

      {contactStatus.changeStatusTo !== 'Yes' &&
        <Box
          sx={{
            p: '12px',
            width: 'calc(100% - 24px - 2px)',
            border: '1px solid rgba(232, 11, 11, 0.6)',
            borderRadius: '5px',
            backgroundColor: 'rgba(232, 11, 11, 0.2)',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box>
              {(contactStatus.applyStatusOn || formattedDates) && (
                <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                  {formattedDates}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: '2px' }}>
              {onEdit && (
                <IconButton sx={{ p: 0 }} onClick={onEdit}>
                  <Tooltip title="Update Alert">
                    <EditIcon sx={{ fontSize: '18px' }} />
                  </Tooltip>
                </IconButton>
              )}
              {onDelete && (
                <IconButton sx={{ p: 0 }} onClick={onDelete}>
                  {isArchivable && <Tooltip title="Archive Alert">
                    <Archive sx={{ fontSize: '18px' }} />
                  </Tooltip>}

                  {!isArchivable && <Tooltip title="Delete Alert">
                    <Delete sx={{ fontSize: '18px' }} />
                  </Tooltip>}
                </IconButton>
              )}
            </Box>
          </Box>

          <Typography style={{ fontSize: '12px' }}>
            <Highlighter
              searchWords={[search.toLowerCase()]}
              autoEscape
              textToHighlight={currentUnavailability}
            />
          </Typography>

          {contactStatus.connectOnly && (
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape
                textToHighlight={`Connect only: ${contactStatus.connectOnly.split(';').join(',')}`}
              />
            </Typography>
          )}

          {contactStatus.statusNote && (
            <Typography style={{ fontSize: '12px' }}>
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape
                textToHighlight={`Note: ${contactStatus.statusNote}`}
              />
            </Typography>
          )}
        </Box>
      }
    </Box>
  )
}

export default ContactStatusAlert;
