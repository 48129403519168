import { LoggedInUser } from "reducers/slices/userReducer"
import { AppThunk } from "reducers/types"

export const getLoggedInUser = (): AppThunk<LoggedInUser | null> => {
  return (dispatch, getState) => {
    return getState().user.user
  }
}

export const getIsCallEnabled = (): AppThunk<boolean> => {
  return (dispatch, getState) => {
    return getState().ui.isCallEnabled
  }
}
