import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import ActivityHeader from "./ActivityHeader";
import GroupList from "./GroupList";
import { HEADER_HEIGHT } from "../Header";
import { getAllUsers } from "api/users";
import { getActiveConferences, getUnansweredConferences } from "api/conferences";
import { useDispatch } from "react-redux";
import { setOtherUserCalls } from "reducers/slices/callSlotReducer";
import useCallFunctions from "hooks/useCallFunctions";
import { addCalls } from "reducers/slices/callReducer";
import { prepareFetchedCalls } from "utils/calls";
import { setAndGroupUsers } from "reducers/slices/activityViewReducer";
import { getCallStats } from "api/callrecords";
import { useAppSelector } from "reducers/hooks";
import { logger } from "utils/utils";
import useSocket from "../../../hooks/useSocket";

interface TeamCallStats {
  teamId: string,
  callsPerDay: number,
}

export interface CallStats {
  totalAnsweredCallsPerDay: number,
  totalUnansweredCallsPerDay: number,
  teams: TeamCallStats[],
}

const ActivityView = () => {
  const socket = useSocket();
  const loggedInUser = useAppSelector(state => state.user.user);
  const dispatch = useDispatch()
  const { addUnansweredCall } = useCallFunctions()
  const [callStats, setCallStats] = useState<CallStats>({ totalAnsweredCallsPerDay: 0, totalUnansweredCallsPerDay: 0, teams: [] })

  useEffect(() => {
    getCallStats().then(res => {
      setCallStats(res.data)
    })

    getAllUsers().then((res: any) => {
      dispatch(setAndGroupUsers(res.data))
    })

    getActiveConferences().then((res: any) => {
      const _calls = prepareFetchedCalls(res.data)
      const calls = _calls.filter(e => e.conferenceJoinedBy !== loggedInUser?.userId)
      dispatch(addCalls({ calls }))
      dispatch(setOtherUserCalls(res.data))
    })

    getUnansweredConferences().then(res => {
      res.data.forEach((conference: any) => {
        addUnansweredCall(conference.conferenceName, conference)
      })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser?.details?.teamBlock, loggedInUser?.details?.userBlock])

  useEffect(() => {
    socket?.emit('ACTIVITY_VIEW_SUB', {}, function () {
      logger('ACTIVITY_VIEW_SUB')
    });

    return () => {
      socket?.emit('ACTIVITY_VIEW_UNSUB', {}, function () {
        logger('ACTIVITY_VIEW_UNSUB')
      });
    }
  }, [socket])
  
  return (
    <Box width="100%" height={`calc(100% - ${HEADER_HEIGHT}px)`}>
      <ActivityHeader callStats={callStats} />
      <GroupList callStats={callStats} />
    </Box>
  );
};

export default ActivityView;
