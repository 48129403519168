import agent from './agent'

export function duoEnroll(token: string) {
  return agent.post('/duo/enroll', {}, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export interface IEnrollStatus {
  duoUserId: string;
  activationCode: string;
}

export function duoEnrollStatus(payload: IEnrollStatus, token: string) {
  return agent.post('/duo/enroll-status', payload, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export function duoAuth(factor: 'sms' | 'push', token: string) {
  return agent.post('/duo/auth', { factor }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export function duoAuthSms(passcode: string, token: string) {
  return agent.post('/duo/auth-sms', { passcode }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}