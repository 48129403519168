import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { CallGroup, User } from "utils/activity";

interface AllUsers {
  [userId: string]: User,
}

interface activityViewState {
  groups: CallGroup[],
  allUsers: AllUsers,
  searchUser: string,
  selectedUser: string,
}

const initialState: activityViewState = {
  groups: [],
  allUsers: {},
  searchUser: '',
  selectedUser: '',
}

interface AddOrUpdateUserPayload {
  user: User,
  loggedInUserBlock: string,
}

export const activityViewSlice = createSlice({
  name: "activityView",
  initialState,
  reducers: {
    setAndGroupUsers: (state, action: PayloadAction<User[]>) => {
      const groups: CallGroup[] = []
      const allUsers: AllUsers = {}

      action.payload.forEach(user => {
        let group = groups.find(group => group.id === user.teamId)
        if (!group) {
          group = {
            id: user.teamId,
            name: user.teamName,
            userIds: []
          }
          groups.push(group)
        }

        if (group) {
          group.userIds.push(user.id)
        }
        allUsers[user.id] = user
      })

      return {
        ...state,
        groups,
        allUsers,
      }
    },
    addOrUpdateUsers: (state, action: PayloadAction<User[]>) => {
      const groups = cloneDeep(state.groups)
      const allUsers = cloneDeep(state.allUsers)

      action.payload.forEach(user => {
        let group = groups.find(group => group.id === user.teamId)
        if (!group) {
          group = {
            id: user.teamId,
            name: user.teamName,
            userIds: []
          }
          groups.push(group)
        }

        if (group) {
          if (!group.userIds.includes(user.id)) {
            group.userIds.push(user.id)
          }
        }
        allUsers[user.id] = user
      })

      return {
        ...state,
        groups,
        allUsers,
      }
    },
    addOrUpdateUser: (state, action: PayloadAction<AddOrUpdateUserPayload>) => {
      let groups = cloneDeep(state.groups)
      const allUsers = cloneDeep(state.allUsers)

      const { user, loggedInUserBlock } = action.payload

      groups.forEach(group => {
        group.userIds = group.userIds.filter(e => e !== user.id)
      })

      groups = groups.filter(e => !!e.userIds.length)

      const group = groups.find(group => group.id === user.teamId)

      if (group) {
        group.userIds.push(user.id)
      } else {
        const newBlock = user.userBlock || user.teamBlock
        if (loggedInUserBlock === '0' || loggedInUserBlock === newBlock) {
          const newGroup = {
            id: user.teamId,
            name: user.teamName,
            userIds: [user.id]
          }
          groups.push(newGroup)
        }
      }

      allUsers[user.id] = user

      return {
        ...state,
        groups,
        allUsers,
      }
    },
    setUserOnline: (state, action: PayloadAction<string>) => {
      const userId = action.payload
      if (state.allUsers[userId]) {
        state.allUsers[userId].isOnline = true
      }
    },
    setSearchUser: (state, action: PayloadAction<string>) => {
      state.searchUser = action.payload
    },
    setSelectedUser: (state, action: PayloadAction<string>) => {
      state.selectedUser = action.payload
    },
  },
});

export const { setAndGroupUsers, addOrUpdateUsers, addOrUpdateUser, setUserOnline, setSearchUser, setSelectedUser } = activityViewSlice.actions;

export default activityViewSlice.reducer;
