import { useEffect } from "react";
import { useAppDispatch } from "reducers/hooks";
import { AppThunkDispatch } from "reducers/types";
import { clearRemovedCalls } from "reducers/thunks/callThunks";

/**
 * Properly remove calls that has been flagged as removed, to clear up memory
 */
const useRemovedCallCleaner = () => {
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    const interval = setInterval(() => {
      thunkDispatch(clearRemovedCalls())
    }, 60 * 1000)

    return () => {
      if (interval) clearInterval(interval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default useRemovedCallCleaner
