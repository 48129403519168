import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CallRecord } from 'utils/calls';
import { getCallRecords } from "api/callrecords";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import CallerHistory from "../components/CallerHistory";
import debounce from "awesome-debounce-promise";
import { useDispatch } from "react-redux";
import { AppThunkDispatch } from "reducers/types";
import { getSelectedTask } from "reducers/thunks/accountThunk";

const debouncedGetCallRecords = debounce(getCallRecords, 500)

const TaskCallerHistory = () => {
  const selectedTask = useSelector((state: RootState) =>
    state.accountPanel.tasks.find(e => e.name === state.accountPanel.selectedTask))

  const [lastCallRecord, setLastCallRecord] = useState<CallRecord | null>(null) // Always incoming call
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch


  useEffect(() => {
    setLastCallRecord(null)
    setIsLoading(true)

    const taskName = selectedTask?.name

    debouncedGetCallRecords({
      limit: 1,
      page: 1,
      callerNumber: selectedTask?.callerNumber,
      accountIds: selectedTask?.accountId ? [selectedTask.accountId] : undefined,
    }).then((res) => {
      const selectedTask = thunkDispatch(getSelectedTask())
      if (selectedTask?.name !== taskName) return

      const lastRecord = res.data[0] as CallRecord
      setLastCallRecord(lastRecord)

      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask])

  const isOutbound = selectedTask?.direction === 'Outgoing'
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100% - 32px)',
        overflowY: 'auto',
        py: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          px: 2,
          borderBottom: '1px solid #E4ECFC',
        }}
      >
        {isOutbound ? 'Callee' : 'Caller'} History
      </Typography>
      <Box
        sx={{
          p: 2, pb: '4px',
          width: 'calc(100% - 32px)',
        }}
      >
        <CallerHistory
          lastCallRecord={lastCallRecord}
          isLoading={isLoading}
          isOutbound={isOutbound}
          account={{
            name: selectedTask?.accountName || '',
            id: selectedTask?.accountId || '',
          }}
          useAccountInfo={!selectedTask?.callerNumber}
        />
      </Box>
    </Box>
  );
};

export default TaskCallerHistory;
