import React, { FC, useEffect, useMemo, useState } from 'react'
import { keyframes, Paper, Typography } from '@mui/material';
import { CallState, CallStateState, TimestampAction } from 'utils/calls';

interface IProps {
  callState: CallState,
  isSelected: boolean
  onSelect: () => any
}

const blinking = keyframes`
  from {
    background-color: #E80B0B;
  }
  to {
    background-color: #9E0000;
  }
`
const AdditionalCallCard: FC<IProps> = ({
  callState,
  isSelected,
  onSelect,
}: IProps) => {
  const {
    state,
    timestamps,
  } = callState;

  // If the call is onHold for more than 1 min, then start blinking
  const [shouldBlink, setShouldBlink] = useState<Boolean>(false)

  useEffect(() => {
    if (state !== CallStateState.ONHOLD) return setShouldBlink(false)

    const timestamp = timestamps.find(e => e.action === TimestampAction.HOLD)
    if (!timestamp) return setShouldBlink(false)

    const now = Date.now()
    if (now - timestamp.timestamp > (60 * 1000)) {
      return setShouldBlink(true)
    }

    const timeout = setTimeout(() => {
      setShouldBlink(true)
    }, 60 * 1000 - (now - timestamp.timestamp))

    return () => clearTimeout(timeout)
  }, [state, timestamps])

  const cardColor = useMemo(() => {
    switch (state) {
      case CallStateState.ANSWERED:
        return '#1033A5';

      case CallStateState.ONHOLD:
        return '#D79F2B';

      case CallStateState.WITH_CALLER:
      case CallStateState.TRANSFERRING:
      case CallStateState.WITH_CLIENT:
        return '#448C3D';

      default:
        return '#9E0000';
    }
  }, [state])

  return (
    <Paper
      elevation={0}
      sx={{
        px: '4px',
        bgcolor: cardColor,
        borderRadius: '5px',
        cursor: 'pointer',
        border: isSelected ? '2px solid white' : `2px solid grey`,
        animation: shouldBlink ? `${blinking} 1s infinite` : ''
      }}
      onClick={onSelect}
    >
      <Typography sx={{ fontSize: '12px', fontWeight: 700, color: 'white' }}>Outbound Call</Typography>
    </Paper>
  );
}

export default AdditionalCallCard