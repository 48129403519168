import { Autocomplete } from "@mui/material"
import { FC, useMemo } from "react";
import { FormQuestion } from "../utils";
import StyledTextField from "./StyledTextField";

type Option = {
  label: string,
  value: string,
}

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  error?: string
  options: Option[],
}

const AutocompletePicklistInput: FC<IProps> = (props) => {
  const { question, handleChange, value, options, error } = props;

  const handleInternalChange = (e: any, newValue: Option | null) => {
    handleChange(question.id, newValue?.value || '')
  }

  const selectedOption = useMemo(() => {
    return options.find(e => e.value === value)
  }, [value, options])

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      onChange={handleInternalChange}
      value={selectedOption || { label: '', value: 'Please Select' }}
      getOptionLabel={(option: Option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText="No results found"
      ChipProps={{ sx: { borderRadius: '25px' } }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          error={!!error}
          helperText={error}
          placeholder={"Please Select"}
          InputProps={{
            ...params.InputProps,
            sx: { bgcolor: '#E4ECFC', borderRadius: '25px' }
          }}
        />
      )}
    />
  )
}

export default AutocompletePicklistInput;
