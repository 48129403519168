import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Typography } from '@mui/material';
import { CallState, CallStateState, TimestampAction } from 'utils/calls';

interface IProps {
  callState: CallState,
  small?: boolean,
  color: string,
}

interface ITimerProps {
  baseTime?: number,
  counter?: number, // For onhold only
  accumulated?: number, // Added after basetime calculation
}

const CallTimer: FC<IProps> = ({ callState, small, color }) => {
  const { state, timestamps } = callState
  const timerRef = useRef<HTMLElement | null>(null);

  const calculated: ITimerProps = useMemo(() => {
    switch (state) {
      case CallStateState.UNANSWERED:
        return {
          baseTime: timestamps.find(e => [TimestampAction.RECEIVED, TimestampAction.REQUEUE].includes(e.action))?.timestamp
        }
        
      case CallStateState.ONHOLD:
      case CallStateState.WITH_CLIENT:
      case CallStateState.TRANSFERRING:
        const holdAction = state === CallStateState.ONHOLD ? TimestampAction.HOLD : TimestampAction.TRANSFER_TO_CLIENT
        return {
          baseTime: timestamps.find(e => e.action === holdAction)?.timestamp,
          counter: timestamps.filter(e => [TimestampAction.HOLD, TimestampAction.TRANSFER_TO_CLIENT].includes(e.action)).length
        }

      case CallStateState.ANSWERED:
      case CallStateState.WITH_CALLER:
        // Calculate all time on unanswered and answered state
        let accumulated = 0
        const sortedTimestamps = timestamps.slice().reverse()
        for (let index = 0; index < sortedTimestamps.length - 1; index++) {
          const current = sortedTimestamps[index]
          const next = sortedTimestamps[index + 1]

          if (!next) break

          // On requeue or received, reset the answered timer
          if ([TimestampAction.REQUEUE, TimestampAction.RECEIVED].includes(current.action)) {
            accumulated = 0
          }

          if ([
            TimestampAction.RECEIVED,
            TimestampAction.RINGING,
            TimestampAction.BROADCASTED,
            TimestampAction.ANSWER,
            TimestampAction.REQUEUE,
            TimestampAction.TRANSFER_TO_CALLER,
            TimestampAction.STOP_TRANSFER,
          ].includes(current.action)) {
            accumulated += next.timestamp - current.timestamp
          }
        }

        const currentAction = state === CallStateState.ANSWERED ? TimestampAction.ANSWER : TimestampAction.TRANSFER_TO_CALLER
        return {
          baseTime: timestamps.find(e => e.action === currentAction)?.timestamp,
          accumulated,
        }
      default:
        break;

    }
    return {}
  }, [state, timestamps])

  const { baseTime, counter, accumulated = 0 } = calculated

  useEffect(() => {
    if (!baseTime) return

    const updateTimer = () => {
      const now = Date.now()

      // Elapsed in seconds
      let timeElapsed = Math.floor((now - baseTime) / 1000) + Math.floor(accumulated / 1000)
      if (timeElapsed < 0) timeElapsed = 0
      const minutes = Math.floor(timeElapsed / 60)
      const seconds = timeElapsed % 60
      let timeString = String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0')
      if (counter) {
        timeString += `[${counter}]`
      }

      if (timerRef.current) {
        timerRef.current.textContent = timeString
      }
    }
    
    const interval = setInterval(() => {
      updateTimer()
    }, 1000)

    updateTimer()

    return () => clearInterval(interval)
  }, [baseTime, counter, accumulated])

  if (!baseTime) return null

  return (
    <Typography ref={timerRef} sx={{ fontSize: '13px', fontWeight: 600, color, lineHeight: '15px' }}>00:00</Typography>
  )
}

export default CallTimer