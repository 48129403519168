import { FC } from 'react';
import { Box } from '@mui/material';
import { getBlocks, getTeams } from 'api/callrecords';
import { searchUsers } from 'api/users';
import debounce from 'awesome-debounce-promise';
import { FilterItem } from 'pages/main/callHistory/useCallHistory';
import CallHistoryFilter from 'pages/main/callHistory/Filter';
import { ACTIVITY_TYPES, DIRECTIONS } from 'pages/main/callHistory/Filters';

interface IProps {
  selectedBlocks: FilterItem[],
  setSelectedBlocks: (items: FilterItem[]) => void,
  selectedTeams: FilterItem[],
  setSelectedTeams: (items: FilterItem[]) => void,
  selectedReceptionists: FilterItem[],
  setSelectedReceptionists: (items: FilterItem[]) => void,
  selectedActivityTypes: FilterItem[],
  setSelectedActivityTypes: (items: FilterItem[]) => void,
  selectedDirections: FilterItem[],
  setSelectedDirections: (items: FilterItem[]) => void,
}

const debouncedSearchUsers = debounce(searchUsers, 500)

export const CallHistoryFilters: FC<IProps> = ({
  selectedBlocks, setSelectedBlocks,
  selectedTeams, setSelectedTeams,
  selectedReceptionists, setSelectedReceptionists,
  selectedActivityTypes, setSelectedActivityTypes,
  selectedDirections, setSelectedDirections,
}) => {
  const formatReceptionistOptions = (options: any[]) => {
    return options
      .map((e: any) => ({ label: e.name, value: e.id }))
      .sort((a: FilterItem, b: FilterItem) => a.label > b.label ? 1 : -1)
  }

  return (
    <Box
      sx={{
        maxHeight: '100%',
        overflowY: 'auto',
        pr: 1,
        width: 'calc(100% - 8px)',
      }}
    >
      <Box>
        <CallHistoryFilter
          name="Blocks"
          selectedItems={selectedBlocks}
          setSelectedItems={setSelectedBlocks}
          fetchOptions={getBlocks}
          optionsFormatter={(items) => items.map((e: any) => ({ label: e, value: e }))}
        />
      </Box>
      <Box>
        <CallHistoryFilter
          name="Teams"
          selectedItems={selectedTeams}
          setSelectedItems={setSelectedTeams}
          fetchOptions={getTeams}
          optionsFormatter={(items) => items.map((e: any) => ({ label: e.name, value: e.id }))}
        />
      </Box>
      <Box>
        <CallHistoryFilter
          name="Receptionist"
          selectedItems={selectedReceptionists}
          setSelectedItems={setSelectedReceptionists}
          search
          fetchOptions={(search?: string) => debouncedSearchUsers(search as string, 20, true)}
          optionsFormatter={formatReceptionistOptions}
        />
      </Box>
      <Box>
        <CallHistoryFilter
          name="Activity Type"
          selectedItems={selectedActivityTypes}
          setSelectedItems={setSelectedActivityTypes}
          defaultOptions={ACTIVITY_TYPES}
          optionsFormatter={items => items}
        />
      </Box>
      <Box>
        <CallHistoryFilter
          name="Direction"
          selectedItems={selectedDirections}
          setSelectedItems={setSelectedDirections}
          defaultOptions={DIRECTIONS}
          optionsFormatter={items => items}
        />
      </Box>

    </Box>
  );
}

export default CallHistoryFilters;