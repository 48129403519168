import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { getAccount, getFaqs } from "api/accounts";
import { Account, ACCOUNT_PANEL_STATUS, Faq, LAST_ACCOUNT_STORAGE } from "utils/accounts";
import { setAccountPanelStatus, setActiveAccount, setOpenAccountId } from "reducers/slices/accountPanelReducer";
import { useAppDispatch, useAppSelector } from "reducers/hooks";
import { selectedCallSelector } from "reducers/selector/callSelector";
import { getActiveCall, getSelectedCall } from "reducers/thunks/callThunks";
import { AppThunkDispatch } from "reducers/types";
import debounce from "awesome-debounce-promise";
import { getAccountCache } from "reducers/thunks/accountThunk";
import { setCache } from "reducers/slices/cacheReducer";
import { logger } from "utils/utils";
import { CallType } from "utils/calls";
const DEBUG = false

const debouncedGetAccount = debounce(getAccount, 500)
const debouncedGetFaqs = debounce(getFaqs, 500)

/**
 * Check if there are any active call, or selected call
 * and fetch and select the account detail for said call.
 */
const useAccountsFetcher = () => {
  const selectedCall = useAppSelector(selectedCallSelector)
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const openAccountId = useSelector((state: RootState) => state.accountPanel.openAccountId)
  const [lastFetchedId, setLastFetchedId] = useState<string>('')
  const fetchingAccountId = useRef<string | null>(null);
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    const lastAccountId = localStorage.getItem(LAST_ACCOUNT_STORAGE)
    if (lastAccountId) {
      fetchAccountDetails(lastAccountId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedCall?.type === CallType.OUTBOUND || selectedCall?.type === CallType.UNBOUND) {
      DEBUG && logger('Skipped fetching selected call account details as it is an outbound call, accountId: ' + selectedCall?.accountId)
      return
    }

    DEBUG && logger('Fetching selected account details due to selectedCall changes, accountId: ' + selectedCall?.accountId)
    fetchSelectedCallAccount()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCall?.accountId])

  useEffect(() => {
    if (openAccountId) {
      // Re-fetch latest account, used on connection error
      if (openAccountId === 'last') {
        DEBUG && logger('Fetching account details as requested to re-fetch latest account, accountId: ' + lastFetchedId)
        dispatch(setOpenAccountId(''))
        fetchAccountDetails(lastFetchedId)
        return
      }

      if (openAccountId === 'call') {
        DEBUG && logger('Fetching account details due to call select, accountId: ' + selectedCall?.accountId)
        dispatch(setOpenAccountId(''))
        fetchSelectedCallAccount()
        return
      }

      if (account.id !== openAccountId) {
        DEBUG && logger('Fetching account details as requested by global search, accountId: ' + openAccountId)
        dispatch(setOpenAccountId(''))
        fetchAccountDetails(openAccountId, true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAccountId, lastFetchedId])

  const fetchSelectedCallAccount = () => {
    const activeCall = thunkDispatch(getSelectedCall()) || thunkDispatch(getActiveCall())

    if (activeCall?.accountId) {
      DEBUG && logger('Fetching selected call account details', { activeCallAccountId: activeCall.accountId, activeAccountId: account.id })
      fetchAccountDetails(activeCall.accountId)
    }
  }

  const fetchAccountDetails = async (accountId: string, isFromGlobalSearch?: boolean) => {
    DEBUG && logger('Fetching account details', { requestedAccountId: accountId, activeAccountId: account.id })
    fetchingAccountId.current = accountId

    let accountDetails

    try {
      const cachedAccount = thunkDispatch(getAccountCache(accountId))
      // Always use fresh account if it's from global search
      if (cachedAccount && !isFromGlobalSearch) {
        accountDetails = {
          ...cachedAccount,
        }
      } else {
        dispatch(setAccountPanelStatus(ACCOUNT_PANEL_STATUS.LOADING))
        // @ts-ignore
        dispatch(setActiveAccount({}))

        const res = await Promise.all([
          debouncedGetAccount(accountId),
          debouncedGetFaqs(accountId),
        ])
        const account: Account = res[0].data
        const faqs: Faq[] = res[1].data.data
        if (account) {
          account.faqs = faqs
          if (!isFromGlobalSearch) {
            dispatch(setCache({ key: account.id, value: account }))
          }

          DEBUG && logger('Account fetched', { accountId: account.id, fetchingAccountId: fetchingAccountId.current })
          // Another account was loaded during this, we should skip loading this one
          if (fetchingAccountId.current !== accountId) {
            return
          }

          accountDetails = {
            ...account,
            isFromGlobalSearch,
          }
        }
      }
    } catch (err) {
      logger(err)
    }

    setLastFetchedId(accountId)

    if (accountDetails) {
      dispatch(setActiveAccount(accountDetails))
      dispatch(setAccountPanelStatus(ACCOUNT_PANEL_STATUS.LOADED))
      localStorage.setItem(LAST_ACCOUNT_STORAGE, accountId)
    } else {
      dispatch(setAccountPanelStatus(ACCOUNT_PANEL_STATUS.NOT_FOUND))
    }
  }

  return null
}

export default useAccountsFetcher