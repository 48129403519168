import { useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Typography, keyframes } from "@mui/material";
import { AccessTime, CallEnd, RingVolume, Warning } from "@mui/icons-material";
import moment from "moment-timezone";
import DisconnectAlert from "components/DisconnectAlert";
import { useAppSelector } from "reducers/hooks";

const blinking = keyframes`
  to {
    visibility: hidden;
  }
`

export const HEADER_HEIGHT = 68

const Warnings = ({ color }: { color: string }) => {
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const [unassignedTooltipShown, setUnassignedTooltipShown] = useState<boolean>(false)
  const noConnection = useAppSelector(state => state.ui.noConnection);
  const noAudioPerm = useAppSelector(state => state.ui.noAudioPerm);
  const noMic = useAppSelector(state => state.ui.noMic);
  const unassignedCallsLength = useAppSelector(state =>
    state.callSlot.unassignedCalls.length + state.callSlot.priorityUnassignedCalls.length
  );

  useEffect(() => {
    if (!callEnabled) return
    setUnassignedTooltipShown(true)
    const timeout = setTimeout(() => setUnassignedTooltipShown(false), 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [unassignedCallsLength, callEnabled])

  const isNotLocal = useMemo(() => {
    const now = moment()
    const local = moment().local(false)

    return now.format() !== local.format()
  }, [])

  return (
    <Box display="flex" alignItems="center">
      {(callEnabled && !!unassignedCallsLength) &&
        <Tooltip
          title={`There are ${unassignedCallsLength} hidden unanswered call(s)`}
          arrow
          open={unassignedTooltipShown}
          onMouseEnter={() => setUnassignedTooltipShown(true)}
          onMouseLeave={() => setUnassignedTooltipShown(false)}
        >
          <Box
            sx={{
              ml: '12px',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              color,
            }}
          >
            <RingVolume sx={{ animation: `${blinking} 1s steps(5, start) infinite` }} />
            <CallEnd sx={{ position: 'absolute', bottom: -5 }} />
            <Typography style={{ position: 'absolute', bottom: -9, fontSize: '10px' }}>{unassignedCallsLength}</Typography>
          </Box>
        </Tooltip>
      }
      {isNotLocal &&
        <Tooltip title="We have detected that your local time is not in Pacific Time (PST/PDT). Times shown in the app are in Pacific Time.">
          <AccessTime sx={{ color, ml: '12px' }} />
        </Tooltip>
      }
      {noAudioPerm &&
        <>
          <Warning sx={{ color, mr: '4px', ml: '12px' }} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color
            }}
          >
            Microphone disabled. Please allow microphone permission and refresh the app.
          </Typography>
        </>
      }
      {noMic &&
        <>
          <Warning sx={{ color, mr: '4px', ml: '12px' }} />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color
            }}
          >
            No microphone found. Please check your system and refresh the app.
          </Typography>
        </>
      }
      {noConnection &&
        <DisconnectAlert />
      }
    </Box>
  );
};

export default Warnings;
