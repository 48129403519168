import { FC } from "react";
import { Box, ListItemButton } from "@mui/material";
import { Contact } from "utils/accounts";
import ContactStatus from "./contactItem/ContactStatus";
import ContactNumbers from "./contactItem/ContactNumbers";
import ContactNumbersStatic from "./contactItem/ContactNumbersStatic";

interface IProps {
  search: string,
  contact: Contact,
  isSelected: boolean,
  isGloballySelected: boolean,
  onSelect?: (contact: Contact) => void,
}

const ContactListItem: FC<IProps> = ({ contact, isSelected, isGloballySelected, onSelect, search }) => {
  return (
    <ListItemButton
      id={contact.id}
      key={contact.id}
      onClick={() => onSelect && onSelect(contact)}
      sx={{
        display: 'block',
        px: 2, py: 1,
        cursor: 'pointer',
        border: '2px solid',
        borderBottom: 'none',
        borderColor: isSelected ? '#A5B5E3' : 'transparent',
        borderTopColor: '#A5B5E3',
        backgroundColor: isGloballySelected ? 'mark'
          : isSelected ? '#E4ECFC !important'
            : '#F5F8FD',
        '&:hover': {
          backgroundColor: '#E4ECFC !important',
        },
        '&:last-of-type': {
          border: '2px solid',
          borderColor: isSelected ? '#A5B5E3' : 'transparent',
          borderTopColor: '#A5B5E3',
          borderBottomColor: '#A5B5E3',
        },
        transition: 'border 0.5s',
      }}
    >
      <Box display="flex" alignItems="center">
        <Box width="100%">
          <ContactStatus contact={contact} search={search} />
          {isSelected ?
            <ContactNumbers contact={contact} search={search} />
            :
            <ContactNumbersStatic contact={contact} search={search} />
          }
        </Box>
      </Box>
    </ListItemButton>
  )
}

export default ContactListItem;
