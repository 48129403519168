import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface CacheState {
  [key: string]: any,
}

const initialState: CacheState = {
};

interface CachePayload {
  key: string,
  value: any,
}

export const CacheSlice = createSlice({
  name: "cache",
  initialState,
  reducers: {
    setCache: (state, action: PayloadAction<CachePayload>) => {
      const { key, value } = action.payload
      state[key] = value 
    },
    removeCache: (state, action: PayloadAction<string>) => {
      const key = action.payload
      state[key] = undefined
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCache, removeCache
} = CacheSlice.actions;

export default CacheSlice.reducer;
