import axios, { AxiosInstance } from 'axios'
import { SnackbarSeverity, setLoginError, setSnackbar } from 'reducers/slices/UIReducer';
import { logout } from 'reducers/slices/userReducer';
import { store } from 'reducers/store';
import { logger } from 'utils/utils';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
})

const ccInstance = axios.create({
  baseURL: process.env.REACT_APP_CC_API,
})

const setInstanceInterceptors = (instance: AxiosInstance) => {
  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    config.headers = config.headers || {}
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  })

  instance.interceptors.response.use(undefined,
    (error) => {
      logger(error)
      if (error.response?.status === 401) {
        store.dispatch(logout())
        store.dispatch(setLoginError('Your session has expired, please login again.'))

        return Promise.reject(error)
      }

      const message = error.response?.data || error.message
      if (typeof message === 'string') {
        store.dispatch(setSnackbar({ message, severity: SnackbarSeverity.ERROR }))
      }
  
      if (typeof message === 'object') {
        if (message.name === 'ValidationError') {
          store.dispatch(setSnackbar({ message: message.details.body[0].message, severity: SnackbarSeverity.ERROR }))
        }
      }
    
      return Promise.reject(error)
    }
  )
}

setInstanceInterceptors(instance)
setInstanceInterceptors(ccInstance)

export default instance
export const ccAgent = ccInstance