import { Box } from "@mui/material";
import AbbyForm from "abbyForms/Form";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { removeTask } from "reducers/slices/accountPanelReducer";
import { setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";

interface IProps {
  taskId: string,
  link: string,
  isSelected: boolean,
}

const TaskAbbyForm: FC<IProps> = ({ taskId, link, isSelected }) => {
  const [shouldLoad, setShouldLoad] = useState<boolean>(false)
  const dispatch = useDispatch()

  // Intentionally not loading until user selected the task
  // Prevent user with huge amount of tasks from requesting all forms info at the same time.
  useEffect(() => {
    if (isSelected) {
      setShouldLoad(true)
    }
  }, [isSelected])

  const linkParams = useMemo(() => {
    const splitLink = link.split('/')

    const formIndex = splitLink.findIndex(e => e.toLowerCase() === 'form')
    if (formIndex === -1) return {}

    const formIdAndParams = splitLink[formIndex + 1]
    if (!formIdAndParams) return {}

    const formId = formIdAndParams.split('?')[0]
    const params = new URL(link).searchParams

    return {
      formId,
      token: params.get('token'),
      callerNumber: params.get('callerNumber'),
      callerName: params.get('callerName'),
      createdByName: params.get('createdByName'),
      contactID: params.get('contactID'),
      type: params.get('type'),
    }
  }, [link])

  const handleAfterSubmit = (callerNumber: string, accountName: string) => {
    dispatch(setSnackbar({ message: 'Form submitted!', severity: SnackbarSeverity.SUCCESS }))
    dispatch(removeTask(taskId))
  }

  if (!shouldLoad) {
    return null
  }

  return (
    <Box width="100%" height="calc(100% - 36px)" sx={{ overflowY: 'auto' }}>
      <AbbyForm
        {...linkParams}
        afterSubmit={handleAfterSubmit}
        isRendered={isSelected}
      />
    </Box>
  )
}

export default TaskAbbyForm;
