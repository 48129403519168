import { Autocomplete } from "@mui/material"
import { FC, useMemo } from "react";
import { FormQuestion } from "../utils";
import StyledTextField from "./StyledTextField";

type Option = {
  label: string,
  value: string,
}

interface IProps {
  question: FormQuestion,
  handleChange: (questionId: string, value: string) => void,
  value: string,
  error?: string,
  options: Option[],
}

const AutocompletePicklistMultipleInput: FC<IProps> = (props) => {
  const { question, handleChange, value, options, error } = props;

  const handleInternalChange = (e: any, newValues: Option[] | null) => {
    let newValue = ''
    if (newValues) {
      newValue = newValues.map(e => e.value).join(',')
    }
    handleChange(question.id, newValue)
  }

  const selectedOptions = useMemo(() => {
    return options.filter(e => value.includes(e.value))
  }, [value, options])

  return (
    <Autocomplete
      fullWidth
      size="small"
      multiple
      limitTags={10}
      options={options}
      onChange={handleInternalChange}
      value={selectedOptions}
      disableCloseOnSelect
      getOptionLabel={(option: Option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      noOptionsText="No results found"
      ChipProps={{ sx: { borderRadius: '25px' } }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          error={!!error}
          helperText={error}
          placeholder={"Please Select"}
          InputProps={{
            ...params.InputProps,
            sx: { bgcolor: '#E4ECFC', borderRadius: '25px' }
          }}
        />
      )}
    />
  )
}

export default AutocompletePicklistMultipleInput;
