import { useEffect, useState } from 'react';
import { Box, Drawer, IconButton } from '@mui/material';
import CallHistoryFilters from './Filters';
import { Close } from '@mui/icons-material';
import CallHistoryHistory from './History';
import { getCallRecords } from 'api/callrecords';
import { CallRecord } from 'utils/calls';
import { resetNewCallHistoryCount, setIsViewingGlobalCallHistory } from 'reducers/slices/callHistoryReducer';
import useCallHistory, { MAX_HISTORY_ITEMS } from './useCallHistory';
import { setIsGlobalHistoryOpen } from 'reducers/slices/UIReducer';
import { useAppDispatch, useAppSelector } from 'reducers/hooks';
import { AppThunkDispatch } from 'reducers/types';
import { getNewCallHistoryCount } from 'reducers/thunks/callHistoryThunk';

export const GlobalCallHistory = () => {
  const {
    startDate, setStartDate,
    endDate, setEndDate,
    selectedBlocks, setSelectedBlocks,
    selectedTeams, setSelectedTeams,
    selectedReceptionists, setSelectedReceptionists,
    selectedActivityTypes, setSelectedActivityTypes,
    selectedAccounts, setSelectedAccounts,
    selectedDirections, setSelectedDirections,
    otherFilters, setOtherFilters,
    callRecords, setCallRecords,
    hasMore, setHasMore,
    callRecordsQuery, refreshHistory, loadNextPage
  } = useCallHistory({ isGlobal: true })

  const [isLoadingNewData, setIsLoadingNewData] = useState<boolean>(false)

  const callHistoryOpen = useAppSelector(state => state.ui.isGlobalHistoryOpen)
  const globalFilterOverride = useAppSelector(state => state.callHistory.filters)
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    if (callHistoryOpen && !callRecords.length) {
      refreshHistory()
    }
    dispatch(setIsViewingGlobalCallHistory(callHistoryOpen))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callHistoryOpen])

  const setCallHistoryOpen = (isOpen: boolean) => {
    dispatch(setIsGlobalHistoryOpen(isOpen))
  }

  useEffect(() => {
    if (!callHistoryOpen && Object.keys(globalFilterOverride).length) {
      setSelectedBlocks([])
      setSelectedTeams([])
      setSelectedReceptionists([])
      setSelectedActivityTypes([])
      setSelectedAccounts([])
      setSelectedDirections([])

      const globalFilter = { ...globalFilterOverride }
      if (globalFilter.account) {
        setSelectedAccounts([globalFilter.account])
        delete globalFilter.account
      }

      setCallHistoryOpen(true)
      setOtherFilters({ ...globalFilter })
      refreshHistory()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterOverride])

  const loadNewerData = () => {
    setIsLoadingNewData(true)
    const newCallHistoryCount = thunkDispatch(getNewCallHistoryCount())
    getCallRecords({
      ...callRecordsQuery,
      limit: newCallHistoryCount + 10,
      page: 1,
    }).then(res => {
      dispatch(resetNewCallHistoryCount())
      setIsLoadingNewData(false)

      const filteredRecords = res.data.filter((newCall: CallRecord) => !callRecords.find(call => call.id === newCall.id))
      const newCallRecords = filteredRecords.concat(callRecords)
      setCallRecords(newCallRecords)
      if (newCallRecords.length >= MAX_HISTORY_ITEMS) {
        setHasMore(false)
      }
    })
  }

  return (
    <Drawer
      anchor="bottom"
      open={callHistoryOpen}
      keepMounted
      onClose={() => setCallHistoryOpen(false)}
      PaperProps={{
        sx: {
          m: '12px',
          mt: 0,
          borderRadius: '5px',
          // 100vh - header - body top pad - call cards - greeting - call buttons - drawer bottom margin
          height: 'calc(100vh - 68px - 12px - 118px - 56px - 54px - 12px)',
        }
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        onClick={() => setCallHistoryOpen(false)}
      >
        <Close sx={{ color: '#E80B0B' }} />
      </IconButton>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
        }}
      >
        <CallHistoryFilters
          selectedBlocks={selectedBlocks} setSelectedBlocks={setSelectedBlocks}
          selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}
          selectedReceptionists={selectedReceptionists} setSelectedReceptionists={setSelectedReceptionists}
          selectedActivityTypes={selectedActivityTypes} setSelectedActivityTypes={setSelectedActivityTypes}
          selectedAccounts={selectedAccounts} setSelectedAccounts={setSelectedAccounts}
          selectedDirections={selectedDirections} setSelectedDirections={setSelectedDirections}
        />

        <CallHistoryHistory
          isGlobal
          callRecords={callRecords}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}

          hasMore={hasMore}
          loadMore={loadNextPage}
          loadNewerData={loadNewerData}
          isLoadingNewData={isLoadingNewData}
          refreshHistory={refreshHistory}

          setCallHistoryOpen={setCallHistoryOpen}
          otherFilters={otherFilters}
          setOtherFilters={setOtherFilters}
        />
      </Box>
    </Drawer>    
  );
}

export default GlobalCallHistory;