import { useMemo } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { addTask } from "reducers/slices/accountPanelReducer";
import {
  FormLink,
  formatTimezone,
  getAccountFormLinks,
  getAddress,
  parseHoursDaysOfOperation,
  parseHolidayHoursOfOperation,
} from 'utils/accounts';
import CopyButton from "components/buttons/CopyButton";
import moment from "moment-timezone";
import { formatDate, removeLastWord, stringContainsHtmlTags } from 'utils/utils';
import { handleSelectedCallLink } from "reducers/thunks/accountThunk";
import { AppThunkDispatch } from "reducers/types";

const Title = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#1033A5',
  lineHeight: '28px',
}))

const LinkText = styled(Typography)(() => ({
  color: '#1033A5',
  fontWeight: 500,
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
}))

const Info = (props: { label: string, value?: string | null, copy?: boolean }) => (
  <Typography sx={{ fontWeight: 600, fontSize: '12px', mb: '4px' }}>{`${props.label}: `}
    <Typography component="span" style={{ fontWeight: 400, fontSize: '12px' }}>{props.value}</Typography>
    {(props.value && props.copy) && <CopyButton text={props.value || ''} />}
  </Typography>
)

const SideInfo = ({ showCustomHours, showCustomHolidayHours }: {
  showCustomHours: () => void,
  showCustomHolidayHours: () => void,
}) => {
  const account = useSelector((state: RootState) => state.accountPanel.activeAccount)
  const selectedContact = useSelector((state: RootState) => state.accountPanel.selectedContact)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  const links = useMemo(() => {
    return getAccountFormLinks(account.hostedsuiteLinks || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.hostedsuiteLinks])

  const contactLinks = useMemo(() => {
    return getAccountFormLinks(selectedContact?.hostedSuiteLinks || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact.hostedSuiteLinks])

  const handleLink = (link: FormLink) => {
    thunkDispatch(handleSelectedCallLink(link))
  }

  const handleMapLink = (address: string, name = 'Address') => {
    if (address) {
      let link = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${encodeURI(address)}`
      if (account.shippingLatitude && account.shippingLongitude) {
        link += `&center=${account.shippingLatitude},${account.shippingLongitude}`
      }

      dispatch(addTask({
        name: name + ' - ' + account.name,
        link,
        accountId: account.id,
        accountName: account.name,
      }))
    }
  }

  const hoursDaysOfOperation = parseHoursDaysOfOperation(account.hoursDaysOfOperation)
  const holidayHoursOfOperation = parseHolidayHoursOfOperation(account.holidayHoursOfOperation)

  const getActiveHours = () => {
    /**
     * CCAA-1000 Moment.tz acts very weirdly here.
     * The commented line below should work, but somehow it's always 24 hours behind the startTime and endTime
     * Probably some timezone complexity or something.
     * Converting it into HH:mm A beforehand ensure that the same conversion happen on now, startTime, and endTime.
     * const now = moment().tz(formatTimezone(account.timeZone), false)
     */
    const nowHours = moment().tz(formatTimezone(account.timeZone), false).format('HH:mm A')
    const now = moment.tz(nowHours, 'HH:mm A', formatTimezone(account.timeZone))

    const today = now.format('dddd')
    const activeHours = hoursDaysOfOperation.find(e => e.title === today)
    if (!activeHours?.isChecked) return { isOpen: false, title: today }

    const startTime = moment.tz(activeHours.start || '', 'HH:mm A', formatTimezone(account.timeZone))
    let endTime = moment.tz(activeHours.end || '', 'HH:mm A', formatTimezone(account.timeZone))
    if (endTime.isSameOrBefore(startTime)) {
      endTime = endTime.add(1, 'day')
    }
    const isOpen = now.isBetween(startTime, endTime)

    return {
      isOpen,
      title: today,
      start: moment(activeHours.start, 'HH:mm A').format('hh:mm A'),
      end: moment(activeHours.end, 'HH:mm A').format('hh:mm A'),
    }
  }

  const activeHours = getActiveHours()
  const address = getAddress(account)
  return (
    <Box width="100%">
      {!!links.length &&
        <Box mb={3}>
          <Title sx={{ fontSize: '24px' }}>Links</Title>
          {links.map((link, index) => (
            <LinkText
              key={link.link + index}
              sx={{ mt: '4px' }}
              onClick={() => handleLink(link)}
            >
              {link.name}
            </LinkText>
          ))}
        </Box>
      }

      {!!contactLinks.length &&
        <Box mb={3}>
          <Title sx={{ fontSize: '24px' }}>Contact Links</Title>
          {contactLinks.map((link, index) => (
            <LinkText
              key={link.link + index}
              sx={{ mt: '4px' }}
              onClick={() => handleLink(link)}
            >
              {link.name}
            </LinkText>
          ))}
        </Box>
      }

      <Box mb={3}>
        <Title>General Info</Title>
        <Info label="Main Business Line" value={account.mainBusinessPhoneNumber} copy />
        {account.mainBusinessPhoneNumber2 &&
          <Info label="Main Business Line 2" value={account.mainBusinessPhoneNumber2} copy />
        }
        {account.oKToGiveOutFax !== 'No' &&
          <Info label="Fax Number" value={account.fax} copy />
        }

        <Info label="How Long in Business" value={account.howLongInBusiness} />
        <Info label="Time Zone" value={account.timeZone} />

        {account.customHoursDaysOfOperation2 &&
          <Typography sx={{ fontWeight: 600, fontSize: '12px', mb: '4px' }}>
            {'Hours/Days of Operation: '}
            <LinkText
              // @ts-ignore
              component="span"
              onClick={showCustomHours}
            >
              {'View Hours/Days in FAQs'}
            </LinkText>
          </Typography>
        }

        {!account.customHoursDaysOfOperation2 &&
          <>
            <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>
              {'Hours/Days of Operation: '}
              <Typography component="span" style={{ fontWeight: 400, fontSize: '12px' }}>
                {!hoursDaysOfOperation.length && 'N/A'}
              </Typography>
            </Typography>
            {hoursDaysOfOperation.map(hours => (
              <Typography
                key={hours.title}
                sx={{
                  fontWeight: activeHours.title === hours.title ? 600 : 400,
                  fontSize: '12px',
                  pl: 2, mb: '2px',
                }}
              >
                <span style={{ display: 'inline-block', width: 100 }}>
                  {`${hours.title}`}
                </span>

                {hours.isChecked ?
                  <span>{`${hours.start} - ${hours.end}`}</span>
                  :
                  <span style={activeHours.title === hours.title ? { color: '#E80B0B' } : {}}>
                    Closed
                  </span>
                }

                {(hours.isChecked && activeHours.title === hours.title) &&
                  <span style={{ display: 'inline-block', marginLeft: 8 }}>
                    {activeHours.isOpen ?
                      <span style={{ color: '#448C3D' }}>Open</span>
                      :
                      <span style={{ color: '#E80B0B' }}>Closed</span>
                    }
                  </span>
                }
              </Typography>
            ))}
          </>
        }

        {account.customHolidayHoursOfOperation &&
          <Typography sx={{ fontWeight: 600, fontSize: '12px', mb: '4px' }}>
            {'Holiday Hours/Days of Operation: '}
            <LinkText
              // @ts-ignore
              component="span"
              onClick={showCustomHolidayHours}
            >
              {'View Holiday Hours/Days in FAQs'}
            </LinkText>
          </Typography>
        }

        {!account.customHolidayHoursOfOperation && holidayHoursOfOperation.length > 0 &&
          <>
            <Typography sx={{ fontWeight: 600, fontSize: '12px' }}>
              {'Holiday Hours/Days of Operation: '}
            </Typography>
            {holidayHoursOfOperation.map((hours, index) => {
              const formattedTimezone = formatTimezone(account.timeZone)
              const isTodayHoliday = moment.tz(hours.holidayDate, formattedTimezone).isSame(
                moment.tz(formattedTimezone).startOf('day'),
                'day'
              );  // Check if holidayDate matches today
              return (
                <Typography
                  key={hours.title}
                  sx={{
                    fontWeight: isTodayHoliday ? 600 : 400, // Bold font for active holiday
                    fontSize: '12px',
                    pl: 2, mb: '2px',
                    backgroundColor: index % 2 === 0 ? 'grey.200' : 'white',
                  }}
                >
                  <Box display="flex" alignItems="top">
                    <span style={{ display: 'inline-block', width: 150, textAlign: 'left', marginRight: 10 }}>
                      {`${removeLastWord(hours.title, 'Afternoon', ['New Year Eve Afternoon'])}`}
                    </span>
                    <Box textAlign="right" marginLeft="auto">
                      {hours.isChecked ? (
                        <span>{`${hours.start} - ${hours.end}`}</span>
                      ) : (
                        <span style={{ color: '#E80B0B' }}>Closed</span>
                      )}
                      {isTodayHoliday && hours.isChecked && (
                        <span style={{ display: 'inline-block', marginLeft: 8, color: '#448C3D' }}>
                          Open
                        </span>
                      )}
                    </Box>
                  </Box>
                </Typography>
              );
            })}
          </>
        }

        <Typography sx={{ fontWeight: 600, fontSize: '12px', mb: '4px' }}>{'Website: '}
          <LinkText
            // @ts-ignore
            component="span"
            onClick={() => handleLink({ name: 'Website', link: account.website || '' })}
          >
          {account.website}
          </LinkText>
        </Typography>

        <Info label="Business Start Date" value={formatDate(account.businessStartDate)} />
        <Info label="Call Allowance" value={account.callAllowance?.toString()} />
      </Box>

      {(address && account.okToGiveOutPhysicalAddress !== 'No') &&
        <Box mb={3}>
          <Box display="flex" alignItems="center">
            <Title>Address</Title>
          </Box>
          {stringContainsHtmlTags(address) ?
            <Typography sx={{ mt: '4px', fontSize: '12px' }} component="div">
              <div dangerouslySetInnerHTML={{ __html: address || '' }}></div>
            </Typography>
            :
            (account.shippingPostalCode || (account.shippingLongitude && account.shippingLatitude)) ?
              <LinkText
                sx={{ mt: '4px' }}
                onClick={() => handleMapLink(address)}
              >
                {address}
              </LinkText>
              :
              <Typography sx={{ mt: '4px', fontSize: '12px' }}>{address}</Typography>
          }
        </Box>
      }

      {(account.directionsToYourOffice && account.okToGiveOutPhysicalAddress !== 'No') &&
        <Box mb={3}>
          <Title>Directions to the office</Title>
          <Typography sx={{ mt: '4px', fontSize: '12px' }} component="div">
            <div dangerouslySetInnerHTML={{ __html: account.directionsToYourOffice || '' }}></div>
          </Typography>
        </Box>
      }

      <Box mb={3}>
        <Title>Abby Info</Title>
        <Info label="Abby Teams" value={account.abbyTeam ?? (account.abbyBlock ? `Block ${account.abbyBlock}` : 'No teams or block set')} />
        <Info label="EVO Levels" value={account.evoLevel ?? 'EVO levels not set'} />
        <Info label="Calibration Specialist" value={account.calibrationSpecialist} />
        <Info label="Customer Success Manager" value={account.accountManagerName} />
        <Info label="Sales Person" value={account.salesConsultantName} />
      </Box>
    </Box>
  );
};

export default SideInfo;
