import { AppBar, Box, Button, MenuItem, Select, Typography } from "@mui/material";
import logo from '../../assets/logo.svg';
import { History } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewRoute } from "utils/utils";
import { setIsGlobalHistoryOpen } from "reducers/slices/UIReducer";
import UserMenu from "./settings/UserMenu";
import Warnings from "./Warnings";

export const HEADER_HEIGHT = 68

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const navigate = useNavigate()

  if (location.pathname === ViewRoute.RECEPTIONIST_VIEW) {
    return null
  }

  return (
    <AppBar elevation={0} position="relative" style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 1,
          backgroundColor: 'white',
          width: 'calc(100% - 16px)',
          alignItems: 'center',
          height: HEADER_HEIGHT - 16,
        }}
      >
        <Box pt="4px" pl={1} mb="-10px">
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(ViewRoute.RECEPTIONIST_VIEW)}
          >
            <img src={logo} width={80} height={40} alt="logo" />
          </Box>
          <Typography
            sx={{
              mt: '-4px',
              fontSize: '10px',
              lineHeight: '10px',
              color: 'black',
              textAlign: 'right',
            }}
          >
            v{process.env.REACT_APP_VERSION || '1.0.0'}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Warnings color="#FF3737" />
          <Select
            size="small"
            sx={{
              width: 150, mx: 2,
              backgroundColor: '#F9FBFF',
              borderColor: '#E3EBFC'
            }}
            value={location.pathname}
          >
            <MenuItem onClick={() => navigate(ViewRoute.RECEPTIONIST_VIEW)} value={ViewRoute.RECEPTIONIST_VIEW}>My View</MenuItem>
            <MenuItem onClick={() => navigate(ViewRoute.ACTIVITY_VIEW)} value={ViewRoute.ACTIVITY_VIEW}>Activity View</MenuItem>
            <MenuItem onClick={() => navigate('/my-account')} value="/my-account">My Account</MenuItem>
          </Select>

          <Button
            variant="contained"
            sx={{ mr: 2, height: 38 }}
            startIcon={<History sx={{ color: '#1033A5' }} />}
            onClick={() => dispatch(setIsGlobalHistoryOpen(true))}
          >
            Call History
          </Button>

          <UserMenu />
        </Box>
      </Box>
    </AppBar>
  );
};

export default Header;
