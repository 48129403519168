import { ContentCopy } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useState } from "react"

const CopyButton = (props: { text: string }) => {
  const [showCopied, setShowCopied] = useState<boolean>(false)

  const noBubbleUp = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const copyText = (e: React.MouseEvent<any>) => {
    noBubbleUp(e)

    setShowCopied(true)
    navigator.clipboard.writeText(props.text)

    setTimeout(() => {
      setShowCopied(false)
    }, 1000)
  }

  return (
    <Tooltip arrow placement="top" title={showCopied ? 'Copied!' : 'Copy'}>
      <span>
        <IconButton
          sx={{ p: 0, ml: '4px' }}
          onClick={copyText}
          onMouseDown={noBubbleUp}
        >
          <ContentCopy sx={{ fontSize: '14px' }} />
        </IconButton>
      </span>
    </Tooltip>
  )
}

export default CopyButton