import { FC, useEffect, useState } from 'react';
import { Box, Button, Dialog, IconButton, InputAdornment, ListItemButton, Typography } from '@mui/material';
import { DateCalendar, DateField } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment-timezone';
import { ArrowRightAlt, Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { SnackbarSeverity, setSnackbar } from 'reducers/slices/UIReducer';

export const getDateRangeLabel = (startDate: Moment | null, endDate: Moment | null) => {
  let startLabel = ''
  let endLabel = ''
  if (startDate) startLabel = startDate.format('MM/DD/YYYY')
  if (endDate) endLabel = endDate.format('MM/DD/YYYY')

  if (startLabel && endLabel) return startLabel + ' - ' + endLabel
  if (!startLabel && endLabel) return 'Up to ' + endLabel
  if (startLabel && !endLabel) return 'From ' + startLabel
  return ''
}

interface DateOption {
  label: string,
  type: string,
  amount?: number,
  unit?: any,
}

const dateOptions = [
  { label: 'Last 7 days', type: 'subtract', amount: 7, unit: 'days' },
  { label: 'Last 14 days', type: 'subtract', amount: 14, unit: 'days' },
  { label: 'Last 30 days', type: 'subtract', amount: 30, unit: 'days' },
  { label: 'Last 3 months', type: 'subtract', amount: 3, unit: 'months' },
  { label: 'Last 12 months', type: 'subtract', amount: 12, unit: 'months' },
  { label: 'Month to date', type: 'month-to-date' },
  { label: 'Quarter to date', type: 'quarter-to-date' },
  // { label: 'All time', type: 'all-time' },
  { label: 'Custom', type: 'custom' },
]

interface IProps {
  open: boolean,
  onClose: () => void,
  startDate: Moment | null,
  setStartDate: (date: Moment | null) => void,
  endDate: Moment | null,
  setEndDate: (date: Moment | null) => void,
}

export const DateRangePicker: FC<IProps> = ({
  open, onClose,
  startDate, setStartDate,
  endDate, setEndDate,
}) => {
  const [internalStartDate, setInternalStartDate] = useState<Moment | null>(moment().startOf('day'))
  const [internalEndDate, setInternalEndDate] = useState<Moment | null>(moment().endOf('day'))
  const [selectedOption, setSelectedOption] = useState<string>('Last 12 months') // Default last year
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      setInternalStartDate(startDate)
      setInternalEndDate(endDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleChangeStartDate = (value: Moment | null) => {
    setSelectedOption('Custom')
    setInternalStartDate(value?.startOf('day') || null)
  }

  const handleChangeEndDate = (value: Moment | null) => {
    setSelectedOption('Custom')
    setInternalEndDate(value?.endOf('day') || null)
  }

  const handleClearStartDate = () => {
    setSelectedOption('Custom')
    setInternalStartDate(null)
  }

  const saveChanges = () => {
    if (!internalStartDate && !internalEndDate) {
      return dispatch(setSnackbar({ message: 'Either start date or end date must be set.', severity: SnackbarSeverity.ERROR }))
    }
    if (internalStartDate && internalEndDate && internalStartDate.isAfter(internalEndDate)) {
      return dispatch(setSnackbar({ message: 'Start date must not be greater than end date.', severity: SnackbarSeverity.ERROR }))
    }
    if (internalStartDate && internalEndDate && internalEndDate.diff(internalStartDate, 'years', true) > 1) {
      return dispatch(setSnackbar({ message: 'The maximum period allowed is 12 months.', severity: SnackbarSeverity.ERROR }))
    }

    setStartDate(internalStartDate || (internalEndDate && internalEndDate?.clone().subtract(1, 'year')))

    if (internalEndDate) {
      setEndDate(internalEndDate)  
    } else if (internalStartDate) {
      const isStartDateBeyondMaxPeriod = moment().startOf('day').diff(internalStartDate, 'year', true) > 1
      if (isStartDateBeyondMaxPeriod) {
        setEndDate(internalStartDate.clone().add(1, 'year'))
      } else {
        setEndDate(null)
      }
    }

    onClose()
  }

  const onSelectOption = (option: DateOption) => {
    setSelectedOption(option.label)
    setInternalEndDate(null)
    switch (option.type) {
      case 'subtract':
        setInternalStartDate(moment().subtract(option.amount || 0, option.unit).startOf('day'))
        break;

      case 'month-to-date':
        setInternalStartDate(moment().startOf('month'))
        break;

      case 'quarter-to-date':
        setInternalStartDate(moment().startOf('quarter'))
        break;
    
      case 'all-time':
        setInternalStartDate(null)
        break;

      default:
        break;
    }
  }

  const minStartDate = internalEndDate ? internalEndDate.clone().subtract(1, 'year') : undefined
  const maxEndDateByPeriod = internalStartDate ? internalStartDate.clone().add(1, 'year') : undefined
  const maxEndDate = maxEndDateByPeriod?.isBefore(moment()) ? maxEndDateByPeriod : moment()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 1200, borderRadius: '10px' } }}
    >
      <Box display="flex">
        <Box width={180} borderRight="1px solid #EEEDF0" pt={3}>
          {dateOptions.map(option => (
            <ListItemButton
              key={option.label}
              onClick={() => onSelectOption(option)}
              sx={{

                display: 'block',
                pl: 4, py: 1,
                cursor: 'pointer',
                backgroundColor: selectedOption === option.label ? '#F8FBFE !important' : '#FFF',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: selectedOption === option.label ? 600 : 400,
                  color: selectedOption === option.label ? '#1033A5' : '#000'
                }}
              >
                {option.label}
              </Typography>
            </ListItemButton>
          ))}
        </Box>

        <Box>
          <Box borderBottom="1px solid #EEEDF0" display="flex" height={380}>
            <Box px={1} borderRight="1px solid #EEEDF0">
              <DateCalendar minDate={minStartDate} value={internalStartDate} onChange={handleChangeStartDate} />
            </Box>
            <Box px={1}>
              <DateCalendar maxDate={maxEndDate} value={internalEndDate} onChange={handleChangeEndDate} />
            </Box>
          </Box>
          <Box px={3} pt={2} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <DateField
                size="small"
                variant="outlined"
                sx={{ width: 155 }}
                minDate={minStartDate}
                value={internalStartDate}
                onChange={handleChangeStartDate}
                format="MM/DD/YYYY"
                InputProps={{
                  sx: { fontSize: '16px', pr: internalStartDate && 1 },
                  endAdornment: internalStartDate && (
                    <InputAdornment position="end">
                      <IconButton sx={{ p: 0 }} onClick={handleClearStartDate}>
                        <Close sx={{ color: '#A5B5E3' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <ArrowRightAlt sx={{ mx: 2 }} />
              <DateField
                size="small"
                variant="outlined"
                sx={{ width: 155 }}
                maxDate={maxEndDate}
                value={internalEndDate}
                onChange={handleChangeEndDate}
                format="MM/DD/YYYY"
                InputProps={{
                  sx: { fontSize: '16px', pr: internalEndDate && 1 },
                  endAdornment: internalEndDate && (
                    <InputAdornment position="end">
                      <IconButton sx={{ p: 0 }} onClick={() => setInternalEndDate(null)}>
                        <Close sx={{ color: '#A5B5E3' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box display="flex" alignItems="center">
              <Button
                sx={{
                  backgroundColor: '#F5F8FD',
                  fontWeight: 400,
                  mr: 1,
                  '&:hover': { backgroundColor: '#E4E7EC' },
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: '#1033A5',
                  color: '#FFF',
                  fontWeight: 400,
                  '&:hover': { backgroundColor: '#0F2284' },
                }}
                onClick={saveChanges}
              >
                Set Date
              </Button>
            </Box>
          </Box>
          <Typography sx={{ px: 3, pb: '3px', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)' }}>The maximum period allowed is 12 months</Typography>
        </Box>
      </Box>
    </Dialog>
  );
}

export default DateRangePicker;