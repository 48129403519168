import { useContext, useEffect } from "react";
import { TwilioContext, TwilioInterface } from "context/twilio-context";
import { logger } from "utils/utils";
import { useAppDispatch, useAppSelector } from "reducers/hooks";
import { activeCallSelector2 } from "reducers/selector/callSelector";
import { Call } from "@twilio/voice-sdk";
import { AppThunkDispatch } from "reducers/types";
import { getActiveCall, stopAllProcessing } from "reducers/thunks/callThunks";

/**
 * Call cleaner ensure that receptionist are not stuck on ghost calls
 * There are certains timing in which ghost call might occur,
 * most likely when customer hang up right when receptionist is joining.
 * 
 * We will compare on app side and decide if twilio device should drop a call
 */
const useCallCleaner = () => {
  const twilioContext = useContext(TwilioContext) as TwilioInterface
  const { device } = twilioContext
  const activeCall = useAppSelector(activeCallSelector2)
  const isListeningToOperator = useAppSelector(state => !!state.callSlot.listeningToOperatorId)
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  // @ts-ignore _activeCall is private, but it's way better than manually maintaining the active call on our app
  const twilioCall: Call = device?._activeCall

  useEffect(() => {
    if (isListeningToOperator) return

    let timeout: NodeJS.Timeout
    if (twilioCall && !activeCall) {
      // @ts-ignore _options is private, but we need it
      logger('Ghost call detected', { parameters: twilioCall.parameters, twimlParams: twilioCall._options.twimlParams })

      timeout = setTimeout(() => {
        const _activeCall = thunkDispatch(getActiveCall())
        if (twilioCall && !_activeCall) {
          // @ts-ignore
          logger('Disconnecting from ghost call', { parameters: twilioCall.parameters, twimlParams: twilioCall._options.twimlParams })
          device?.disconnectAll()
          thunkDispatch(stopAllProcessing())
        }
      }, 750)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twilioCall, activeCall, isListeningToOperator])

  return null
}

export default useCallCleaner
