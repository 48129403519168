import React, { FC, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Box, IconButton, InputAdornment, Skeleton, TextField } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { Contact, searchContacts, sortContacts } from "utils/accounts";
import ContactListItem from "./ContactListItem";
import { useDispatch } from "react-redux";
import { setSearchedContact, setSelectedContact } from "reducers/slices/accountPanelReducer";
import { useAppSelector } from "reducers/hooks";

const ContactListItemMemo = React.memo(ContactListItem, (prevProps, nextProps) => {
  return (
    prevProps.contact === nextProps.contact &&
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.isGloballySelected === nextProps.isGloballySelected &&
    prevProps.search === nextProps.search
  )
})

interface IProps {
  isLoading?: boolean,
}

const ContactList: FC<IProps> = ({ isLoading }) => {
  const [search, setSearch] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const account = useAppSelector(state => state.accountPanel.activeAccount)
  const contacts = useAppSelector(state => state.accountPanel.activeAccount?.contacts || [])
  const searchedContact = useAppSelector(state => state.accountPanel.searchedContact)
  const dispatch = useDispatch()

  useEffect(() => {
    const selectedContact = contacts.find(e => e.id === selectedContactId)
    if (selectedContact) {
      dispatch(setSelectedContact(selectedContact))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContactId])

  const sortedContacts = useMemo(() => {
    return sortContacts(contacts.slice())
  }, [contacts])

  useEffect(() => {
    setSearch('')
  }, [account?.id, searchedContact])

  useEffect(() => {
    if (!isLoading && sortedContacts.length && !searchedContact) {
      const selected = sortedContacts.find(e => e.id === selectedContactId)
      if (!selected) {
        setSelectedContactId(sortedContacts[0].id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, sortedContacts, searchedContact])

  useLayoutEffect(() => {
    if (!isLoading && sortedContacts.length && searchedContact) {
      const searched = sortedContacts.find(e => e.id === searchedContact.id)
      if (searched) {
        const el = document.getElementById(searched.id)
        if (el) {
          el.scrollIntoView()
          setSelectedContactId(searched.id)
          setTimeout(() => {
            dispatch(setSearchedContact(null))
          }, 400)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, sortedContacts, searchedContact])

  const filteredContacts = useMemo(() => {
    let contacts = sortedContacts.filter(e => !e.hideInEvo)
    if (searchedContact) return contacts

    return searchContacts(contacts, search)
  }, [sortedContacts, search, searchedContact])

  const handleSelect = useCallback((contact: Contact) => {
    setSelectedContactId(contact.id)
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        pt: 2, pb: '4px',
        backgroundColor: '#F8FBFE',
        height: 'calc(100% - 16px - 4px)',
      }}
    >
      <Box px="10px" pb={1} width="calc(100% - 20px)" height={46}>
        <TextField
          fullWidth
          style={{ backgroundColor: 'white' }}
          value={search}
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Contacts"
          InputProps={{
            style: { fontSize: '14px', paddingLeft: '8px', paddingRight: 6 },
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: '4px' }}>
                <Search sx={{ color: '#A5B5E3' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {!!search &&
                  <IconButton sx={{ p: '2px' }} onClick={() => setSearch('')}>
                    <Close sx={{ color: '#A5B5E3' }} />
                  </IconButton>
                }
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 54px)',
          overflowY: 'auto',
        }}
      >
        {isLoading &&
          [...new Array(4)].map((e, i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              width="100%"
              height="25%"
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.22)' },
              }}
            />
          ))
        }

        {!isLoading && filteredContacts.map(contact => (
          <ContactListItemMemo
            key={contact.id}
            contact={contact}
            onSelect={handleSelect}
            isSelected={selectedContactId === contact.id}
            isGloballySelected={searchedContact?.id === contact.id}
            search={search}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ContactList;
