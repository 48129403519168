import { Paper, Typography } from '@mui/material';

export const NoCallCardSmall = () => {
  return (
    <Paper
      sx={{
        width: 200,
        height: 98,
        p: 1,
        bgcolor: '#F7F7F7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        boxShadow: 'none',
      }}
    >
      <Typography sx={{ fontSize: '15px', fontWeight: 700, color: '#A4A4A4' }} align="center">
        No Active Call
      </Typography>
    </Paper>
  );
}

export default NoCallCardSmall;