import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import Header from "./Header";
import { Device } from "@twilio/voice-sdk";
import { twilioTokenAPI } from "api/twilio";
import { useDispatch } from "react-redux";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";

let device: Device;

/**
 * This is only for QA to accept call, and to hang up calls.
 */
const QA = () => {
  const [callState, setCallState] = useState<any>(null)
  const [isInCall, setIsInCall] = useState(false)
  const [hasIncomingCall, setHasIncomingCall] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    twilioTokenAPI().then(res => {
      let shouldRegister = false
      if (!device) {
        device = new Device(res.data.twilioAccessToken);
        shouldRegister = true
      }

      device.removeAllListeners()

      device.on('registered', device => {
        console.log('The device is ready to receive incoming calls.')
      });

      device.on('incoming', call => {
        console.log('incoming', call);
        setHasIncomingCall(true)
        setCallState(call)

        call.on('disconnect', (call2: any) => {
          setHasIncomingCall(false)
          setCallState(null)
          setIsInCall(false)
          dispatch(setSnackbar({
            message: 'Call ended.',
            severity: SnackbarSeverity.WARNING
          }))
        })

        call.on('cancel', (call2: any) => {
          setHasIncomingCall(false)
          setCallState(null)
          setIsInCall(false)
          dispatch(setSnackbar({
            message: 'Incoming call canceled.',
            severity: SnackbarSeverity.WARNING
          }))
        })
      });

      device.on('tokenWillExpire', async () => {
        const res2 = await twilioTokenAPI()
        device.updateToken(res2.data.twilioAccessToken)
      })

      if (shouldRegister) {
        device.register()
      }
    })

    return () => {
      if (device) device.destroy()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const acceptCall = async () => {
    await callState.accept()
    setHasIncomingCall(false)
    setIsInCall(true)
  }

  const rejectCall = async () => {
    await callState.reject()
    setHasIncomingCall(false)
    setCallState(null)
  }

  const hangup = async () => {
    await callState.disconnect()
    setIsInCall(false)
    setCallState(null)
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        bgcolor: '#E4ECFC',
      }}
    >
      <Header />
      <Box p={2}>
        <Typography variant="h3">QA Mode</Typography>
        {hasIncomingCall && <Typography>Incoming call from {callState?.parameters?.From}</Typography>}
        {hasIncomingCall && <Button onClick={acceptCall}>Answer</Button>}
        {hasIncomingCall && <Button onClick={rejectCall}>Reject</Button>}
        {isInCall && <Button onClick={hangup}>Hang up</Button>}
      </Box>
    </Box>
  );
};

export default QA;
