import { Device } from "@twilio/voice-sdk"
import { twilioTokenAPI } from "api/twilio"
import { useEffect, useState } from "react"
import { TwilioContext } from "./twilio-context"
import { useDispatch, useSelector } from "react-redux"
import { SnackbarSeverity, setSnackbar, setSoftTwilioLoading, setTwilioLoading } from "reducers/slices/UIReducer"
import { logout } from "reducers/slices/userReducer"
import { RootState } from "reducers/store"
import { useIdleTimer } from "react-idle-timer"
import { logger } from "utils/utils"
import { AppThunkDispatch } from "reducers/types"
import { getIsCallEnabled } from "reducers/thunks/userThunk"
import { stopAllProcessing } from "reducers/thunks/callThunks"

export const TwilioProvider = (props: any) => {
  const [device, setDevice] = useState<Device | null>(null);
  const user = useSelector((state: RootState) => state.user.user);
  const callEnabled = useSelector((state: RootState) => state.ui.isCallEnabled);
  const edgeLocation = useSelector((state: RootState) => state.ui.edgeLocation);
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  useEffect(() => {
    if (user?.userId && callEnabled) {
      setupTwilioDevice()
    } else {
      handleLogout()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userId, callEnabled])

  useEffect(() => {
    // Not on first load
    if (device) {
      setupTwilioDevice()
      logger('Changing edge location to ' + edgeLocation)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edgeLocation])

  const setupTwilioDevice = (softLoading = false) => {
    const isCallEnabled = thunkDispatch(getIsCallEnabled())
    if (!isCallEnabled) return
    if (device) device.destroy()

    dispatch(softLoading ? setSoftTwilioLoading(true) : setTwilioLoading(true))
    twilioTokenAPI().then(res => {
      const newDevice = new Device(res.data.twilioAccessToken, { edge: edgeLocation })

      newDevice.audio?.disconnect(false)
      newDevice.audio?.outgoing(false)

      // @ts-ignore
      newDevice.on('tokenWillExpire', async () => {
        const res2 = await twilioTokenAPI()
        newDevice.updateToken(res2.data.twilioAccessToken)
      })

      newDevice.on('error', (twilioError, call) => {
        logger('A twilio device error has occured', { call, twilioError })
        thunkDispatch(stopAllProcessing())

        // Token has expired
        if (twilioError?.code === 20104) {
          dispatch(setSnackbar({ message: 'Reconnecting to twilio server. Please retry.', severity: SnackbarSeverity.WARNING }))
          setupTwilioDevice(true)
        }

        if (twilioError?.code === 53000) {
          dispatch(setSnackbar({ message: 'Failed to reach Twilio server. Please retry in a bit.', severity: SnackbarSeverity.WARNING }))
        }

        if (twilioError?.code === 31005) {
          dispatch(setSnackbar({ message: 'Connection error happened during the call.', severity: SnackbarSeverity.WARNING }))
        }
      })

      dispatch(softLoading ? setSoftTwilioLoading(false) : setTwilioLoading(false))
      setDevice(newDevice)
    }).catch(err => {
      dispatch(setSnackbar({ message: 'Login expired', severity: SnackbarSeverity.ERROR }))
      dispatch(logout())
    })
  }

  useIdleTimer({
    timeout: 60 * 60 * 1000, // 1 hour
    onActive: () => setupTwilioDevice(true),
  })

  const handleLogout = () => {
    if (device) device.destroy()
    // Redux states are cleared as well, check src/redux/store/index.ts
  }

  return (
    <TwilioContext.Provider value={{ device }}>
      {props.children}
    </TwilioContext.Provider>
  )
}