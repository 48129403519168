import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OUTBOUND_SLOT } from 'reducers/slices/callSlotReducer';
import { RootState } from "reducers/store";
import AdditionalCallCard from '../callCard/AdditionalCallCard';
import { AppThunkDispatch } from 'reducers/types';
import { selectSlotThunk } from 'reducers/thunks/callThunks';

interface IProps {
  name: string,
  userId: string,
}

export const AdditionalCallSlot: FC<IProps> = () => {
  const outboundUnassignedCall = useSelector((state: RootState) => state.callSlot.outboundUnassignedCall);
  const callState = useSelector((state: RootState) => state.call[outboundUnassignedCall || '']);
  const isSelected = useSelector((state: RootState) => state.callSlot.selectedSlot === OUTBOUND_SLOT)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  const handleSelect = () => {
    thunkDispatch(selectSlotThunk(OUTBOUND_SLOT))
  }

  if (!callState || callState.isRemoved) {
    return null
  }

  return (
    <AdditionalCallCard
      callState={callState}
      isSelected={isSelected}
      onSelect={handleSelect}
    />
  );
}

export default AdditionalCallSlot;