import { FC, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Contact, ScheduledContactStatus } from "utils/accounts";
import { useDispatch } from "react-redux";
import { setSnackbar, SnackbarSeverity } from "reducers/slices/UIReducer";
import { deleteScheduledStatus } from "api/contacts";
import ContactStatusAlert from "./contactItem/ContactStatusAlert";

interface IProps {
  contactStatus: ScheduledContactStatus | null,
  contact: Contact | null,
  closeDelete: () => void,
  onDeleteCallback: () => void,
}

const ContactStatusDeleteDialog: FC<IProps> = ({ contactStatus, contact, closeDelete, onDeleteCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleDelete = () => {
    if (contactStatus && contact) {
      setIsLoading(true)

      deleteScheduledStatus(contactStatus.id, contact.accountId, contact.id).then(() => {
        dispatch(setSnackbar({ message: 'Contact Call Status deleted!', severity: SnackbarSeverity.SUCCESS }))
        onDeleteCallback()
      }).finally(() => setIsLoading(false))
    }
  }
  
  return (
    <Dialog open={!!contactStatus} onClose={() => closeDelete()}>
      <DialogTitle>
        {'Delete Status'}
        <IconButton
          onClick={() => closeDelete()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#E80B0B',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pr: 6 }}>
        <Typography>Are you sure you want to delete this status?</Typography>
        {(contactStatus && contact) &&
          <ContactStatusAlert contactStatus={contactStatus} contact={contact} />
        }
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={closeDelete}>Cancel</Button>
        <Button disabled={isLoading} variant="contained" color="secondary" onClick={handleDelete}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactStatusDeleteDialog;
