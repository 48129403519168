import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box, Dialog, FormHelperText, IconButton, Paper, PaperProps, Stack, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { CallNoteState } from 'utils/calls';
import { updateCallNotes } from 'api/callrecords';
import useCallFunctions from 'hooks/useCallFunctions';
import Draggable from 'react-draggable';
import { useDispatch } from 'react-redux';
import { closeCallNote, openCallNote } from 'reducers/slices/callSlotReducer';

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface ICallNoteProps {
  callState: CallNoteState,
}

const CallNote: FC<ICallNoteProps> = ({ callState }) => {
  const [notes, setNotes] = useState('')
  const { updateCall } = useCallFunctions()
  const dispatch = useDispatch()

  useEffect(() => {
    if (callState?.notes) {
      setNotes(callState?.notes)
    }
  }, [callState.notes])

  const handleClose = (e: any) => {
    noBubbleUp(e)
    updateNotes()
    dispatch(closeCallNote(callState.conferenceName))
  }

  const updateNotes = (onUnmount = false) => {
    if (callState?.callRecordId) {
      if (notes && callState?.notes !== notes) {
        const truncatedNotes = notes.substring(0, 255).trim()
        updateCall(callState.conferenceName, { notes: truncatedNotes })

        if (!callState.hipaaCompliant) {
          updateCallNotes(callState.conferenceName, callState.callRecordId, truncatedNotes).catch(() => {
            if (!onUnmount) {
              dispatch(openCallNote(callState))
            }
          })
        }
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Save and submit on enter
    if (e.key === 'Enter' && !e.shiftKey) {
      handleClose(e)
    }
  }

  const noBubbleUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRefAutoFocus = useCallback((node: any) => {
    const element = node
    if (!element) return

    if (callState.viewMode) return

    // Put the cursor at the end of input
    element.setSelectionRange(element.value.length, element.value.length)
    element.focus()
  }, [callState.viewMode]);

  return (
    <Dialog
      open={true}
      hideBackdrop
      PaperComponent={PaperComponent}
      sx={{ pointerEvents: 'none' }}
      PaperProps={{
        sx: { pointerEvents: 'auto' }
      }}
      disableEnforceFocus
    >
      <Box width={400} p="20px" onClick={noBubbleUp}>
        <Box display="flex" alignItems="center" sx={{ cursor: 'move' }} id="draggable-dialog-title">
          <StickyNote2OutlinedIcon sx={{ color: '#1033A5', mr: 1, mt: '2px' }} />
          <Typography style={{ fontWeight: 600, fontSize: 22 }}>
            {callState.viewMode ? 'Call Note' : 'Update Note'}
          </Typography>
          <IconButton sx={{ marginLeft: 'auto', mr: -1 }} onClick={handleClose}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Typography sx={{ mt: '4px', fontSize: '20px', fontWeight: 600 }}>{callState?.name}</Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#1033A5' }}>{callState?.callerNumber}</Typography>
        {callState.viewMode &&
          <Box
            sx={{
              mt: 1, p: 1,
              height: 100,
              overflowY: 'auto',
              bgcolor: '#E4ECFC',
              borderRadius: '5px',
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>{notes}</Typography>
          </Box>
        }
        {!callState.viewMode &&
          <>
            <TextField
              sx={{
                bgcolor: '#E4ECFC',
                borderRadius: '5px',
                mt: 1,
              }}
              inputRef={handleRefAutoFocus}
              InputProps={{ sx: { p: 1 } }}
              inputProps={{ style: { fontSize: '14px' } }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={notes}
              onKeyDown={handleKeyDown}
              onChange={e => setNotes(e.target.value)}
            />
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
              {notes.length > 255 &&
                <FormHelperText error sx={{ whiteSpace: 'pre-line' }}>
                  {`Maximum notes characters exceeded. \nAnything past 255 will not be saved.`}
                </FormHelperText>
              }
              <FormHelperText error={notes.length > 255} sx={{ pl: 1 }}>{notes.length + '/255'}</FormHelperText>
            </Stack>
            {callState.hipaaCompliant &&
              <Typography sx={{ mt: 1, fontSize: '12px' }}>
                In compliance with HIPAA, this note will not be saved and is only visible during the call.
              </Typography>
            }
          </>
        }
      </Box>
    </Dialog>
  )
}

export default CallNote