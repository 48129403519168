import React, { useLayoutEffect, useMemo, useState } from "react";
import { Badge, Box, Button, Menu, MenuItem, Tooltip, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers/store";
import { removeTask, setSelectedTask } from "reducers/slices/accountPanelReducer";
import { Close, KeyboardDoubleArrowDown } from "@mui/icons-material";
import { PanelTask } from "utils/accounts";

const TaskButton = styled(Button)(() => ({
  width: 240,
  marginLeft: 8,
  '&:first-of-type': { marginLeft: 0 },
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  paddingRight: '22px',
}))

const TaskLabel = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '14px',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}))

const SelectedTaskButton = styled(TaskButton)(() => ({
  zIndex: 6,
  color: '#FFF',
  backgroundColor: '#1033A5',
  '&:hover': {
    color: '#FFF',
    backgroundColor: '#1033A5',
  },
}))

const CloseIcon = styled(Close)(() => ({
  fontSize: '20px',
  color: '#FF3737',
  cursor: 'pointer',
  '&:hover': { color: '#CC0404' },
  position: 'absolute',
  right: 4,
}))

const SelectedCloseIcon = styled(CloseIcon)(() => ({
  color: 'white',
  '&:hover': { color: '#b5b5b5' }
}))

const ButtonLabel = (props: any) => (
  <Tooltip title={props.children} PopperProps={{ sx: { textAlign: 'center' }}}>
    <TaskLabel>
      {props.children}
    </TaskLabel>
  </Tooltip>
)


const TaskButtons = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [width, setWidth] = useState<number>(800)
  const tasks = useSelector((state: RootState) => state.accountPanel.tasks)
  const selectedTask = useSelector((state: RootState) => state.accountPanel.selectedTask)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    const getWidth = () => {
      const wrapper = document.getElementById('taskButtons')
      const width = wrapper?.clientWidth || 0
      setWidth(width)
    }

    getWidth()
  }, [])

  const onSelectTask = (task: PanelTask) => (e: any) => {
    dispatch(setSelectedTask(task.name))
    setAnchorEl(null)
  }

  const onRemoveTask = (task: PanelTask) => (e: any) => {
    e.preventDefault()
    dispatch(removeTask(task.name))
  }

  // The idea of this is to effectively use the spaces available to fill as much buttons as possible
  // The problem is that, we will also like to show dropdown button if we have tasks that are not shown
  // and such we need to calculate, if we have just enough tasks and enough space, to not show the dropdown
  // at all.
  // I feel like there should be an easier way to achieve this. But the code below should work just fine
  const [shownTasks, remainingTasks] = useMemo(() => {
    let usedWidth = -8 // first task is only 240

    const shownTasks: PanelTask[] = []
    const remainingTasks = tasks.slice()
    while (width > usedWidth && remainingTasks.length) {
      const remainingWidth = width - usedWidth
      const taskWidth = 248
      const dropdownWidth = 40
      
      // As long as there are enough space for 2 tasks + 1 dropdown, just push the tasks in.
      if (remainingWidth >= (taskWidth * 2) + dropdownWidth) {
        shownTasks.push(remainingTasks.shift() as PanelTask)
        usedWidth += taskWidth
      } else {
        // We don't have enough space for 2 tasks + 1 dropdown anymore.
        // Now we need to do different action depending on remaining space

        // We have enough for 2 tasks, and we only have 2 tasks left
        if (remainingWidth >= (taskWidth * 2) && remainingTasks.length === 2) {
          shownTasks.push(remainingTasks.shift() as PanelTask)
          shownTasks.push(remainingTasks.shift() as PanelTask)
          usedWidth += (taskWidth * 2)

        // We have enough for 1 task, and only 1 task left
        } else if (remainingWidth >= taskWidth && remainingTasks.length === 1) {
          shownTasks.push(remainingTasks.shift() as PanelTask)
          usedWidth += taskWidth

        // We have enough for 1 tasks and the dropdown
        } else if (remainingWidth >= taskWidth + dropdownWidth) {

          // Show selected task or first remaining task
          const selectedIndex = remainingTasks.findIndex(e => e.name === selectedTask)
          if (selectedIndex !== -1) {
            shownTasks.push(remainingTasks.splice(selectedIndex, 1)[0])
          } else {
            shownTasks.push(remainingTasks.shift() as PanelTask)
          }
          usedWidth += taskWidth

        // Not enough space, break the loop
        } else {
          break
        }
      }
    }

    return [shownTasks, remainingTasks]
  }, [tasks, selectedTask, width])

  const showDropdown = !!remainingTasks.length
  return (
    <Box width="calc(100% - 306px)" id="taskButtons">
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {remainingTasks.map(task => (
          <MenuItem key={task.name} onClick={onSelectTask(task)}>{task.name}</MenuItem>
        ))}
      </Menu>

      {shownTasks.map((task, i) => (
        selectedTask === task.name ?
          <SelectedTaskButton key={task.name}>
            <ButtonLabel>
              {task.name}
            </ButtonLabel>
            <SelectedCloseIcon onClick={onRemoveTask(task)} />
          </SelectedTaskButton>
          :
          <TaskButton
            key={task.name}
            onClick={onSelectTask(task)}
            sx={{ zIndex: 5 - i }}
          >
            <ButtonLabel>
              {task.name}
            </ButtonLabel>
            <CloseIcon onClick={onRemoveTask(task)} />
          </TaskButton>
      ))}

      {showDropdown && (
        <TaskButton
          sx={{ padding: 0, width: 32, minWidth: 32 }}
          onClick={(e => setAnchorEl(e.currentTarget))}
        >
          <Badge badgeContent={remainingTasks.length} color="primary">
            <KeyboardDoubleArrowDown />
          </Badge>
        </TaskButton>
      )}
    </Box>

  );
};

export default TaskButtons;
