import { FC, useEffect, useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { SnackbarSeverity, setSnackbar } from "reducers/slices/UIReducer";
import { useNavigate } from "react-router-dom";
import { duoEnroll, duoEnrollStatus } from "api/authDuo";
import { logout } from "reducers/slices/userReducer";
import { Close } from "@mui/icons-material";

type IProps = {
  open: boolean,
  onClose: () => void,
}

const DuoEnrollDialog: FC<IProps> = ({ open, onClose }) => {
  const [duoUserId, setDuoUserId] = useState<string>('')
  const [qrCodeUrl, setQrCodeUrl] = useState<string>('')
  const [activationCode, setActivationCode] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (open && !qrCodeUrl) {
      const token = localStorage.getItem('token')
      if (token) {
        duoEnroll(token).then((res) => {
          const response = res.data.response
          setDuoUserId(response.user_id)
          setQrCodeUrl(response.activation_barcode)
          setActivationCode(response.activation_code)
        })
      }
    }
  }, [open, qrCodeUrl])

  useEffect(() => {
    let interval: NodeJS.Timer

    const token = localStorage.getItem('token')
    if (activationCode && duoUserId && token) {
      interval = setInterval(() => {
        duoEnrollStatus({ activationCode, duoUserId }, token).then((res) => {
          const response = res.data
          if (response === 'success') {
            clearInterval(interval)
            dispatch(logout())
            navigate('/')

            dispatch(setSnackbar({ message: 'Authentication added, please login again.', severity: SnackbarSeverity.SUCCESS }))
          }
        })
      }, 2000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [activationCode, duoUserId, navigate, dispatch])

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>
        {'Setting Up 2-Factor Authentication'}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#E80B0B',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box width={900}>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>1. Install</Typography>
                <Typography>Duo Mobile is free for both iPhone and Android.</Typography>
                <img width="200px" height="200px" alt="qr" src="/assets/qr/duo-apple.png" />
                <Typography sx={{ fontSize: '12px', mt: -2 }}>iPhone</Typography>
                <img width="200px" height="200px" alt="qr" src="/assets/qr/duo-android.png" />
                <Typography sx={{ fontSize: '12px', mt: -2 }}>Android</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack alignItems="center">
                <Typography sx={{ fontWeight: 600, fontSize: '18px' }}>2. Tap "+" and Scan</Typography>
                <Typography>Open Duo Mobile and tap the "+" button to add an account.</Typography>
                <Typography>Scanning this barcode will link the app to your account.</Typography>
                {qrCodeUrl && <img width="300px" height="300px" alt="qr" src={qrCodeUrl} />}
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DuoEnrollDialog;
