import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import useEventsListener from "hooks/useEventsListener";
import useCallsAssigner from "hooks/useCallsAssigner";
import { Outlet } from "react-router-dom";
import useRingtonePlayer from "hooks/useRingtonePlayer";
import useListeningToOperator from "hooks/useListeningToOperator";
import useAccountsFetcher from "hooks/useAccountsFetcher";
import SoftLoading from "pages/SoftLoading";
import useConnectionHandler from "hooks/useConnectionHandler";
import useTaskIframeListener from "hooks/useTaskIframeListener";
import GlobalCallHistory from "./callHistory/GlobalCallHistory";
import SettingsDialog from "./settings/SettingsDialog";
import { useDispatch } from "react-redux";
import { PanelTask, TASKS_STORAGE } from "utils/accounts";
import { setTask } from "reducers/slices/accountPanelReducer";
import useCallCleaner from "hooks/useCallCleaner";
import CallNotes from "./receptionist/CallNotes";
import useRemovedCallCleaner from "hooks/useRemovedCallCleaner";


const GlobalCallHistoryMemo = React.memo(GlobalCallHistory)
const SettingsDialogMemo = React.memo(SettingsDialog)
const HeaderMemo = React.memo(Header)
const SoftLoadingMemo = React.memo(SoftLoading)
const CallNotesMemo = React.memo(CallNotes)

const Main = () => {
  useEventsListener()
  useCallsAssigner()
  useRingtonePlayer()
  useListeningToOperator()
  useAccountsFetcher()
  useConnectionHandler()
  useTaskIframeListener()
  useCallCleaner()
  useRemovedCallCleaner()
  const dispatch = useDispatch()

  useEffect(() => {
    const savedTasks = localStorage.getItem(TASKS_STORAGE)
    if (savedTasks) {
      const tasks: PanelTask[] = JSON.parse(savedTasks)
      dispatch(setTask(tasks))
    }
  }, [dispatch])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        bgcolor: '#E4ECFC',
        position: 'relative',
      }}
    >
      <GlobalCallHistoryMemo />
      <SettingsDialogMemo />

      <HeaderMemo />
      <Outlet />
      <SoftLoadingMemo />
      <CallNotesMemo />
    </Box>
  );
};

export default Main;
