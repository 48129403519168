import { Typography } from "@mui/material"
import { FormQuestion } from "../utils";

const QuestionInstructions = ({ question }: { question: FormQuestion }) => {
  return (
    <>
      {question.instructions &&
        <Typography component="div">
          <div dangerouslySetInnerHTML={{ __html: question.instructions }}></div>
        </Typography>
      }
    </>
  )
}

export default QuestionInstructions;
