import { createSvgIcon } from "@mui/material";

const EditIcon = createSvgIcon(
<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M1.60449 10.0001C1.41703 10.0001 1.25559 9.93306 1.12015 9.79908C0.984711 9.66509 0.916992 9.50292 0.916992 9.31255C0.916992 9.1251 0.984711 8.96365 1.12015 8.82821C1.25559 8.69277 1.41703 8.62505 1.60449 8.62505H9.39616C9.58362 8.62505 9.74507 8.69205 9.8805 8.82603C10.0159 8.96002 10.0837 9.12219 10.0837 9.31255C10.0837 9.50001 10.0159 9.66146 9.8805 9.7969C9.74507 9.93234 9.58362 10.0001 9.39616 10.0001H1.60449ZM2.06283 6.79608V5.83633C2.06283 5.7811 2.07207 5.7285 2.09055 5.67854C2.10912 5.62859 2.13983 5.58214 2.18268 5.53921L7.20063 0.532836C7.26701 0.466455 7.34237 0.416229 7.4267 0.382159C7.51096 0.34809 7.59804 0.331055 7.68795 0.331055C7.78084 0.331055 7.86914 0.34809 7.95287 0.382159C8.03659 0.416229 8.11401 0.468823 8.18513 0.539941L8.73593 1.09785C8.80705 1.16423 8.85846 1.24001 8.89016 1.32518C8.92194 1.41043 8.93783 1.49946 8.93783 1.59227C8.93783 1.67752 8.92194 1.76228 8.89016 1.84653C8.85846 1.93087 8.80705 2.00859 8.73593 2.07971L3.72955 7.08609C3.68662 7.12902 3.64022 7.16045 3.59034 7.18039C3.54038 7.2004 3.48778 7.21041 3.43255 7.21041H2.47716C2.35899 7.21041 2.26041 7.17088 2.18142 7.09181C2.10236 7.01283 2.06283 6.91425 2.06283 6.79608ZM7.6043 2.23577L8.25468 1.58528L7.68795 1.01855L7.03746 1.66893L7.6043 2.23577Z"
    fill="#656565" />
</svg>
,
  'EditIcon',
)

export default EditIcon;
