import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Close, Email, HourglassEmpty, MergeType, Voicemail } from "@mui/icons-material";
import { Contact, getContactName, getTransferNumbers, TransferNumber } from "utils/accounts";
import Highlighter from 'react-highlight-words';
import CopyButton from "components/buttons/CopyButton";
import useCallFunctions from "hooks/useCallFunctions";
import { activeCallSelector, selectedCallSelector } from "reducers/selector/callSelector";
import { useAppSelector } from "reducers/hooks";
import { isActiveCallTransferable } from "utils/calls";
import { spin } from "utils/animations";
import useThrottledAction from "hooks/useThrottledAction";

interface IProps {
  search: string,
  contact: Contact,
}

const clickableLinkStyle = {
  fontWeight: 'normal',
  color: '#1033A5',
  textDecoration: 'underline',
  cursor: 'pointer',
}

const blindLinkStyle = {
  ...clickableLinkStyle,
  color: '#D79F2B',
}

const dialedLinkStyle = {
  color: '#E80B0B',
}

const ContactNumbers: FC<IProps> = ({ contact, search }) => {
  const [numberToDial, setNumberToDial] = useState<TransferNumber>()
  const [showDialPrompt, setShowDialPrompt] = useState<boolean>(false)
  const activeCall = useAppSelector(state => activeCallSelector(state))
  const selectedCall = useAppSelector(state => selectedCallSelector(state))
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const { transferToVm, outboundOrTransfer, outboundCall, coldTransfer } = useCallFunctions()
  const isThrottled = useThrottledAction()

  const transferNumbers: TransferNumber[] = useMemo(() => {
    if (!contact) return []

    return getTransferNumbers(contact)
  }, [contact])

  const dialedCounts = useMemo<Record<string, number>>(() => {
    const dialedCountMap: Record<string, number> = {};
    const dialedNumbers = selectedCall?.transfersDialed || [];

    dialedNumbers.forEach((phoneId) => {
      dialedCountMap[phoneId] = (dialedCountMap[phoneId] ?? 0) + 1;
    });

    return dialedCountMap;
  }, [selectedCall?.transfersDialed, transferNumbers]);

  const noBubbleUp = (e: React.MouseEvent<any>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleTransferNumberClick = (transferNumber?: TransferNumber) => (e: React.MouseEvent<HTMLSpanElement>) => {
    noBubbleUp(e);
    if (!transferNumber) return;

    // Handle blind transfer directly
    if (transferNumber.type === 'Blind') {
      return handleColdTransferClick(transferNumber.id)(e); // Use transferNumber.id
    }

    if (isThrottled()) return;

    // Use the stable id for tracking
    const transferKey = transferNumber.id;

    // Check if the number was already dialed
    if (dialedCounts[transferKey]) {
      if (activeCall && !showDialPrompt) {
        setNumberToDial(transferNumber); // Set the number for redial prompt
        setShowDialPrompt(true);
        return;
      } else {
        setShowDialPrompt(false); // Close prompt if already showing
      }
    }

    // Perform the outbound or transfer action
    outboundOrTransfer(transferNumber.value, transferNumber.id, getContactName(contact, true));
  };

  const handleColdTransferClick = (id: string) => (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isThrottled()) return;

    noBubbleUp(e);

    const transferNumber = transferNumbers.find((num) => num.id === id);

    if (transferNumber) {
      if (activeCall) {
        coldTransfer(activeCall.conferenceName, transferNumber.value, getContactName(contact, true));
      } else {
        outboundCall(transferNumber.value);
      }
    }
  };

  const handleVoicemailClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    noBubbleUp(e)
    if (isThrottled()) return

    if (contact.assignedVoicemail) {
      if (!activeCall) {
        outboundCall('*99' + contact.assignedVoicemail)
      }
    }
  }

  const handleVmColdTransferClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    noBubbleUp(e)
    if (isThrottled()) return

    if (contact.assignedVoicemail) {
      transferToVm(contact.assignedVoicemail, getContactName(contact, true))
    }
  }

  const isCallTransferable = useMemo(() => {
    return callEnabled && isActiveCallTransferable(activeCall)
  }, [activeCall, callEnabled])

  const isTransferEnabled = (transferNumber: typeof transferNumbers[number]) => {
    if (!callEnabled) return false
    if (activeCall?.isProcessing) return false

    // Enable for extensions dialing
    if (transferNumber.value.includes('?')) {
      // For warm transfer
      if (transferNumber.value === activeCall?.warmTransferredToNum) return true
      // For outbound call
      if (transferNumber.value === activeCall?.callerNumber) return true
    }

    if (transferNumber.value === activeCall?.callerNumber) return false

    return isCallTransferable || !activeCall
  }

  const hideNumbers = !contact.connectOnly && contact.doYouTakeCalls !== 'Yes' && contact.doYouTakeCalls !== 'Emergency Calls Only'
  return (
    <Box width="100%">
      <Dialog open={showDialPrompt} onClose={() => setShowDialPrompt(false)}>
        <DialogTitle>
          {'Redial'}
          <IconButton
            onClick={() => setShowDialPrompt(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#E80B0B',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pr: 6, width: 500 }}>
          <Typography>Are you sure you want to dial {numberToDial?.label}: {numberToDial?.value} again?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialPrompt(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={handleTransferNumberClick(numberToDial)}>Yes</Button>
        </DialogActions>
      </Dialog>

      {!hideNumbers && transferNumbers.map(transferNumber => (
        <Stack direction="row" alignItems="center" key={transferNumber.id} flexWrap="wrap">
          <Typography sx={{ fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 600 }}>
            {transferNumber.label}:&nbsp;
          </Typography>
          <Stack direction="row" alignItems="center" key={transferNumber.id} minHeight={21}>
            <Typography
              sx={{ fontSize: '13px', whiteSpace: 'nowrap' }}
              onMouseDown={noBubbleUp}
              onClick={isTransferEnabled(transferNumber) ? handleTransferNumberClick(transferNumber) : undefined}
              style={{
                ...(isTransferEnabled(transferNumber) ? (transferNumber.type === 'Blind' ? blindLinkStyle : clickableLinkStyle) : {}),
                ...(dialedCounts[transferNumber.id] ? dialedLinkStyle : {}), // Apply red style if dialed
              }}
            >
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={
                  transferNumber.value +
                  (dialedCounts[transferNumber.id] ? ` [${dialedCounts[transferNumber.id]}]` : '')
                }
              />
            </Typography>
            <CopyButton text={transferNumber.value} />
            {(transferNumber.type === 'Both' && !!activeCall) &&
              <Tooltip arrow placement="top" title="Blind Transfer">
                <span>
                  <IconButton
                    sx={{ p: 0, ml: '4px', color: '#D79F2B' }}
                    onClick={handleColdTransferClick(transferNumber.value)}
                    onMouseDown={noBubbleUp}
                    disabled={!isCallTransferable}
                  >
                    <MergeType sx={{ fontSize: '20px' }} />
                  </IconButton>
                </span>
              </Tooltip>
            }
            {(!!activeCall && !activeCall.transferEnabled) &&
              <HourglassEmpty sx={{ fontSize: '14px', animation: `${spin} 1.5s linear 0s infinite` }} />
            }
          </Stack>
        </Stack>
      ))}
      {contact.assignedVoicemail &&
        <Stack direction="row" alignItems="center" minHeight={21}>
          <Voicemail sx={{ color: '#F2B648', fontSize: '18px', mr: 1 }} />
          <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
            VM:&nbsp;
            <span
              onMouseDown={noBubbleUp}
              onClick={callEnabled && !activeCall ? handleVoicemailClick : undefined}
              style={callEnabled && !activeCall ? clickableLinkStyle : {}}
            >
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={
                  contact.assignedVoicemail +
                  (dialedCounts[contact.assignedVoicemail] ? ` [${dialedCounts[contact.assignedVoicemail]}]` : '')
                }
              />
            </span>
          </Typography>
          <CopyButton text={contact.assignedVoicemail} />
          {!!activeCall &&
            <Tooltip arrow placement="top" title="Blind Transfer">
              <span>
                <IconButton
                  sx={{ p: 0, ml: '4px', color: '#D79F2B' }}
                  onClick={handleVmColdTransferClick}
                  onMouseDown={noBubbleUp}
                  disabled={!callEnabled || !isCallTransferable}
                >
                  <MergeType sx={{ fontSize: '20px' }} />
                </IconButton>
              </span>
            </Tooltip>
          }
        </Stack>
      }
      {(contact.email && contact.okToGiveOutEmailAddress !== 'No') &&
        <Stack direction="row" alignItems="center">
          <Email sx={{ color: '#2F6FCF', fontSize: '18px', mr: '1px' }} />
          <Typography sx={{ fontSize: '13px' }}>
            :&nbsp;
            <span
              onMouseDown={noBubbleUp}
              style={{ color: '#1033A5', textDecoration: 'underline', cursor: 'pointer' }}
            >
              <Highlighter
                searchWords={[search.toLowerCase()]}
                autoEscape={true}
                textToHighlight={contact.email}
              />
            </span>
          </Typography>
          <CopyButton text={contact.email} />
        </Stack>
      }
    </Box>
  )
}

export default ContactNumbers;
